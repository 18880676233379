import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import TooltipCustom from './TooltipCustom';
import { CheckCircle } from '@mui/icons-material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';



const FormEditable = ({ className, label, noLabel, noBorder, required, tooltip, tooltipTitle, icon, value, ...props }) => {
  const [editable, setEditable] = useState(false);

  return <>
      {!noLabel && <h5 className={`d-flex ${editable ? 'input-label' : 'm-0'}`}>
        {label}{required ? <span className="color-required"> *</span> : ''}
        {tooltip ? <>
          <TooltipCustom 
            title={tooltipTitle}
            style={{ color: "var(--color-gray)", marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto', fontSize: '14px' }}>
          </TooltipCustom>
        </> : ''}
      </h5>}
      {editable ? <TextField
        {...props}
        variant="standard"
        fullWidth={true}
        InputProps={{
          disableUnderline: true,
          startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : '',
          endAdornment: editable ? <InputAdornment position="end"><CheckCircle className="clickable color-primary" style={{ fontSize: '16px' }} onClick={() => setEditable(false)}></CheckCircle></InputAdornment> : '',
          style: { padding: '8px 16px', marginBottom: '0', 
            border: noBorder ? 'none' : '1px solid lightgray', borderRadius: '6px', fontSize: '14px' }
        }}
        sx={{
          ['& .MuiInput-input'] : {
            padding: 0,
          }
        }}
      /> : 
        <h4 className='d-flex' style={{ fontSize: '14px', lineHeight: '20px', margin: 0, marginBottom: '24px', fontWeight: 500 }}>
          {value}
            <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} onClick={() => setEditable(true)}></DriveFileRenameOutlineIcon>
        </h4>
      }
    </>
}

export default FormEditable;