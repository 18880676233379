import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Container, Button, TextField, MenuItem, InputAdornment, Snackbar, Avatar, Divider } from "@mui/material";
import { MailOutlineOutlined, RocketLaunch } from "@mui/icons-material";

import FormField from "../components/common/FormField"
import ChipTag from "../components/common/ChipTag"

import api from "../api/api"
import axios from "axios";

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '6319e72d-0dc8-4bbc-86cc-09d1701df65d' // prod
// var proxyEndpoint = 'http://localhost:5555'
// var wsEndpoint = 'ws://localhost:5555'
// var vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'

var env = String(process.env.REACT_APP_ENV)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
}

function YourTeamHome() {
  const navigate = useNavigate();

  const { user, isAuthenticated, isLoading } = useAuth0();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  
  const [flow, setFlow] = useState('');
  const [teamStep, setTeamStep] = useState(0);
  const [createTeamName, setCreateTeamName] = useState('');
  const [invitedTeam, setInvitedTeam] = useState();
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [currentTeamId, setCurrentTeamId] = useState({})
  const [addTeamEmailCount, setAddTeamEmailCount] = useState(1);
  const [addTeamEmail, setAddTeamEmail] = useState('');
  const [addTeamAccess, setAddTeamAccess] = useState('full');

  const ACCESS_OPTIONS = [
    {name: 'Full access', val: 'full'}, 
    // {name: 'Read only', val: 'read'}
  ]

  useEffect(() => {
    if(user && !isLoading) {
      var userId = user.email

      // check if any invites
      api.getTeams({ userId }).then((response) => {
        const teams = response.data 
        let inviteFound = false
        teams.map((team) => {
          team.members.map((member) => {
            if(member.user_id === userId && member.status === 'invited') {
              setInvitedTeam(team)
              setCurrentTeamId(team._id.$oid)
              setFlow('join');
              inviteFound = true
              return
            }
          })
        })

        console.log('inviteFound', inviteFound)

        if(inviteFound) {
          return
        }

        let teamId = localStorage.getItem('teamId')
        if (teamId) {
          setCurrentTeamId(teamId)
          let status
          api.getTeam({ userId, teamId }).then((response) => {
            let team = response.data;
            // check that user is invited or active
            team.members.map((member) => {
              if(member.user_id === userId) {
                status = member.status
              }
            })
            if(status === 'active') {
              navigate('/interviews')
              return
            }
            if(status === 'invited') {
              setInvitedTeam(team)
              setCurrentTeamId(team._id.$oid)
              setFlow('join');
            }
          })
        } else {
          console.log('create flow')
          setFlow('create');
        }        
      }).catch((err) => {
        console.log(err)
        setFlow('create');
      })
    }
  }, [user, isLoading]);

  const callCreateTeam = (team_name) => {
    let teamDetails = {
      name: team_name
    };
    let userId = user.email;
    api.createTeam({ userId, teamDetails }).then((response) => {
      setSnackbarMsg(`Successfully created team ${team_name}.`);
      setOpenSnackbar(true);

      setTeamStep((prev) => prev + 1)
      setCurrentTeamId(response.data.id)
      localStorage.setItem('teamId', response.data.id)
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });
  }

  const callInviteTeamMember = (member_id, access) => {
    let memberId = member_id;
    let teamId = currentTeamId;
    let userId = user.email;
    let role = access;
    api.inviteTeamMember({ memberId, teamId, userId, role }).then((response) => {
      setSnackbarMsg(`Successfully invited team member.`);
      setOpenSnackbar(true);

      setInvitedMembers((prev) => [...prev, memberId]);
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });
  }
  
  const callAcceptJoinTeam = (team_name) => {
    let userId = user.email;
    let teamId = currentTeamId;
    api.acceptJoinTeam({ userId, teamId }).then((response) => {
      setSnackbarMsg(`Successfully joined team ${team_name}.`);
      setOpenSnackbar(true);

      navigate('/interviews')
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });
  }

  const renderCreateFlow = () => {
    return (<>
      { teamStep == 0 && user && 
        <>
          <h3 className="section-heading">Create new team</h3>
          <p className="section color-gray">
            Get your interviews up and running faster by creating your team to collaborate.
          </p>
          <FormField
            required
            label="Team Name"
            placeholder={`${user.email}'s Team`}
            value={createTeamName}
            onChange={(e) => {setCreateTeamName(e.target.value)}}
          />
          <div style={{ display : 'flex', width: '100%', marginTop: '8px' }}>
            {/* <Button className="btn-main gray outlined" style={{ height: '40px', padding: '10px 14px', marginLeft: 'auto' }}
              onClick={() => callInviteTeamMember(addTeamEmail, addTeamAccess)}>
                Skip for now
            </Button> */}
            <Button className="btn-main" style={{ height: '40px', padding: '10px 14px', marginLeft: 'auto' }}
              onClick={() => callCreateTeam(createTeamName)}>
                Create Team
            </Button>
          </div>
        </>
      }
      { teamStep == 1 && user &&
        <>  
        <h3 className="section-heading">Invite team members to { createTeamName }</h3>
        <p className="color-gray" style={{ fontSize: 12, margin: 0 }}>Get your projects up and running faster by inviting your team to collaborate.</p> 
        <div className="d-flex flex-row" style={{ marginTop: '16px' }}>
          <div className="d-flex" style={{ flex: '10', marginRight: '16px' }}>
            <TextField 
              variant="outlined"
              fullWidth={true}
              InputProps={{
                startAdornment: <InputAdornment position="start"><MailOutlineOutlined></MailOutlineOutlined></InputAdornment>,
              }}
              value={addTeamEmail}
              onChange={(e) => {setAddTeamEmail(e.target.value)}}                  
            />
          </div> 
          <div className="d-flex" style={{ flex: '2' }}>
            <TextField
              select
              variant="outlined"
              defaultValue={'full'}
              value={addTeamAccess}
              onChange={(e) => {setAddTeamAccess(e.target.value)}}        
            >
              {ACCESS_OPTIONS.map((access) => 
                <MenuItem key={access.name} value={access.val}>
                  {access.name}
                </MenuItem>)}
            </TextField>
          </div>             
        </div>
        <div style={{ display : 'flex', width: '100%', marginTop: '16px' }}>
          {invitedMembers.length < 1 && <Button className="btn-main gray outlined" style={{ height: '40px', padding: '10px 14px', marginLeft: 'auto' }}
            onClick={() => navigate('/interviews')}>
              Skip for now
          </Button>}
          <Button className="btn-main" style={{ height: '40px', padding: '10px 14px', marginLeft: invitedMembers.length < 1 ? '12px' : 'auto' }}
            onClick={() => callInviteTeamMember(addTeamEmail, addTeamAccess)}>
          <MailOutlineOutlined style={{ marginLeft: 'auto', fontSize: '18px', marginRight: '8px' }}></MailOutlineOutlined>
              {`Send Invite${addTeamEmailCount > 1 ? 's' : ''}`}
          </Button>
        </div>
        {invitedMembers.length > 0 &&
          <>
            <Divider style={{ marginTop: '24px', marginBottom: '16px' }}></Divider>
            <h5 className='section-heading'>Invited</h5>
            <div className='d-flex flex-col'>
              {
                invitedMembers.map((member) => {
                  return <div style={{ marginTop: '8px' }}>
                    <p className="color-gray" style={{ margin: 0, fontSize: '14px' }}>
                      {member}
                      <ChipTag variant="in-progress" text="Invited" style={{ width: 'max-content', float: 'right', marginTop: 'auto', marginBottom: 'auto' }} />
                    </p>
                  </div>;
                })
              }
            </div>
          </>
        }
        </>
      }
    </>)
  }
  
  const renderJoinFlow = () => {
    return (<>
      { teamStep == 0 && user && invitedTeam &&
        <>
          <h3 className="section-heading">You have been invited to {invitedTeam.name}</h3>
          <p className="section color-gray">
            Accept the invite to collaborate with your team.
          </p>
          <div className='d-flex flex-row' style={{ backgroundColor: '#F9FAFB', padding: '16px', borderRadius: '8px' }}>
            <Avatar
              className="my-auto"
              sx={{
                bgcolor: "var(--color-primary)",
                color: "white",
                width: 32,
                height: 32,
                borderRadius: '6px',
                fontSize: "18px",
                marginRight: "12px",
              }}
            >
              {Array.from(invitedTeam.name)[0]}
            </Avatar>
            <h4 className="color-main my-auto" style={{ lineHeight: "30px", fontSize: "16px", margin: 0 }}>{invitedTeam.name}</h4>
            <p className="color-gray my-auto" style={{ margin: 0, marginLeft: 'auto', fontSize: '14px', lineHeight: '30px' }}>
              {`${invitedTeam.members.filter(member => member.status == 'active').length} member${invitedTeam.members.filter(member => member.status == 'active').length > 1 ? 's' : ''}`}
            </p>
          </div>
          <div style={{ display : 'flex', width: '100%', marginTop: '12px' }}>
            {/* <Button className="btn-main gray outlined" style={{ height: '40px', padding: '10px 14px', marginLeft: 'auto' }}
              onClick={() => callInviteTeamMember(addTeamEmail, addTeamAccess)}>
                Skip for now
            </Button> */}
            <Button className="btn-main" style={{ height: '40px', padding: '10px 14px', marginLeft: 'auto' }}
              onClick={() => callAcceptJoinTeam(invitedTeam.name)}>
                Accept Invite
            </Button>
          </div>
        </>
      }
    </>)
  }

  return (
    <Container>
      <div className="d-flex flex-col">
        <h2 className="section mx-auto" style={{ marginTop: "10%", textAlign: 'center' }}>
          Welcome to Wayfaster!
        </h2>
        <div className="d-flex flex-row mx-auto">
          <div
            className="card w-33 d-flex flex-col mx-auto"
            style={{ padding: "24px" }}
          >
            {flow == 'create' &&
              renderCreateFlow()
            }
            {flow == 'join' &&
              renderJoinFlow()
            }
          </div>
        </div>
        
        {flow == 'create' && teamStep == 1 && invitedMembers.length > 0 && <div className='d-flex flex-row mx-auto' style={{ marginTop: '32px' }}>
          <h4 
            onClick={() => navigate('/interviews')}
            className="active-link my-auto" style={{ marginRight: '12px', lineHeight: '20px' }}>Get started with Wayfaster</h4>
    
          <button className="btn-main d-flex gray outlined mx-auto" style={{ marginTop: '4px', width: 'min-content' }}
            onClick={() => navigate('/interviews')}>
            <RocketLaunch fontSize="16px" className="color-primary m-auto" />
          </button>
        </div>}
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />       

    </Container>
  );
}

export default YourTeamHome;
