import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Container, CircularProgress } from "@mui/material";
import {
  Avatar
} from "@mui/material";

import NavigateBefore from "@mui/icons-material/NavigateBefore";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ConstructionIcon from '@mui/icons-material/Construction';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

// needed for row & cell level scope DnD setup
import { useAuth0 } from "@auth0/auth0-react";

import FormField from "../components/common/FormField";
import ChipTag from "../components/common/ChipTag";

import api from "../api/api";
import { Search } from "@mui/icons-material";

const TEMPLATE_ADMINS = [ 'varun@techinterviewer.ai', 'shreyas@techinterviewer.ai', 'shreyas.jaganmohan@gmail.com', 'miguelpatricktaruc@gmail.com' ]

function CreateInterviewTemplate({
  setCreationFlow,
  setActiveStep,
  setAddQuestions,
  setAddScoring,
  setAddOutputs,
  setShowAddQuestionInput,
  setRoleBackground,
  roleBackground,
  showInterviewSetup,
  headerText,
  label,
  sx,
  fullWidth,
  btnText
}) {
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState([]);

  const [interview, setInterview] = useState({});
  const [activeSubStep, setActiveSubStep] = useState(0);

  const [submitting, setSubmitting] = useState(false);


  useEffect(() => {
    api
      .getTemplates()
      .then((response) => {
        console.log(response.data);
        setTemplates(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  const handleEditMode = () => {

  }

  const handleCreateTemplate = () => {

  }

  const getIcon = (icon) => {
    switch (icon) {
      case 'retail':
        return <PointOfSaleIcon style={{ fontSize: '20px' }}></PointOfSaleIcon>
      case 'medical':
        return <MonitorHeartIcon style={{ fontSize: '20px' }}></MonitorHeartIcon>
      case 'design':
        return <DesignServicesIcon style={{ fontSize: '20px' }}></DesignServicesIcon>
      case 'phone':
        return <LocalPhoneIcon style={{ fontSize: '20px' }}></LocalPhoneIcon>
      case 'construction':
        return <ConstructionIcon style={{ fontSize: '20px' }}></ConstructionIcon>
      case 'customer':
        return <PersonIcon style={{ fontSize: '20px' }}></PersonIcon>
      case 'data':
        return <BarChartIcon style={{ fontSize: '20px' }}></BarChartIcon>
      case 'bolt':
      default:
        return <ElectricBoltIcon style={{ fontSize: '20px' }}></ElectricBoltIcon>;
    }
  }

  const renderChooseTemplateStep = () => {
    return (
      <div
        className="mx-auto"
        style={{ paddingTop: "40px", paddingBottom: "80px" }}
      >
        {/* Header */}
        <div className="d-flex flex-col section" style={{ textAlign: 'center'}}>
          <h2 className="section-heading">Engage more with templates</h2>
          <p className="section color-gray">
            Find and try templates with pre-set questions designed for different roles.
          </p>
        </div>
        
        <div className="d-flex flex-row" style={{ marginBottom: '16px' }}>
          {/* Back to interview setup */}
          <div className="d-flex flex-row" style={{ flex: 1 }}>
            { showInterviewSetup && 
              <div className="d-flex flex-row" style={{ marginBottom: '16px' }}>
                <button style={{ marginRight: "auto" }}
                  disabled={submitting}
                  className="btn-main small d-flex gray outlined my-auto"
                  onClick={() => setCreationFlow('')}
                >
                  <NavigateBefore fontSize="14px" className="color-heading m-auto" />
                </button>
                  <h5 className={ submitting ? 'inactive' : '' }style={{ margin: "auto 8px", lineHeight: "24px" }}>Back to interview setup</h5>              
              </div>
            }
          </div>

          {/* Search */}
          <div className="d-flex flex-row" style={{ flex: 1 }}>
            <FormField
              className="d-flex"
              style={{ maxWidth: '340px', marginRight: 'auto', marginLeft: 'auto', marginBottom: 0 }}
              label=""
              icon={<Search className="color-gray" style={{ fontSize: '20px' }}></Search>}
              placeholder="Search templates"
              value={search}
              onChange={(e) => {setSearch(e.target.value)}}
            />
          </div>

          {/* Create / Edit Templates */}
          <div className="d-flex flex-row" style={{ flex: 1 }}>
            {TEMPLATE_ADMINS.includes(user.email) && 
              <div className="d-flex flex-row" style={{ marginLeft: 'auto', marginBottom: '16px' }}>    
                <button 
                  style={{
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                  disabled={submitting}
                  className="btn-main small d-flex gray outlined my-auto"
                  onClick={() => handleEditMode()}
                >
                  <EditIcon style={{ fontSize: '16px' }} className="color-heading m-auto"></EditIcon>
                </button>
                <button 
                  style={{
                    width: "fit-content",
                    marginLeft: "12px",
                  }}
                  disabled={submitting}
                  className="btn-main small d-flex gray outlined my-auto"
                  onClick={() => handleCreateTemplate()}
                >
                  <AddIcon style={{ fontSize: '16px' }} className="color-heading m-auto"></AddIcon>
                </button>
              </div>
            }
          </div>
        </div>

        {/* Template Grid */}
        <div className="wrap-grid" style={{ width: fullWidth ? "100%" : "60%", overflowY: 'auto', padding: '8px' }}>
          { templates.filter((template) => {
            if (search.trim().length == 0) 
              return true;
            else
              return template.template_metadata.title.toLowerCase().includes(search.toLowerCase()) } ).map((template) =>
            { let metadata = template.template_metadata;
              return <Box
                className="d-flex flex-col card clickable"
                style={{ padding: "24px" }}
                onClick={() => {
                  console.log('clicked on card')

                  setRoleBackground({
                    ...roleBackground,
                    interviewTitle: template.title,
                    jobTitle: template.jobTitle,
                    length: template.length,
                  });
                  setAddQuestions({
                    questions: template.questions,
                    currentQuestion: {
                      text: '',
                      numFollowUps: 0,
                      editIndex: -1
                    }
                  });
                  setAddScoring({
                    signals: template.scorecard,
                    currentSignal: {
                      'signal': '',
                      'scale': 4,
                      'description': '',
                      '1PointCriteria': '',
                      '2PointCriteria': '',
                      '3PointCriteria': '',
                      '4PointCriteria': '',
                      'weight': 100, // percentage,
                      'editIndex': -1
                    }
                  });
                  setAddOutputs({
                    outputs: template.structuredOutputs,
                    currentOutput: {
                      output: '',
                      data_type: 'List',
                      extraction_instructions: ''
                    },
                    editIndex: -1
                  });
                  setActiveStep(4);
                }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "var(--color-main)",
                      color: "white",
                      width: 32,
                      height: 32,
                      borderRadius: "6px",
                      fontSize: "18px",
                      marginBottom: '24px',
                      backgroundImage: metadata.thumbnail ? `url(${metadata.thumbnail})` : ''
                    }}
                  >
                    { metadata.thumbnail ?
                      '' :
                      metadata.icon ?
                        getIcon(metadata.icon) :
                        <ElectricBoltIcon style={{ fontSize: '20px' }}></ElectricBoltIcon>
                    }
                  </Avatar>
                
                <h3 className="section-heading" style={{ fontSize: '16px' }}>
                  {metadata.title}
                </h3>
                <p className="color-gray" style={{ margin: 0, fontSize: '14px' }}>
                  {metadata.description}
                </p>
                
                <div className="d-flex flex-row" style={{ marginTop: '16px', flexWrap: 'wrap' }}>
                  { metadata.tags.length > 0 && metadata.tags.map((tag) =>
                    <ChipTag
                      className="m-auto"
                      variant="default"
                      text={tag}
                      style={{ marginTop: "8px", marginRight: '12px', width: 'min-content' }}
                    />
                  )}
                </div>

              </Box>
            }
          )}
        </div>

      </div>
    );
  };

  const renderActiveSubStepComponent = () => {
    switch (activeSubStep) {
      case 0:
        return renderChooseTemplateStep();
      default:
        return (
          <>
            <CircularProgress />
          </>
        );
    }
  };

  return (
    <Container>
      {renderActiveSubStepComponent()}
    </Container>
  );
}

export default CreateInterviewTemplate;
