import React from 'react'
import { styled } from '@mui/material/styles';
import { Circle } from '@mui/icons-material';
import Chip from '@mui/material/Chip';

/**
 * Custom styled ChipTag that uses underlying MUI Chip. Specify
 * variant for preset settings for label classes (determining color),
 * and presence of indicator.
 */
const variantsClasses = {
  'in-progress': 'color-progress',
  'completed': 'color-completed',
  'closed': 'color-closed',

  'accept': 'color-accept',
  'pending': 'color-gray',
  'action': 'color-mid',
  'reject': 'color-closed',
  'default': 'color-main'
}

const bgColors = {
  'in-progress': 'var(--color-progress-bg)',
  'completed': 'var(--color-completed-bg)',
  'closed': 'var(--color-closed-bg)',
  'pending': 'var(--color-lightgray)',
  'action': 'var(--color-mid-bg)',

  'accept': 'var(--color-accept-bg)',
  'reject': 'var(--color-reject-bg)',
  'default': 'var(--color-lightgray)'
}


const ChipTag = styled(({ className, indicator, variant, text, ...props  }) => (
    <Chip {...props} 
      label={
        <>
          <div className="d-flex">
            {indicator && <Circle className={["m-auto", variantsClasses[variant] ? variantsClasses[variant] : '' ]} style={{ fontSize: 6, marginRight: '4px' }}/>}
            <span className={variantsClasses[variant] ? variantsClasses[variant] : ''} style={{ fontWeight: 600 }}>{text}</span>
          </div>
        </>
      }
      sx={{
        border: `0.5px solid ${bgColors[variant]}`,
        background: bgColors[variant],
        borderRadius: '6px',
        fontSize: '12px',
        fontWeight: 600,
        height: '24px',
        '& .MuiChip-label': {
          paddingRight: '8px',
          paddingLeft: '8px',
        }
      }}/>
))();

export default ChipTag;