import React, { useState, useRef } from "react";
import { Avatar, Grow, Paper, MenuList, MenuItem } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { TableContainer, Table, TableRow, TableHead, TableBody, TableCell } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import { PlayCircle, UnfoldMore } from "@mui/icons-material";

function VoiceSelector({ currentVoice, voices, dialog, clicked }) {
  const [voiceOpen, setVoiceOpen] = useState(false);
  const [audio, setAudio] = useState(null);
  const voiceRef = useRef(null);

  const handleShowVoice = () => {
    setVoiceOpen(true);
  };

  return (
    <>
      <div className="d-flex flex-row" style={{ marginLeft: "auto", width: '100%' }}>
        <button
          className="btn-main gray outlined d-flex flex-row"
          style={{ height: "56px", borderBottom: '1px solid lightgray !important', borderRadius: '0px !important;', flex: 1 }}
          ref={voiceRef}
          onClick={handleShowVoice}
        >
            <Avatar
            className="my-auto"
            src={currentVoice.avatar_url}
            sx={{
              color: "black",
              width: 32,
              height: 32,
              borderRadius: "6px",
              textTransform: "capitalize",
              fontSize: "16px",
              marginRight: "12px",
            }}
          >
          </Avatar>          
          <div className="d-flex flex-col my-auto" style={{ textAlign: "start" }}>
            <h6
              className="color-main my-auto"
              style={{ marginRight: "4px", fontSize: "12px", lineHeight: '16px'}}
            >
              {currentVoice.voice_name}
            </h6>
          </div>
          {/* <ExpandMoreIcon className="my-auto color-main" fontSize="20px" style={{ marginLeft: 'auto' }} /> */}
          <UnfoldMore className="color-gray my-auto" style={{ marginLeft: 'auto', fontSize: '16px' }} />
        </button>
      </div>

      <Dialog open={voiceOpen} maxWidth="md">
          <DialogTitle>Voices</DialogTitle>
          <DialogContent>
            <Paper style={{ width: '30vw' }}>
              <TableContainer style={{ overflowX: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{
                          '& .MuiTableCell-root': {
                            lineHeight: '20px',
                            backgroundColor: '#FAFAFA',
                            color: 'var(--color-heading)',
                            padding: '12px 16px'
                          }
                        }}>
                        <TableCell>
                          Voice
                        </TableCell>
                        <TableCell>
                          Preview
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {voices.map((voice) => {
                        return (
                          <TableRow className={`clickableRow ${currentVoice.voice_id == voice.voice_id ? 'clickableRowSelected' : ''}`}
                            key={voice.voice_id}
                            onClick={() => {
                              clicked(voice);
                              setVoiceOpen(false);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell>
                              <div className="d-flex flex-row">
                                <Avatar
                                  className="my-auto"
                                  src={voice.avatar_url}
                                  sx={{
                                    border: currentVoice.voice_id == voice.voice_id ? '3px solid var(--color-primary)' : '',
                                    color: "black",
                                    width: 32,
                                    height: 32,
                                    textTransform: "capitalize",
                                    fontSize: "16px",
                                    marginRight: "12px",
                                  }}
                                >
                                </Avatar>          
                                <div className="d-flex flex-col my-auto" style={{ textAlign: "start" }}>
                                  <h6
                                    className="color-main my-auto"
                                    style={{ marginRight: "4px", fontSize: "12px", lineHeight: '16px'}}
                                  >
                                    {voice.voice_name}
                                  </h6>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <PlayCircle className="hoverable color-gray" style={{ fontSize: '18px' }} onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  let curr = new Audio(voice.preview_audio_url);
                                  if (audio && !audio.ended) {
                                    console.log('audio currently playing')
                                  }
                                  else {
                                    setAudio(curr)
                                    curr.play();
                                  }
                              }}></PlayCircle>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
              </TableContainer>
            </Paper>
          </DialogContent>
          <DialogActions>
              <button className="btn-main" onClick={() => setVoiceOpen(false)}>
                Close
              </button>
          </DialogActions>
      </Dialog>
    </>
  );
}

export default VoiceSelector;
