import React from "react";

import { Container } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import TextsmsIcon from "@mui/icons-material/Textsms";
import BoltIcon from "@mui/icons-material/Bolt";
import ChipTag from "../components/common/ChipTag";
import { AppRegistration } from "@mui/icons-material";

function CreateInterviewHome({ setCreationFlow }) {
  return (
    <Container>
      <div style={{ width: "100%" }}>
        <h2
          className="section mx-auto"
          style={{ marginTop: "10%", textAlign: "center" }}
        >
          Choose how to set up the interview
        </h2>
        <div
          className="d-flex flex-row mx-auto"
          style={{ margin: "auto", width: "100%" }}
        >
          <div
            className="card w-33 clickable"
            onClick={() => setCreationFlow("jd")}
            style={{ marginRight: "24px", padding: "24px", width: "100%" }}
          >
            <DocumentScannerIcon
              className="section color-gray"
              fontSize="large"
            />
            <h3 className="section-heading">Upload from job description</h3>
            <p className="section color-gray">
              Import a job description and create personalized questions tailored to the role and job requirements. 
            </p>
            {/* <ChipTag
              className="m-auto"
              variant="default"
              text="Recommended"
              style={{ marginLeft: "-2px", marginTop: "-8px" }}
            /> */}
          </div>

          <div
            className="card w-33 clickable"
            onClick={() => setCreationFlow("template")}
            style={{ marginRight: "24px", padding: "24px", width: "100%" }}
          >
            <AppRegistration
              className="section color-gray"
              fontSize="large"
            />
            <h3 className="section-heading">Choose template</h3>
            <p className="section color-gray">
              Select from a variety of templates with pre-set questions designed for different roles such as designers, developers, and more.
            </p>
            <ChipTag
              className="m-auto"
              variant="default"
              text="Recommended"
              style={{ marginLeft: "-2px", marginTop: "-8px" }}
            />
          </div>

          <div
            className="card w-33 clickable"
            onClick={() => setCreationFlow("self")}
            style={{ padding: "24px", width: "100%" }}
          >
            <TextsmsIcon className="section color-gray" fontSize="large" />
            <h3 className="section-heading">Create your own</h3>
            <p className="section color-gray">
              Build a completely unique interview from scratch to perfectly suit your specific needs and preferences.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default CreateInterviewHome;
