import React from "react";

import { Avatar, CircularProgress } from "@mui/material";

import ChipTag from "../common/ChipTag";
import LogoSvg from "../../wayfaster-icon-bg-sm.svg";
import { TextSnippet } from "@mui/icons-material";

function SimulatedPreview({ preview, loading }) {
  return (
    <>
      <div className="d-flex flex-col">
        {loading ? (
          <>
          <div className="d-flex flex-row">
            <CircularProgress style={{ color: "var(--color-primary) !important", width: '24px', height: '24px', marginRight: '12px' }}/>
            <h5 className="color-gray my-auto">Generating preview...</h5>
        </div>
          </>
        ) : preview && preview.length > 0 ? (
          preview.map((transcript) => {
            return (
              <div className="section d-flex flex-col" key={transcript.text}>
                <div
                  className="d-flex flex-row"
                  style={{ marginBottom: "12px" }}
                >
                  {transcript.speaker == "interviewer" ? (
                    <img
                      className="my-auto"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                        borderRadius: '50%'
                      }}
                      alt="Wayfaster"
                      src={LogoSvg}
                    />
                  ) : (
                    <Avatar
                      className="my-auto"
                      sx={{
                        bgcolor: "var(--color-progress)",
                        color: "white",
                        width: 24,
                        height: 24,
                        fontSize: "12px",
                        marginRight: "8px",
                      }}
                    >
                      JD
                    </Avatar>
                  )}
                  <h5
                    className="my-auto"
                    style={{ lineHeight: "20px", marginRight: "12px" }}
                  >
                    {transcript.speaker == "interviewer"
                      ? "Wayfaster"
                      : "James Doe"}
                  </h5>
                  {transcript.speaker == "candidate" && (
                    <ChipTag
                      variant="default"
                      text="Candidate"
                      style={{ width: "max-content" }}
                    />
                  )}
                </div>
                <p
                  className="color-main my-auto"
                  style={{ lineHeight: "24px" }}
                >
                  {transcript.text}
                </p>
              </div>
            );
          })
        ) : (
          <div className="section d-flex flex-col">
            <h5 className="color-gray section-heading">
              <TextSnippet
                className="color-gray"
                style={{ opacity: 0.75, marginBottom: "12px" }}
              ></TextSnippet>{" "}
              <br />
              Add or select a question to simulate an interview preview with a
              candidate.
            </h5>
          </div>
        )}
      </div>
      {/* <button className="btn-main d-flex" style={{ marginTop: '16px' }}
    onClick={() => {
        console.log('generating conversation');
        callGenerateConversationPreview({
        topic: 'Can you tell me about a time you had to work through a difficult UX design problem?',
        follow_ups: 2,
        context_type: 'question_preview',
        job_title: roleBackground.jobTitle, 
        title: roleBackground.interviewTitle
        })}}>
    Generate
    </button> */}
    </>
  );
}

export default SimulatedPreview;
