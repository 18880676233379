import React, { useCallback } from "react";
import { InputLabel, Checkbox, Popper, Grow, MenuList, ClickAwayListener, Select, Menu, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Box, Chip, FormControl, Accordion, AppBar, Toolbar, IconButton, Button, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, TextField,CircularProgress, LinearProgress, Alert, Snackbar, TableContainer, TableCell, TableBody, TableSortLabel, Table, TableRow, TableHead, Card, CardContent, Tab, Tabs, TextareaAutosize, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw";
import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from "react-router-dom";
import Vapi from '@vapi-ai/web';
import axios from 'axios';
import io from 'socket.io-client';
import SyncIcon from '@mui/icons-material/Sync';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GoogleIcon from '@mui/icons-material/Google';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LinkIcon from '@mui/icons-material/Link';
import Add from "@mui/icons-material/Add";
import StopIcon from '@mui/icons-material/Stop';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ClearIcon from "@mui/icons-material/Clear";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AlertTitle from '@mui/material/AlertTitle';
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import UnfoldMore from "@mui/icons-material/UnfoldMore";
import { Scrollbars } from 'react-custom-scrollbars';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import * as loom from '@loomhq/loom-embed'

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import ScheduleIcon from '@mui/icons-material/Schedule';
import ArticleIcon from '@mui/icons-material/Article';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ArrowBack, Computer, Share, ExpandLess, NavigateBefore, NavigateNext, ExpandMore, Check, Clear, MailOutlineOutlined, ContentCopyOutlined, CloseFullscreen, OpenInFull } from "@mui/icons-material";
import TwitterIcon from '@mui/icons-material/Twitter';
import EditIconSvg from './EditIcon.svg';
import DeleteIconSvg from './DeleteIcon.svg';
import CopyIconSvg from './CopyIcon.svg';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PhoneIcon from '@mui/icons-material/Phone';
import MakeInterviewUnique from './MakeInterviewUnique.svg';
import PlayIcon from './PlayIcon.svg';
import ComputerIcon from '@mui/icons-material/Computer';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import ReactGA from "react-ga4";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIconNewSvg from './EditIconNew.svg';
import DeleteIconNewSvg from './DeleteIconNew.svg';
import LoomLogoSvg from './LoomLogo.svg';
import CamLogoSvg from './CamLogo.svg';

import TooltipCustom from "./components/common/TooltipCustom";
import ChipTag from "./components/common/ChipTag";
import WayfasterLogo from "./wayfaster-logo-w.svg";

import Switcher from "./components/team/Switcher";

import CreateSession from './CreateSession.svg';
import {
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom'
import {
  useAuth0
} from '@auth0/auth0-react'
import { ArrowDropDown, CheckCircle, Circle, QueryStats, ThreeP } from "@mui/icons-material";
import FormField from "./components/common/FormField";
import FormSelect from "./components/common/FormSelect";
import api from "./api/api";
import CreateInterviewJD from "./pages/CreateInterviewJD";
import CreateInterviewTemplate from "./pages/CreateInterviewTemplate";
import CreateInterviewHome from "./pages/CreateInterviewHome";
import TableSection from "./components/table/TableSection";

import SimulatedPreview from "./components/interview/SimulatedPreview";
import Papa from "papaparse";
import FormEditable from "./components/common/FormEditable";
import VoiceSelector from "./components/interview/VoiceSelector";
import { useMergeLink } from "@mergeapi/react-merge-link";

const SECTIONS_OPTIONS = ['Introduction', 'Background']

ReactGA.initialize([
  {
    trackingId: "G-KEJZXKN80Q",
  },
]);
ReactGA.send({ hitType: "pageview", page: "/", title: "hit" });

const vapi = new Vapi('c6a21d89-0ffd-45b2-9ad4-28a1fd2708f8')
var callId;
var socket;

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '6319e72d-0dc8-4bbc-86cc-09d1701df65d' // prod
// var proxyEndpoint = 'http://localhost:5555'
// var wsEndpoint = 'ws://localhost:5555'
// var vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'

var env = String(process.env.REACT_APP_ENV)
console.log('env', env)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
}

var stopInvoked = false;
const emptyInterviewObject = {
  state: 'none',
  messages: [],
  context: [],
  mute: false,
  metadata: {},
  scorecard: [],
  scoring_pending: false
}

// TODO: SWITCH BACK, only for staging testing
var interviewDuration = 600;
var globalInterviewType = 'system_design_event_ticketing'
var interviewerName = 'Steve'
const urlParams = new URLSearchParams(window.location.search);
const interviewTypeQueryParam = urlParams.get('interview_type');
if (interviewTypeQueryParam) {
  globalInterviewType = interviewTypeQueryParam
}
var allSysDesignParam = urlParams.get('all_sys_design');
if(!allSysDesignParam) {
  allSysDesignParam = 'false'
}

if(globalInterviewType === 'eve') {
  interviewerName = 'Isabelle'
}

export const InterviewsList = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const [interviews, setInterviews] = useState([])
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false)
  useEffect(() => {
    if(!isLoading && user.email !== '' && !getInterviewsCalled) {
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      if(!teamId) {
        navigate('/')
        return
      }

      api.getInterviews(userId, teamId)
      .then((fetchedInterviews) => {
        console.log(fetchedInterviews)
        if(fetchedInterviews) {
          setInterviews(fetchedInterviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))                
          setGetInterviewsCalled(true)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [isLoading, user, interviews, getInterviewsCalled]);

  if(!isAuthenticated) {
    navigate('/interviews')
  }

  if(isLoading) {
    return (
      <div>
        <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
      </div>
    )
  }

  const handleOnCopy = (interviewConfigId) => {
    console.log('handleOnCopy', interviewConfigId)
    navigate(`/create-interview?copy_from=${interviewConfigId}`);
  }

  console.log(interviews)

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', width: '73%', margin: 'auto', marginTop: '100px' }}>
        <p style={{ fontFamily: 'PPNeueMontreal', fontSize: 28, fontWeight: 500 }}>
          My Interviews
        </p>
        <div style={{ marginLeft: 'auto', marginRight: 0 }}>
          {/* <button variant="contained" className="btn" onClick={() => navigate('/create-interview')}>
            Create New Assessment
          </button>         */}
          <Button
            variant="contained"
            className="btn-main"
            style={{ fontSize: 12, paddingLeft:'20px', paddingRight: '20px', fontWeight: 600, boxShadow:'none'}}
            onClick={() => navigate('/create-interview')}
          >
            Create Interview
          </Button>
        </div>
      </div>
      <div style={{ padding: 25, width: '73%', margin: 'auto' }}>
        <TableContainer component={Paper} style={{ boxShadow: '0px 24px 38px 0px rgba(9, 15, 37, 0.07)'}}>
          <Table>
            <TableHead style={{ backgroundColor: '#FCFCFC', color: '#656565',  borderBottom: '1.5px solid #F4F5F6' , lineHeight: 1.5 }}>
              <TableRow>
                <TableCell style={{ paddingLeft: '40px' }}>Name</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Session Count</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interviews && interviews.map((interview) => (
                <TableRow className="interviewRow clickableRow" key={interview.id} onClick={() => navigate(`/interviews/${interview._id}`)}>
                  <TableCell style={{ paddingLeft: '40px', borderBottom: '1.5px solid #F4F5F6',  fontWeight: 600 }}>{interview.title}</TableCell>
                  <TableCell style={{ borderBottom: '1.5px solid #F4F5F6' }}>{new Date(interview.createdAt).toDateString()}</TableCell>
                  <TableCell style={{ borderBottom: '1.5px solid #F4F5F6' }}>{new Date(interview.updatedAt).toDateString()}</TableCell>
                  <TableCell style={{ borderBottom: '1.5px solid #F4F5F6' }}>{interview.numSessions}</TableCell>
                  <TableCell style={{ borderBottom: '1.5px solid #F4F5F6' }}>
                    <Chip
                      label={
                        <>
                          <Circle style={{ fontSize: 10, color: '#FFC300' }}/>  &nbsp;
                          <span style={{ fontWeight: 500 }}>In progress</span>
                        </>
                      }
                      className='in-progress-chip'
                      variant="contained"
                      sx={{
                        color: 'black',
                        border: '1px solid #D9D9D9',
                        backgroundColor: 'white'
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ borderBottom: '1.5px solid #F4F5F6' }}>
                    <IconButton className="btn outlined" style={{ fontSize: 12 }} onClick={(e) => {
                      e.stopPropagation()
                      handleOnCopy(interview._id)}
                    }>
                      <img src={CopyIconSvg} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {getInterviewsCalled && (!interviews || interviews.length === 0) &&
            <p style={{ textAlign: 'center'}}>No interviews found. Create your first interview <Link to="/create-interview">here.</Link></p>
          }
          {!getInterviewsCalled &&
            <div style={{ display: 'flex', justifyContent: 'center', margin: 25 }}>
              <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
            </div>
          }
        </TableContainer>
      </div>
    </div>
  )
}

const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  })

  // console.log(rowId)

  return (
    <IconButton {...attributes} {...listeners} style={{ height: 'fit-content' }}>
      <DragHandleIcon />
    </IconButton>
  )
}

const DraggableRow = ({ idx, row, selected, setSelectedPreview, stateAddQuestions, setAddQuestions, setShowAddQuestionInput }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
    >
      <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', padding: '0px', margin: '8px' }}>
        <RowDragHandleCell rowId={row.id}/>
        <div style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '8px', marginLeft: '8px' }}>{row.question}</div>
      </TableCell>
      <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{row.follow_ups}</TableCell>
        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px', marginRight: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto' }}>
            {/* Preview */}
            <IconButton
              onClick={() => {
                if (!selected) setSelectedPreview(row);
              }}
              style={{ color: selected ? 'var(--color-primary)' : 'var(--color-main)', marginRight: '4px' }}
            >
              <TextSnippetIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {/* Edit */}
            <IconButton
              onClick={() => {
                setAddQuestions({
                  ...stateAddQuestions,
                  currentQuestion: {
                    text: row.question,
                    numFollowUps: row.follow_ups,
                    id: row.id,
                    editIndex: idx,
                  },
                });
                setShowAddQuestionInput(true)
              }}
              style={{ color: '#656565', marginRight: '4px' }}
            >
              <img src={EditIconSvg} />
            </IconButton>
            {/* Delete */}
            <IconButton
              onClick={() => {
                let newQuestions = stateAddQuestions.questions.filter((_, questionIdx) => questionIdx !== idx);
                setAddQuestions({ ...stateAddQuestions, questions: newQuestions });
              }}
              style={{ color: '#656565' }}
            >
              <img src={DeleteIconSvg} />
            </IconButton>
          </div>
        </TableCell>
    </TableRow>
  )
}

const SessionSpecificDraggableRow = ({ idx, row, stateAddQuestions, setAddQuestions }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
    >
      <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', padding: '0px', margin: '8px' }}>
        <RowDragHandleCell rowId={row.id}/>
        {(stateAddQuestions.currentQuestion && stateAddQuestions.currentQuestion.id === row.id) ?
          <textarea
            style={{ fontSize: '11px', padding: '10px', height: '75px', width: '100%', borderRadius: 6, border: '1px solid lightgray', resize: 'none', fontFamily: 'PPNeueMontreal' }}
            value={row.question}
            onChange={(e) => {
              let newStateAddQuestions = JSON.parse(JSON.stringify(stateAddQuestions))
              newStateAddQuestions.questions[idx].question = e.target.value
              setAddQuestions({
                ...newStateAddQuestions,
                currentQuestion: {
                  text: e.target.value,
                  ...stateAddQuestions.currentQuestion
                }
              })
            }}
          />
        :
          <div style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '8px', marginLeft: '8px', fontFamily: 'PPNeueMontreal', fontSize: '11px' }}>{row.question}</div>
        }

      </TableCell>
      <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>
      {(stateAddQuestions.currentQuestion && stateAddQuestions.currentQuestion.id === row.id) ?
        <TextField value={row.follow_ups} type="number" onChange={(e) => {
          let newStateAddQuestions = JSON.parse(JSON.stringify(stateAddQuestions))
          newStateAddQuestions.questions[idx].follow_ups = e.target.value
          setAddQuestions({
            ...newStateAddQuestions,
            currentQuestion: {
              numFollowUps: e.target.value,
              ...stateAddQuestions.currentQuestion
            }
          })
        }} />
        : row.follow_ups}
      </TableCell>
        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px', marginRight: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto' }}>
            {/* Edit */}
            <IconButton
              onClick={() => {
                setAddQuestions({
                  ...stateAddQuestions,
                  currentQuestion: {
                    text: row.question,
                    numFollowUps: row.follow_ups,
                    id: row.id,
                    editIndex: idx,
                  },
                });
              }}
              style={{ color: '#656565', marginRight: '4px' }}
            >
              <img src={EditIconSvg} />
            </IconButton>
            {/* Delete */}
            <IconButton
              onClick={() => {
                let newQuestions = stateAddQuestions.questions.filter((_, questionIdx) => questionIdx !== idx);
                setAddQuestions({ ...stateAddQuestions, questions: newQuestions });
              }}
              style={{ color: '#656565' }}
            >
              <img src={DeleteIconSvg} />
            </IconButton>
          </div>
        </TableCell>
    </TableRow>
  )
}

export const CreateInterview = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [activeStep, setActiveStep] = useState(-1)
  const [skipped, setSkipped] = useState(new Set())
  const [errMsgs, setErrMsgs] = useState('')
  const [creationFlow, setCreationFlow] = useState('');
  const [sections, setSections] = useState(SECTIONS_OPTIONS)
  const [newSectionName, setNewSectionName] = useState("")
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState({});
  const [interviewPreview, setInterviewPreview] = useState([])

  // "Role Background"
  const [roleBackground, setRoleBackground] = useState({
    interviewTitle: '',
    assessmentLanguage: 'English',
    jobTitle: '',
    interviewLength: 10
  })

  // "Add Questions"
  const [addQuestions, setAddQuestions] = useState({
    questions: [],
    currentQuestion: {
      text: 'What has been the highlight of your past role?',
      numFollowUps: 1,
      editIndex: -1,
    },
    edit: false
  })
  const [showAddQuestionInput, setShowAddQuestionInput] = useState(false)

  // "Add Outputs"
  const [addOutputs, setAddOutputs] = useState({
    outputs: [{ key: 'past_employers', data_type: 'List', extraction_instructions: 'get a list of past employers the candidate has worked for'}],
    currentOutput: {
      output: '',
      data_type: 'List',
      extraction_instructions: ''
    },
    editIndex: -1
  })
  const [showAddOutputInput, setShowAddOutputInput] = useState(false)

  // "Add Scoring"
  const [addScoring, setAddScoring] = useState({
    signals: [
      {
        "signal": "English Proficiency",
        "1PointCriteria": "\t•\tClarity: Speaks unclearly with strong accents or speech issues that significantly hinder understanding.\n\t•\tVocabulary: Uses very basic vocabulary with little to no construction-specific terms and frequent major errors.\n\t•\tComprehension: Struggles to understand even basic questions and instructions, requiring frequent repetition and clarification.\n\t•\tResponsiveness: Responds inappropriately or with significant delays, showing limited understanding of the conversation context.",
        "2PointCriteria": "\t•\tClarity: Speaks with noticeable accents or speech issues that occasionally hinder understanding.\n\t•\tVocabulary: Uses basic vocabulary with limited construction-specific terms and frequent minor errors.\n\t•\tComprehension: Understands basic questions and instructions but often needs repetition or clarification for more complex ones.\n\t•\tResponsiveness: Responds to questions with some delays or hesitations, showing a partial understanding of the conversation context.",
        "3PointCriteria": "\t•\tClarity: Speaks clearly with minor accents or speech issues that do not significantly hinder understanding.\n\t•\tVocabulary: Uses a good range of vocabulary, including some construction-specific terms, with occasional minor errors.\n\t•\tComprehension: Understands most questions and instructions with little need for repetition or clarification.\n\t•\tResponsiveness: Responds appropriately to questions and demonstrates a good understanding of the conversation context.",
        "4PointCriteria": "\t•\tClarity: Speaks very clearly and articulately, with no noticeable accent or speech impediments that hinder understanding.\n\t•\tVocabulary: Uses a wide range of vocabulary, including construction-specific terms and technical language, accurately and appropriately.\n\t•\tComprehension: Fully understands questions and instructions immediately, without needing repetition or clarification.\n\t•\tResponsiveness: Responds promptly and appropriately to questions, showing a thorough understanding of the conversation context.\n",
        "scale": 4,
        "weight": 100
      }
    ],
    currentSignal: {
      'signal': '',
      'scale': 4,
      'description': '',
      '1PointCriteria': '',
      '2PointCriteria': '',
      '3PointCriteria': '',
      '4PointCriteria': '',
      'weight': 100, // percentage,
      'editIndex': -1
    }
  })
  const [generatedCriteria, setGeneratedCriteria] = useState({});
  const [showAddSignalInput, setShowAddSignalInput] = useState(false)
  const [showAddSignalAssistInput, setShowAddSignalAssistInput] = useState(false)

  // "Review Test"
  const [reviewTest, setReviewTest] = useState({
    antiCheating: true,
    videoRecording: true,
    audioRecording: true,
    prompt: ''
  })

  const [expanded, setExpanded] = useState(false)

  const [fetched, setFetched] = useState(false)
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const copyFrom = urlParams.get('copy_from');
    if(!isLoading && isAuthenticated && copyFrom !== undefined && !fetched) {
      setFetched(true)
      var userId = user.email
      const teamId = localStorage.getItem('teamId')
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      api.getInterview(copyFrom, userId, teamId)
      .then((response) => {
        console.log(response)
        let questions = response.data.questions
        questions.shift()

        setRoleBackground({
          assessmentLanguage: 'English',
          interviewTitle: response.data.title,
          jobTitle: '',
          interviewLength: 10
        })

        setAddQuestions({
          ...addQuestions,
          questions: questions,
        })

        setAddScoring({
          ...addScoring,
          signals: response.data.scorecard
        })

        setAddOutputs({
          ...addOutputs,
          outputs: response.data.structuredOutputs
        })

        setReviewTest({
          antiCheating: response.data.antiCheating,
          videoRecording: response.data.videoRecording,
          audioRecording: response.data.audioRecording,
          prompt: response.data.prompt
        })

        // redirect to self-create flow if questions populated from copy
        setCreationFlow('self');
        setActiveStep(0);
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [isLoading, isAuthenticated, user, fetched, setFetched,
  addQuestions, setAddQuestions, addScoring, setAddScoring, addOutputs, setAddOutputs, setReviewTest]);

  const steps = ['Details', 'Questions', 'Scoring', 'Outputs', 'Review'];
  const navigate = useNavigate();
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )


  const createInterview = () => {
    if (user) {
      const interviewConfig = {
        title: roleBackground.interviewTitle,
        length: roleBackground.interviewLength,
        language: roleBackground.assessmentLanguage,
        jobTitle: roleBackground.jobTitle,
        questions: addQuestions.questions,
        scorecard: addScoring.signals,
        prompt: reviewTest.prompt,
        structuredOutputs: addOutputs.outputs,
      };

      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const payload = {
        userId: userId,
        teamId: localStorage.getItem('teamId'),
        interviewConfig: interviewConfig,
      };
      api
        .createInterview(payload)
        .then((response) => {
          console.log(response.data);
          navigate("/interviews/" + response.data.id);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('User not logged in');
    }
  };

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const interviewDetailsValid = () => {
    let valid = true
    let msgs = []

    if(roleBackground.assessmentLanguage.length === 0 || roleBackground.interviewTitle.length === 0 || roleBackground.jobTitle.length === 0) {
      console.log(roleBackground)
      valid = false
      msgs.push('Please fill in all fields')
    }

    if(roleBackground.interviewLength < 5 || roleBackground.interviewLength > 20) {
      valid = false
      msgs.push('Max Interview length must be at least 5 minutes and no more than 20.')
    }

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const questionDetailsValid = () => {
    let valid = true
    let msgs = []

    if(addQuestions.questions.length === 0) {
      valid = false
      msgs.push('You must add at least one question before proceeding.')
    }

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const scoringDetailsValid = () => {
    let msgs = []
    let valid = true

    // check that percentages == 100
    console.log(addScoring.signals)
    let totalWeight = addScoring.signals.reduce((total, signal) => total + signal.weight, 0);
    console.log(totalWeight)
    if(addScoring.signals.length > 0 && totalWeight !== 100) {
      valid = false;
      msgs.push('The total weight of all skills must add up to 100%.');
    }

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const outputsDetailsValid = () => {
    let msgs = []
    let valid = true

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const reviewValid = () => {
    let valid = true
    let msgs = []

    return {
      isValid: valid,
      msgs: msgs
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // form validation
    if(activeStep === 0) {
      // validate Interview Details
      const { isValid, msgs } = interviewDetailsValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
    } else if (activeStep === 1) {
      // validate question details
      const { isValid, msgs } = questionDetailsValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
    } else if (activeStep === 2) {
      // validate scoring details
      const { isValid, msgs } = scoringDetailsValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
    } else if (activeStep === 3) {
      // validate outputs details
      const { isValid, msgs } = outputsDetailsValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
    } else if (activeStep === 4) {
      // validate review test details
      const { isValid, msgs } = reviewValid()
      if(!isValid) {
        setErrMsgs(msgs)
        return
      }
      createInterview();
    }

    setErrMsgs([])
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep == 0) {
      setCreationFlow('')
    }
    setErrMsgs([])
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setAddQuestions((prevState) => {
        const oldIndex = prevState.questions.findIndex((question) => question.id === active.id);
        const newIndex = prevState.questions.findIndex((question) => question.id === over.id);
        return {
          ...prevState,
          questions: arrayMove(prevState.questions, oldIndex, newIndex),
        };
      });
    }
  };

  const renderFlowPrompt = () => {
    return <CreateInterviewHome setCreationFlow={setCreationFlow}/>
  }

  const renderJDUpload = () => {
    return <CreateInterviewJD
      setCreationFlow={setCreationFlow}
      setActiveStep={setActiveStep}
      setAddQuestions={setAddQuestions}
      setShowAddQuestionInput={setShowAddQuestionInput}
      setRoleBackground={setRoleBackground}
      roleBackground={roleBackground}
      showInterviewSetup={true}
      sx={{ width: '800px' }}
    />
  }

  const renderTemplateFlow = () => {
    return <CreateInterviewTemplate
      setCreationFlow={setCreationFlow}
      setActiveStep={setActiveStep}
      setAddQuestions={setAddQuestions}
      setAddScoring={setAddScoring}
      setAddOutputs={setAddOutputs}
      setShowAddQuestionInput={setShowAddQuestionInput}
      setRoleBackground={setRoleBackground}
      roleBackground={roleBackground}
      showInterviewSetup={true}
      sx={{ width: '800px' }}
    />
  }

  const renderRoleBackground = () => {
    return (
      <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
        <Box container className="d-flex flex-col"
          style={{ width: '60%', margin: 'auto', border: '1px solid lightgray', padding: '24px', borderRadius: '6px' }}>
          <h3 className="section" style={{ marginTop: 0 }}>Interview Details</h3>

          <FormField
            required
            label="Interview Title"
            placeholder="Retail Sales Associate Case Study"
            value={roleBackground.interviewTitle}
            onChange={(e) => {setRoleBackground({ ...roleBackground, interviewTitle: e.target.value })}}
          />

          <FormField
            required
            label="Job Title"
            placeholder="Retail Sales Associate"
            value={roleBackground.jobTitle}
            onChange={(e) => {setRoleBackground({ ...roleBackground, jobTitle: e.target.value })}}
            />

          <FormField
            required
            label="Assessment Language"
            placeholder="English"
            tooltip
            tooltipTitle={<>
              <p className="m-auto">
                We currently support conducting interviews in English.
              </p>
            </>}
            disabled
            value={roleBackground.assessmentLanguage}
            />

          <FormField
            required
            label="Max Interview Length (minutes)"
            type="number"
            value={roleBackground.interviewLength}
            onChange={(e) => {setRoleBackground({ ...roleBackground, interviewLength: e.target.value })}}
            />
        </Box>
      </div>
    )
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }

  const triggerShowAddModal = (type) => {
    setErrMsgs([]);
    switch (type) {
      case 'question':
        return setShowAddQuestionInput(true);
      case 'signal':
        return setShowAddSignalInput(true);
      case 'signal-assist':
        return setShowAddSignalAssistInput(true);
      case 'output':
        return setShowAddOutputInput(true)
      default:
        return;
    }
  }

  const cancelShowAddModal = (type) => {
    setErrMsgs([]);
    switch (type) {
      case 'question':
        return setShowAddQuestionInput(false);
      case 'signal':
        setScoringAssistStep(0);
        setScoringAddTab(0);
        setScoringActiveCriteria('1PointCriteria');
        return setShowAddSignalInput(false);
      case 'signal-assist':
        setScoringAssistStep(0);
        setScoringAddTab(0);
        setScoringActiveCriteria('1PointCriteria');
        return setShowAddSignalAssistInput(false);
      case 'output':
        return setShowAddOutputInput(false)
      default:
        return;
    }
  }

  const saveAddQuestion = async () => {
    const questionText = addQuestions.currentQuestion.text;
    const words = questionText.split(' ');
    let msgs = []
    if (words.length < 5) {
      msgs.push('Question must have at least 5 words.');
    }

    const numFollowUps = parseInt(addQuestions.currentQuestion.numFollowUps);
    if (isNaN(numFollowUps) || numFollowUps < 0) {
      msgs.push('Number of follow up questions must be at least 0.');
    }

    if(msgs.length > 0 ) {
      setErrMsgs(msgs)
      setShowAddQuestionInput(false)
    } else {
      setErrMsgs([])
      if(addQuestions.currentQuestion.editIndex >= 0) {
        let updatedQuestions = [...addQuestions.questions];
        updatedQuestions[addQuestions.currentQuestion.editIndex] = {
          question: addQuestions.currentQuestion.text,
          follow_ups: addQuestions.currentQuestion.numFollowUps,
          id: addQuestions.currentQuestion.id,
        }
        console.log(updatedQuestions)
        setAddQuestions({ questions: updatedQuestions, currentQuestion: { text: '', numFollowUps: 1 }})
      } else {
        const randomId = uuidv4()
        setAddQuestions({ questions: [...addQuestions.questions, {
          question: addQuestions.currentQuestion.text,
          follow_ups: addQuestions.currentQuestion.numFollowUps,
          id: randomId,
        }], currentQuestion: { text: 'What has been the highlight of your role?', numFollowUps: 1 }})
      }
      setShowAddQuestionInput(false)
      let editing = addQuestions.currentQuestion.editIndex >= 0;
      let question_id = editing ? addQuestions.currentQuestion.id : uuidv4()
      let question = {
        question: addQuestions.currentQuestion.text,
        follow_ups: addQuestions.currentQuestion.numFollowUps,
        section: addQuestions.currentQuestion.section,
        id: editing ? addQuestions.currentQuestion.id : uuidv4(),
      }
      await updateInAddQuestions(question_id, question);
      setSelectedPreview(question)
    }
  }

  function groupBy(arr, property) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur];
      return acc;
    }, {});
  }

  function sectionSort(a, b) {
    const top_val = 'Introduction'
    const null_val = 'undefined'

    // top_val comes first
    if (a == top_val) return -1;
    if (b == top_val) return 1;


    // nulls sort after anything else
    if (a === null_val) {
      return 1;
    }
    if (b === null_val) {
        return -1;
    }

    // neither matches `value` - compare them normally
    return a.localeCompare(b);
  }

  const setSelectedPreview = async (question) => {
    setCurrentPreviewQuestion({
      topic: question.question,
      follow_ups: question.follow_ups
    })

    if (question.sample) {
      setInterviewPreview(question.sample);
    } else {
      let sample = await callGenerateConversationPreview({
        topic: question.question,
        follow_ups: String(question.follow_ups),
        context_type: 'question_preview',
        job_title: roleBackground.jobTitle, 
        title: roleBackground.interviewTitle
      })
      if (sample) setInterviewPreview(sample);
      else setCurrentPreviewQuestion({});
      console.log('sample', sample)
      question = {...question, sample}
      updateInAddQuestions(question.id, question)
    }
  }

  const updateInAddQuestions = async (rowId, updatedRow) => {
    let updatedQuestions = [...addQuestions.questions];
    let updatedIndex = updatedQuestions.findIndex((question) => question.id == rowId)
    if (updatedIndex >= 0) updatedQuestions[updatedIndex] = updatedRow
    else updatedQuestions = [...updatedQuestions, updatedRow]
    setAddQuestions({ questions: updatedQuestions, currentQuestion: { text: '', numFollowUps: 1 }})
  }

  const callGenerateConversationPreview = ({topic, follow_ups, context_type, job_title, title }) => {
    setLoadingPreview(true);
    return api.generateConversationPreview({ topic, follow_ups, context_type, job_title, title })
    .then((response) => {
      setLoadingPreview(false);
      return(response.data.sample)
    })
    .catch((error) => {
      console.log(error)
      setLoadingPreview(false);
      return null;
    })
  }
  
  const thumbVLight = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: '#d6d6d6', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  const renderAddQuestions = () => {
    return (
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div className="d-flex flex-col mx-auto" style={{ flex: 3, paddingTop: '40px', paddingBottom: '80px' }}>
          {/* Interview Questions Display */}
          <Paper className="d-flex flex-col" style={{ flex: 1, minHeight: '400px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9' }}>
            <div className="d-flex flex-row">
              <h3 className="my-auto">Interview Questions</h3>
            
              <button className="btn-main d-flex" style={{ marginLeft: 'auto', width: 'fit-content' }}
                  onClick={() => {triggerShowAddModal('question')}}>
                  <Add className="my-auto" fontSize="24px"/>
                  Add Question
              </button>
            </div>
            <p className="color-gray" style={{ fontSize: 14 }}>Add questions that will be asked during the interview.</p>
            <Scrollbars renderThumbVertical={thumbVLight} style={{ transition: 'all .3s ease', scrollbarColor: '#d6d6d6 white' }}>
              <Grid container>
                <TableContainer style={{ padding: 0 , width: '100%', margin: 0}}>
                  <Table style={{ padding: 0, margin: 0, width: '100%'}}>
                    <TableHead style={{ padding: 0, margin: 0, width: '100%'}}>
                      <TableRow style={{ padding: 0, margin: 0, width: '100%'}}>
                        <TableCell style={{ padding: 0, margin: 0, width: '100%', fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                          Question
                        </TableCell>
                        <TableCell style={{ display: 'flex', minWidth: '80px', padding: '4px', margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500, width: '72px'}}>
                          Follow-ups
                        </TableCell>
                        <TableCell style={{ fontSize: 16, color: '#656565', border: 'none'}}>
                          {/* Actions */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <SortableContext
                        items={addQuestions.questions.map((question, idx) => {return question.id })}
                        strategy={verticalListSortingStrategy}
                      >
                      {addQuestions.questions.map((question, idx) => (
                        <DraggableRow
                          idx={idx}
                          row={question}
                          selected={currentPreviewQuestion.topic == question.question}
                          setSelectedPreview={setSelectedPreview}
                          setAddQuestions={setAddQuestions}
                          setShowAddQuestionInput={setShowAddQuestionInput}
                          stateAddQuestions={addQuestions}
                        />
                      ))}
                      </SortableContext>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Scrollbars>
          </Paper>

          {/* Add Question */}
          <Dialog open={showAddQuestionInput}>
              <DialogTitle>Add Interview Question</DialogTitle>
              <DialogContent>
                <p style={{ fontSize: 16, color: 'gray', margin: 0 }}>Add questions that will be asked during the interview.</p>
                <Grid container>
                  <Grid item xs={12} style={{ height: '215px', marginTop: '25px'}}>
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        backgroundColor: '#FEFEFE',
                        style: { padding: '10px', border: '1px solid lightgray', borderRadius: '6px' }
                      }}
                      InputLabelProps={{
                        style: {
                          left: '10px', // Adjusts the horizontal position
                          top: '-5px', // Adjusts the vertical position
                          color: '#656565', // Sets the color of the label
                          position: 'absolute' // Ensures the label can be freely moved
                        }
                      }}
                      fullWidth={true}
                      multiline
                      minRows={8}
                      label="Enter Your Question"
                      placeholder="What has been the highlight of your past role?"
                      value={addQuestions.currentQuestion.text}
                      onChange={(e) => {setAddQuestions({ ...addQuestions, currentQuestion: { ...addQuestions.currentQuestion, text: e.target.value }})}}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '25px'}}>
                    <TextField
                      label={
                        <>
                          <div className="d-flex">
                            Number of Follow Up Questions
                            <TooltipCustom
                              title={<>
                                  <p className="m-auto">Wayfaster assessments are fully dynamic, meaning follow up questions are generated based on what the candidate says.
                                  <span style={{ color: '#20C58A'}}> Watch a small demo here to see how it works</span>.
                                  </p>
                                </>}
                              style={{ marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto' }}>
                            </TooltipCustom>
                          </div>
                        </>
                      }
                      InputProps={{
                        disableUnderline: true,
                        backgroundColor: '#FEFEFE',
                        style: { padding: '10px', border: '1px solid lightgray', borderRadius: '6px' }
                      }}
                      InputLabelProps={{
                        style: {
                          left: '10px', // Adjusts the horizontal position
                          top: '-5px', // Adjusts the vertical position
                          color: '#656565', // Sets the color of the label
                          position: 'absolute' // Ensures the label can be freely moved
                        }
                      }}
                      type="number"
                      variant="standard"
                      fullWidth={true}
                      value={addQuestions.currentQuestion.numFollowUps}
                      onChange={(e) => {
                        setAddQuestions({ ...addQuestions, currentQuestion: { ...addQuestions.currentQuestion, numFollowUps: e.target.value }});
                      }}
                    />
                    {/* <p style={{ fontSize: 14, marginLeft: 10, marginTop: 20, fontWeight: '500px' }}>What are follow up questions? Wayfaster assessments are fully dynamic, meaning questions are generated based on what the candidate says. <span style={{ color: '#20C58A'}}>Watch a small demo here to see how it works.</span></p> */}
                  </Grid>
                </Grid>
              </DialogContent>
              {/* Error Messages */}
              <div className="d-flex" style={{ width: '100%', marginTop: '-16px'}}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
              <DialogActions>
                  <div style={{display : 'flex', width: '100%', padding: '16px'}}>
                    <Button className="btn-main gray outlined"
                      style={{ flex: 1, marginRight: '12px' }}
                      onClick={() => cancelShowAddModal('question')}>
                        Cancel
                    </Button>
                    <Button className="btn-main"
                      style={{ flex: 1 }}
                      onClick={() => saveAddQuestion()}>
                        Save
                    </Button>
                  </div>
              </DialogActions>
          </Dialog>
        </div>
        <div className="d-flex flex-col" style={{ flex: 1, border: '1px solid lightgray', borderTop: 'none', padding: '24px', overflow: 'auto' }}>
          <h3 className="section-heading" style={{ marginTop: 0 }}>
            Simulated interview preview
          </h3>
          
          <p className="color-gray" style={{ marginTop: 0, marginBottom: '32px', fontSize: '14px' }}>The simulated interview below outlines what a potential interaction might look like, based on the topic and depth you've selected/created.</p>
                
          <SimulatedPreview
            loading={loadingPreview}
            preview={interviewPreview}
          ></SimulatedPreview>
        </div>
      </DndContext>
    )
  }

  const saveAddStructuredOutput = () => {
    let msgs = []
    if (!addOutputs.currentOutput.extraction_instructions || !addOutputs.currentOutput.data_type || !addOutputs.currentOutput.key) {
      msgs.push('All fields must be filled in');
      setErrMsgs(msgs);
      return;
    }

    if(addOutputs.editIndex > -1) {
      let tmpOutputs = JSON.parse(JSON.stringify(addOutputs.outputs))
      tmpOutputs[addOutputs.editIndex] = {
        extraction_instructions: addOutputs.currentOutput.extraction_instructions,
        data_type: addOutputs.currentOutput.data_type,
        key: addOutputs.currentOutput.key
      }
      setAddOutputs({
        ...addOutputs,
        outputs: tmpOutputs,
        currentOutput: { extraction_instructions: '', data_type: '', key: '' },
        editIndex: -1
      })
    } else {
      setAddOutputs({ ...addOutputs, outputs: [...addOutputs.outputs, {
        extraction_instructions: addOutputs.currentOutput.extraction_instructions,
        data_type: addOutputs.currentOutput.data_type,
        key: addOutputs.currentOutput.key
      }], currentOutput: { extraction_instructions: '', data_type: '', key: '' }})
    }
    setShowAddOutputInput(false)
  }

  const renderAddOutputs = () => {
    return (
      <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
          {/* Structured Outputs Display */}
          <Paper style={{ minHeight: '500px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9' }}>
            <div className="d-flex flex-row">
              <h3 className="my-auto">Structured Outputs</h3>
            
              <button className="btn-main d-flex" style={{ marginLeft: 'auto', width: 'fit-content' }}
                onClick={() => {triggerShowAddModal('output')}}>
                <Add className="my-auto" fontSize="24px"/>
                Add Output
              </button>
            </div>
            <p className="color-gray" style={{ fontSize: 14 }}>Extract some set of structured information out of the interview.</p>
            <Grid container>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: 0, margin: 0, width: '65%', fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                        Key
                      </TableCell>
                      <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                        Description
                      </TableCell>
                      <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                        Type
                      </TableCell>
                      <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addOutputs.outputs.map((output, idx) => {
                      return (
                        <TableRow  key={idx} sx={{ border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}>
                          <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.key}</TableCell>
                          <TableCell style={{ padding: 0, margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.extraction_instructions}</TableCell>
                          <TableCell style={{  fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{output.data_type}</TableCell>
                          <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                              <IconButton
                                onClick={() => {
                                  setAddOutputs({
                                    ...addOutputs,
                                    currentOutput: {
                                      key: output.key,
                                      data_type: output.data_type,
                                      extraction_instructions: output.extraction_instructions
                                    },
                                    editIndex: idx,
                                  })
                                  setShowAddOutputInput(true)
                                }}
                                style={{ color: '#656565', marginRight: '4px' }}>
                                  <img src={EditIconSvg} />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  let tmpOutputs = JSON.parse(JSON.stringify(addOutputs.outputs))
                                  console.log(tmpOutputs)
                                  tmpOutputs.splice(idx, 1)
                                  console.log(tmpOutputs)
                                  setAddOutputs({ ...addOutputs, outputs: tmpOutputs })
                                }}
                                style={{ color: '#656565'}}>
                                  <img src={DeleteIconSvg} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>

          {/* Add Outputs */}
          <Dialog open={showAddOutputInput}>
              <DialogTitle>Add Structured Output</DialogTitle>
              <DialogContent>
                <p style={{ fontSize: 16, color: 'gray', margin: 0 }}>
                  Extract some set of structured information out of the interview
                </p>
                <Grid container>
                  <Grid item xs={2} className="d-flex left-label-wrapper" style={{ marginTop: '25px'}}>
                    <p style={{  fontSize: 14, fontWeight: 600 }}>Key</p>
                  </Grid>
                  <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                    <TextField
                      hiddenLabel
                      variant="outlined"
                      sx={{
                        margin: 'auto',
                        '& .MuiOutlinedInput-input': {
                          padding: '14px',
                          margin: 'auto'
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                        backgroundColor: '#FEFEFE',
                        style: { border: '1px solid lightgray', borderRadius: '6px' }
                      }}
                      fullWidth={true}
                      placeholder="past_employers"
                      value={addOutputs.currentOutput.key}
                      onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, key: e.target.value }})}}
                    />
                  </Grid>
                  <Grid item xs={2} className="left-label-wrapper" style={{ marginTop: '25px'}}>
                    <p style={{  fontSize: 14, fontWeight: 600 }}>Extraction Instructions</p>
                  </Grid>
                  <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                    <TextField
                      hiddenLabel
                      variant="outlined"
                      sx={{
                        margin: 'auto',
                        '& .MuiOutlinedInput-input': {
                          padding: '14px',
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                        backgroundColor: '#FEFEFE',
                        style: { padding: '14px !important', border: '1px solid lightgray', borderRadius: '6px' }
                      }}
                      fullWidth={true}
                      placeholder="Get a list of companies the person has worked at"
                      value={addOutputs.currentOutput.extraction_instructions}
                      onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, extraction_instructions: e.target.value }})}}
                    />
                  </Grid>
                  <Grid item xs={2} className="left-label-wrapper" style={{ marginTop: '25px'}}>
                    <p style={{  fontSize: 14, fontWeight: 600 }}>Type</p>
                  </Grid>
                  <Grid item xs={10} className="d-flex" style={{ marginTop: '25px'}}>
                    <Select
                      variant="outlined"
                      sx={{
                        margin: 'auto',
                        '& .MuiOutlinedInput-input': {
                          padding: '13px',
                          border: '2px solid lightgray',
                          borderRadius: '6px'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid lightgray',
                          borderRadius: '6px'
                        },
                      }}
                      fullWidth={true}
                      value={addOutputs.currentOutput.data_type}
                      onChange={(e) => {setAddOutputs({ ...addOutputs, currentOutput: { ...addOutputs.currentOutput, data_type: e.target.value }})}}
                    >
                      <MenuItem value="List">List</MenuItem>
                      <MenuItem value="Text">Text</MenuItem>
                      <MenuItem value="Number">Number</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </DialogContent>
              {/* Error Messages */}
              <div className="d-flex" style={{ width: '100%' }}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
              <DialogActions>
                  <div style={{display : 'flex', width: '100%', padding: '16px'}}>
                    <Button className="btn-main gray outlined"
                      style={{ flex: 1, marginRight: '12px' }}
                      onClick={() => cancelShowAddModal('output')}>
                        Cancel
                    </Button>
                    <Button className="btn-main"
                      style={{ flex: 1 }}
                      onClick={() => saveAddStructuredOutput()}>
                        Save
                    </Button>
                  </div>
              </DialogActions>
          </Dialog>
      </div>
    )
  }

  const saveAddScoring = () => {
    let msgs = []

    // VALIDATION LOGIC
    if (addScoring.currentSignal.signal == "") {
      msgs.push('Skill name must be filled in.')
    }
    if (addScoring.currentSignal.weight <= 0) {
      msgs.push('Weight must be greater than 0%')
    }
    if (addScoring.currentSignal.weight >= 100) {
      msgs.push('Weight must not be greater than 100%')
    }

    if(msgs.length > 0 ) {
      setErrMsgs(msgs)
    } else {
      setErrMsgs([])
      
      // get criteria
      let criteria = {}
      for (let i = 0; i < Number(addScoring.currentSignal.scale); i++) {
        criteria[`${i + 1}PointCriteria`] = addScoring.currentSignal[`${i + 1}PointCriteria`];
      }
      

      if(addScoring.currentSignal.editIndex >= 0) {
        let updatedSignals = [...addScoring.signals];

        updatedSignals[addScoring.currentSignal.editIndex] = {
          signal: addScoring.currentSignal.signal,
          scale: addScoring.currentSignal.scale,
          ...criteria,
          weight: addScoring.currentSignal.weight,
        }
        setAddScoring({ signals: updatedSignals, currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': '' }})
      } else {
        setAddScoring({ signals: [...addScoring.signals, {
          signal: addScoring.currentSignal.signal,
          scale: addScoring.currentSignal.scale,
          ...criteria,
          weight: addScoring.currentSignal.weight
        }], currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': ''}})
      }
      cancelShowAddModal('signal')
      cancelShowAddModal('signal-assist')
    }
  }

  const [scoringGenerating, setScoringGenerating] = useState(false);
  const [scoringAssistStep, setScoringAssistStep] = useState(0);

  const [scoringAddTab, setScoringAddTab] = useState(0);
  const [scoringActiveCriteria, setScoringActiveCriteria] = useState('1PointCriteria');

  const [scoringScale, setScoringScale] = useState(4);
  
  const handleScoringAddTabChange = (event, newValue) => {
    // out of bounds, greater than previous scale
    let criteriaLabel = `${newValue + 1}PointCriteria`;
    if (!(criteriaLabel in addScoring.currentSignal)) {
      setAddScoring({
        ...addScoring,
        currentSignal: {
          ...addScoring.currentSignal,
          [criteriaLabel]: "", // init new criteria
        },
      });
    }
    setScoringAddTab(newValue);
  }

  // returns a normalized criteria set based on all generated criteria
  const normalizeCriteria = (generatedCriteria, scale) => {
    switch (scale) {
      case 2:
        // Poor, Excellent  
        return {
          '1PointCriteria': generatedCriteria['1PointCriteria'],
          '2PointCriteria': generatedCriteria['6PointCriteria']
        }
      
      case 3:
        // Poor, Average, Excellent
        return {
          '1PointCriteria': generatedCriteria['1PointCriteria'],
          '2PointCriteria': generatedCriteria['3PointCriteria'],
          '3PointCriteria': generatedCriteria['6PointCriteria']
        }
          
      case 4:
        // Poor, Fair, Good, Excellent
        return {
          '1PointCriteria': generatedCriteria['1PointCriteria'],
          '2PointCriteria': generatedCriteria['2PointCriteria'],
          '3PointCriteria': generatedCriteria['4PointCriteria'],
          '4PointCriteria': generatedCriteria['6PointCriteria']
        }
        
      case 5:
        // Poor, Fair, Average, Good, Excellent
        return {
          '1PointCriteria': generatedCriteria['1PointCriteria'],
          '2PointCriteria': generatedCriteria['2PointCriteria'],
          '3PointCriteria': generatedCriteria['3PointCriteria'],
          '4PointCriteria': generatedCriteria['4PointCriteria'],
          '5PointCriteria': generatedCriteria['6PointCriteria']
        }
        
      case 6:
        // Poor, Fair, Average, Good, Very Good, Excellent
        return generatedCriteria;
        
      default:
        // Poor, Fair, Average, Good, Very Good, Excellent
        return generatedCriteria;
    }
  }

  const handleGenerateScoringCriteria = ({ context, scale, signal }) => {
    setScoringGenerating(true);
    api.generateScoringCriteria({
      context,
      signal,
      scale,
      title: roleBackground.interviewTitle,
      job_title: roleBackground.jobTitle
    })
      .then((response) => {
        let data = response.data;
        let criteria = {}

        for (let i = 0; i < Number(data.scale); i++) {
          let point = data.criteria[`${i + 1}PointCriteria`]
          criteria[`${i + 1}PointCriteria`] = ""
          for (const [key, value] of Object.entries(point)) {
            criteria[`${i + 1}PointCriteria`] += `• ${key}: ${value}\n`;
          }
        }
        setGeneratedCriteria({
          ...criteria
        });

        setAddScoring({
          ...addScoring,
          currentSignal: {
            signal: data.signal,
            weight: 0,
            scale: data.scale,
            ...normalizeCriteria(criteria, scale),
            description: context
          },
        })
        setScoringActiveCriteria('1PointCriteria');
        setScoringAssistStep((prev) => prev + 1);
        setScoringGenerating(false);
      })
      .catch((error) => {
        console.log(error)
        setScoringGenerating(false);
      })
  }

  const getScoringPlaceholder = () => {
    let placeholder = "Criteria for "
    switch (scoringActiveCriteria) {
      case '6PointCriteria':
        return placeholder + `"${getScoringDescriptor(6)}"` + ' score (6)'
      case '5PointCriteria':
        return placeholder + `"${getScoringDescriptor(5)}"` + ' score (5)'
      case '4PointCriteria':
        return placeholder + `"${getScoringDescriptor(4)}"` + ' score (4)'
      case '3PointCriteria':
        return placeholder + `"${getScoringDescriptor(3)}"` + ' score (3)'
      case '2PointCriteria':
        return placeholder + `"${getScoringDescriptor(2)}"` + ' score (2)'
      case '1PointCriteria':
        return placeholder + `"${getScoringDescriptor(1)}"` + ' score (1)'
      default:
        return null
    }
  }

  const getScoringDescriptor = (i) => {
    let scale = Number(addScoring.currentSignal.scale);

    // min / max value base cases
    if (i == 1) return "Poor";
    if (i == scale || i == SCORE_SCALES[SCORE_SCALES.length - 1]) return "Excellent"

    switch (scale) {
      case 3:
        if (i == 2) return "Fair";
      case 4:
        if (i == 2) return "Fair";
        if (i == 3) return "Good";
      case 5:
        if (i == 2) return "Fair";
        if (i == 3) return "Average";
        if (i == 4) return "Good";
      case 6:
        if (i == 2) return "Fair";
        if (i == 3) return "Average";
        if (i == 4) return "Good";
        if (i == 5) return "Very Good";
    }
  }

  const SCORE_SCALES = [2, 3, 4, 5, 6]

  const renderAddScoring = () => {
    return (
      <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px' }}>
        {/* Scoring Display */}
        <Paper style={{ minHeight: '500px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '6px', border: '1px solid lightgray' }}>
          <div className="d-flex flex-row">
            <h3 className="my-auto">Scoring</h3>
          
            <div className="d-flex flex-row" style={{ marginLeft: 'auto', width: 'fit-content' }}>
              <button className="btn-main assistant d-flex" style={{ marginRight: '16px' }}
                onClick={() => {triggerShowAddModal('signal-assist')}}>
                <AutoAwesomeOutlinedIcon className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                Scoring Assistant
              </button>
              <button className="btn-main d-flex"
                onClick={() => {
                  setAddScoring({ ...addScoring,
                    currentSignal: { signal: '', weight: 0, scale: 4, '4PointCriteria': '', '3PointCriteria': '', '2PointCriteria': '', '1PointCriteria': ''}})
                  setScoringAddTab(0);
                  setScoringActiveCriteria('1PointCriteria')
                  triggerShowAddModal('signal');
                  }}>
                <Add className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                Add Signal
              </button>
            </div>
          </div>
          <p className="color-gray" style={{ fontSize: 14 }}>Determine what the candidate should be graded on in the interview.</p>
          <Grid container>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', padding: '0px', margin: '0px', fontWeight: 500}}>
                      Signal
                    </TableCell>
                    <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', padding: '0px', margin: '0px', fontWeight: 500}}>
                      Weight (%)
                    </TableCell>
                    <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addScoring.signals.map((signal, idx) => {
                    return (
                      <TableRow  key={idx} sx={{ border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}>
                        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{signal.signal}</TableCell>
                        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>{signal.weight}</TableCell>
                        <TableCell sx={{ border: 'none', padding: '0px', margin: '0px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                          <IconButton
                              onClick={() => {
                                let criteria = {}
                                for (let i = 0; i < Number(signal.scale); i++) {
                                  criteria[`${i + 1}PointCriteria`] = signal[`${i + 1}PointCriteria`];
                                }
                                
                                setAddScoring({
                                  ...addScoring,
                                  currentSignal: {
                                    signal: signal.signal,
                                    ...criteria,
                                    scale: signal.scale,
                                    weight: signal.weight,
                                    editIndex: idx,
                                  },
                                })
                                setShowAddSignalInput(true)
                              }}
                              style={{ color: '#656565', marginRight: '4px' }}><img src={EditIconSvg} />
                            </IconButton>
                          <IconButton
                            onClick={() => {
                              let newSignals = addScoring.signals.filter((signal, signalIdx) => signalIdx !== idx);
                              setAddScoring({ ...addScoring, signals: newSignals });
                            }}
                            style={{color: '#656565' }}><img src={DeleteIconSvg} /></IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>

        {/* Add Scoring */}
        <Dialog open={showAddSignalInput} maxWidth={'md'}>
            <DialogContent>
              <h3 className="section-heading" style={{ fontSize: '20px', marginTop: 0 }}>
                Add scoring expectations
              </h3>
              <p style={{ fontSize: '16px', color: 'gray', margin: 0}}>
                Determine what the candidate should be graded on in the interview.
              </p>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={addScoring.currentSignal.signal}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                    />
                </Grid>

                  
                <Grid item xs={2}>
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={addScoring.currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
                <Grid item xs={10}>
                <h5 className="input-label d-flex">
                  Scores
                </h5>
                  <Tabs value={scoringAddTab} onChange={handleScoringAddTabChange}
                    style={{ background: 'white', marginBottom: '16px', marginTop: '8px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'PPNeueMontreal',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                      {[...Array((Number(addScoring.currentSignal.scale)))].map((e, i) => {
                        return <Tab label={`${i + 1} (${getScoringDescriptor(i + 1)})`} onClick={() => setScoringActiveCriteria(`${i + 1}PointCriteria`)} disableRipple />
                      })}
                  </Tabs>
                </Grid>

                <FormField
                    label={getScoringPlaceholder()}
                    tooltip
                    tooltipTitle={<>
                      <p className="m-auto">
                        Write a criteria description that the candidate has to meet to be evaluated for this score. 
                      </p>
                    </>}
                    style={{ marginBottom: '16px' }}
                    multiline
                    minRows={8}
                    placeholder={getScoringPlaceholder()}
                    value={addScoring.currentSignal[scoringActiveCriteria]}
                    onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, [scoringActiveCriteria]: e.target.value }})}}
                    />

                <FormField
                    label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                    value={addScoring.currentSignal.weight}
                    type="Number"
                    onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, weight: Number(e.target.value) }})}}
                  />

              </Grid>
            </DialogContent>
            {/* Error Messages */}
            <div className="d-flex" style={{ width: '100%' }}>
              {errMsgs.length > 0 &&
                errMsgs.map((errMsg) => {
                  return (
                    <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                  )
                })
              }
            </div>
            <DialogActions>
                <div className="d-flex flex-row" style={{ width: '100%', padding: '16px'}}>
                  <Button className="btn-main gray outlined"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => cancelShowAddModal('signal')}>
                      Cancel
                  </Button>
                  <Button className="btn-main"
                    style={{ marginLeft: '12px' }}
                    onClick={() => saveAddScoring()}>
                      Save
                  </Button>
                </div>
            </DialogActions>
        </Dialog>

        
        {/* Add Signal Assist */}
        <Dialog open={showAddSignalAssistInput} maxWidth={'md'}>
            <DialogContent>
              <h3 className="section-heading" style={{ fontSize: '20px', marginTop: 0 }}>
                {scoringAssistStep === 0 ? 
                'Generate score outputs with scoring assistant' 
                : 'Generated expectations from candidate'
                }
              </h3>
              <p style={{ fontSize: '16px', color: 'gray', margin: 0}}>
                Determine what the candidate should be graded on in the interview.
              </p>
              { scoringAssistStep === 0 &&
               <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={addScoring.currentSignal.signal}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                    />
                  <FormField
                      label="Describe what is most important to you in this signal."
                      multiline
                      minRows={8}
                      placeholder="e.g. It is very important to me that the candidate is fluent in English, has good sentence construction and understands what I say to him/her"
                      value={addScoring.currentSignal.description}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, description: e.target.value }})}}
                      />
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={addScoring.currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
              </Grid>}
              
              { scoringAssistStep === 1 &&
               <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={addScoring.currentSignal.signal}
                      onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, signal: e.target.value }})}}
                    />
                </Grid>
               
                <Grid item xs={2}>
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={addScoring.currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, scale: scale_num }});
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
                <Grid item xs={10}>
                <h5 className="input-label d-flex">
                  Scores
                </h5>
                  <Tabs value={scoringAddTab} onChange={handleScoringAddTabChange}
                    style={{ background: 'white', marginBottom: '16px', marginTop: '8px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'PPNeueMontreal',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                      {[...Array((Number(addScoring.currentSignal.scale)))].map((e, i) => {
                        return <Tab label={`${i + 1} (${getScoringDescriptor(i + 1)})`} onClick={() => setScoringActiveCriteria(`${i + 1}PointCriteria`)} disableRipple />
                      })}
                  </Tabs>
                </Grid>

                <FormField
                    label={getScoringPlaceholder()}
                    tooltip
                    tooltipTitle={<>
                      <p className="m-auto">
                        Write a criteria description that the candidate has to meet to be evaluated for this score. 
                      </p>
                    </>}
                    style={{ marginBottom: '16px' }}
                    multiline
                    minRows={8}
                    placeholder={getScoringPlaceholder()}
                    value={addScoring.currentSignal[scoringActiveCriteria]}
                    onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, [scoringActiveCriteria]: e.target.value }})}}
                    />

                <FormField
                    label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                    value={addScoring.currentSignal.weight}
                    type="Number"
                    onChange={(e) => {setAddScoring({ ...addScoring, currentSignal: { ...addScoring.currentSignal, weight: Number(e.target.value) }})}}
                  />
              </Grid>}
                
              {/* Error Messages */}
              <div className="d-flex" style={{ width: '100%' }}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row" style={{ width: '100%', padding: '16px' }}>
                <Button className="btn-main gray outlined"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {cancelShowAddModal('signal-assist')
                    setErrMsgs([])}}>
                    Cancel
                </Button>
                {scoringAssistStep > 0 && <Button className="btn-main gray outlined"
                  style={{ marginLeft: '12px' }}
                  onClick={() => {setScoringAssistStep((prev) => prev - 1 )
                    setErrMsgs([])}}>
                    Back
                </Button>}
                {scoringAssistStep === 0 && <Button className="btn-main assistant"
                  disabled={
                    scoringGenerating || (addScoring.currentSignal.signal == "" || addScoring.currentSignal.description == "")
                  }
                  style={{ marginLeft: '12px' }}
                  onClick={() => handleGenerateScoringCriteria({
                    signal: addScoring.currentSignal.signal,
                    scale: addScoring.currentSignal.scale,
                    context: addScoring.currentSignal.description
                  })}>
                    { scoringGenerating ? 
                    <>
                      Generating...
                      &nbsp;&nbsp;
                      <CircularProgress
                        className="m-auto"
                        size={14}
                        style={{ color: "white", marginLeft: "8px" }}
                        />
                    </>
                    : <>
                    <AutoAwesomeOutlinedIcon className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                    Generate
                    </> }
                </Button>}
                
                {scoringAssistStep === 1 && <Button className="btn-main"
                  style={{ marginLeft: '12px' }}
                  onClick={() => {saveAddScoring()}}>
                    Save
                </Button>}
              </div>
            </DialogActions>
        </Dialog>
      </div>
    )
  }

  const renderReviewTest = () => {
    const question_sections = groupBy(addQuestions.questions, 'section')
    // show sections only if at least 2 different sections specified
    const showSections = Object.keys(question_sections).length > 1;

    return (
      <div className="mx-auto" style={{ flex: 1, paddingTop: '40px', paddingBottom: '80px', marginBottom: '40px' }}>
        <Paper style={{ minHeight: '400px', padding: '25px', margin: 'auto', width: '70%', boxShadow: 'none', borderRadius: '8px', border: '1px solid #D9D9D9' }}>
        <h3 className="section-heading" style={{ marginTop: 0 }}>Review Interview</h3>
        <p className="color-gray" style={{ fontSize: 14, marginTop: 0, marginBottom: '24px' }}>Review details about the interview. You can make changes to this at any time after saving.</p>

        <div className="d-flex flex-col">
          <FormEditable
            style={{ marginBottom: '24px' }}
            label="Interview Title"
            placeholder={roleBackground.interviewTitle}
            value={roleBackground.interviewTitle}
            onChange={(e) => {setRoleBackground({ ...roleBackground, interviewTitle: e.target.value })}}>
          </FormEditable>

          <FormEditable
            style={{ marginBottom: '24px' }}
            label="Job Title"
            placeholder={roleBackground.jobTitle}
            value={roleBackground.jobTitle}
            onChange={(e) => {setRoleBackground({ ...roleBackground, jobTitle: e.target.value })}}>
          </FormEditable>

          {/* Questions */}
          <h5 className="d-flex input-label">
            Questions 
              <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                onClick={() => setActiveStep(1)}></DriveFileRenameOutlineIcon>
          </h5>
          <div className="card" style={{ marginBottom: '24px' }}>
            <Grid container style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow sx={{
                            '& .MuiTableCell-root': {
                              lineHeight: '20px',
                              backgroundColor: '#FAFAFA',
                              color: 'var(--color-heading)',
                              padding: '12px 16px',
                              borderRadius: '8px'
                            }
                          }}>
                      <TableCell style={{ width: '70%' }}>
                        Question
                      </TableCell>
                      <TableCell style={{ minWidth: '72px' }}>
                        Follow Ups
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(question_sections).sort(sectionSort).map((keyName, keyIndex) => (
                      <SortableContext
                        items={question_sections[keyName].map((question, idx) => {return question.id })}
                        strategy={verticalListSortingStrategy}
                      >
                        {showSections && keyName != 'undefined' && <TableSection label={keyName}></TableSection>}
                        {question_sections[keyName].map((question, idx) => (
                          <>
                            <TableRow
                              sx={{
                                position: 'relative',
                                border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
                            >
                              <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', fontWeight: 600, fontSize: '14px' }}>
                                {question.question}
                              </TableCell>
                              <TableCell sx={{ padding: '0px', margin: '0px', paddingLeft: '17px' }}>{question.follow_ups}</TableCell>
                            </TableRow>
                          </>
                        ))}
                      </SortableContext>
                    ))
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </div>

          
          {/* Scorecard */}
          <h5 className="d-flex input-label">
            Scorecard 
              <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                onClick={() => setActiveStep(2)}></DriveFileRenameOutlineIcon>
          </h5>
          <div className="card" style={{ marginBottom: '24px' }}>
            <Grid container style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow sx={{
                            '& .MuiTableCell-root': {
                              lineHeight: '20px',
                              backgroundColor: '#FAFAFA',
                              color: 'var(--color-heading)',
                              padding: '12px 16px',
                              borderRadius: '8px'
                            }
                          }}>
                      <TableCell style={{ width: '70%' }}>
                        Name
                      </TableCell>
                      <TableCell style={{ minWidth: '72px' }}>
                        Weight
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addScoring.signals.map((signal, idx) => (
                      <>
                        <TableRow
                          sx={{
                            position: 'relative',
                            border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
                        >
                          <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', fontWeight: 600, fontSize: '14px' }}>
                            {signal.signal}
                          </TableCell>
                          <TableCell sx={{ padding: '0px', margin: '0px', paddingLeft: '17px' }}>{signal.weight}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </div>


          {/* Scorecard */}
          <h5 className="d-flex input-label">
            Structured Outputs 
              <DriveFileRenameOutlineIcon className="clickable color-gray" style={{ fontSize: '18px', lineHeight: '20px', marginLeft: '8px' }} 
                onClick={() => setActiveStep(3)}></DriveFileRenameOutlineIcon>
          </h5>
          <div style={{ marginBottom: '24px' }}>
            {addOutputs.outputs.length > 0 ? addOutputs.outputs.map((output) => (
                  <div className="d-flex flex-col section-heading" style={{ padding: '12px', borderRadius: '8px', border: '1px solid lightgray' }}>
                      <>
                        <div className="section-heading">
                          <ChipTag variant="default" text={output.key} style={{ width: 'max-content' }} />
                        </div>
                        <div className="d-flex flex-col" style={{ marginLeft: '8px' }}>
                          <h5 className="d-flex color-gray" style={{ fontSize: '12px', marginTop: 0, marginBottom: '4px' }}>
                            Extraction Instructions
                          </h5>
                          <p class="section-body" style={{ fontSize: '14px' }}>
                            {output.extraction_instructions}
                          </p>

                          <h5 className="d-flex color-gray" style={{ fontSize: '12px', marginTop: 0, marginBottom: '4px'  }}>
                            Type
                          </h5>
                          <p class="section-body" style={{ fontSize: '14px' }}>
                            {output.data_type}
                          </p>
                        </div>
                      </>
                  </div>
                ))
              :
              <>
                <div className="d-flex flex-col section">
                  <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                    No outputs.
                  </p>
                </div>
              </>
            }
          </div>
        </div>

        <FormField
          label="Custom Interview Prompt (optional)"
          multiline
          minRows={6}
          placeholder=""
          value={reviewTest.prompt}
          onChange={(e) => {setReviewTest({ ...reviewTest, prompt: e.target.value})}}
          />

        </Paper>
      </div>
    )
  }

  const renderActiveStepComponent = () => {
    switch (activeStep) {
      case -1:
        switch (creationFlow) {
          case '':
            return renderFlowPrompt();
          case 'jd':
            return renderJDUpload();
          case 'template':
            return renderTemplateFlow();
          case 'self':
          default:
            return setActiveStep(0);
        }
      case 0:
        return renderRoleBackground();
      case 1:
        return renderAddQuestions();
      case 2:
        return renderAddScoring();
      case 3:
        return renderAddOutputs();
      case 4:
        return renderReviewTest();
      default:
        return null;
    }
  }

  return (
    <div className="w-100 d-flex flex-col" style={{ padding: 0, backgroundColor: 'white', height: '100%' }}>
      {activeStep > -1 ? <div className="d-flex flex-row" style={{ padding: '16px', borderBottom: '1px solid lightgray' }}>

        <button className="btn-main d-flex my-auto" style={{ marginRight: 'auto' }}
          onClick={() => handleBack()}>
          <NavigateBefore className="my-auto" fontSize="24px"/>
          Back
        </button>

        <Stepper activeStep={activeStep}
          className="d-flex flex-row"
          style={{ width: 'auto', margin: 'auto !important' }}
          sx={{
            ['& .MuiStep-root'] : {
              display: 'flex',
              flex: 0,
              paddingLeft: 0
            }
          }}
          alternativeLabel connector={
            <NavigateNext className="m-auto" fontSize="14px" style={{ color: 'var(--color-gray)', paddingRight: '8px' }} />
          }>
          {steps.map((label, index) => {
            const stepProps= {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel StepIconComponent={
                  () =>
                    <div className="d-flex flex-row"
                      style={{ borderRadius: '32px', border: '1px solid lightgray', padding: '6px',
                        backgroundColor: activeStep >= index ? 'black': 'white'
                        }}>
                      <Avatar className="m-auto"
                        sx={{ width: '18px', height: '18px' }}
                        style={{ backgroundColor: activeStep >= index ? 'white': 'var(--color-lightgray)',
                        margin: 0 }}
                      >
                        <p className="m-auto" style={{ fontSize: '12px', color: 'var(--color-main)'}}>{index+1}</p>
                      </Avatar>
                      <h5 style={{ fontSize: '14px', margin: 'auto 8px',
                        color: activeStep >= index ? 'white': 'var(--color-gray)' }}>{label}</h5>
                    </div>} {...labelProps}>
                  </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <button className="btn-main d-flex my-auto" style={{ marginLeft: 'auto' }}
          onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Create Interview' : 'Next'}
            <NavigateNext className="my-auto" fontSize="24px" />
        </button>

      </div>
      :
      <div className="d-flex flex-row" style={{ marginRight: 'auto', padding: '16px' }}>
        <button className="btn-main small d-flex gray outlined"
          onClick={() => {navigate("/interviews")}}>
          <ArrowBack fontSize="14px" className="color-heading m-auto" />
        </button>
        <h5 style={{ margin: 'auto 8px', lineHeight: '20px' }}>My Interviews</h5>
      </div>
      }
      {/* Error Messages Header */}
      {errMsgs.length > 0 &&
        !(showAddQuestionInput || showAddOutputInput || showAddSignalInput) &&
        errMsgs.map((errMsg) => {
          return (
            <Alert severity="error" style={{ width: '100%', margin: 'auto'}}>{errMsg}</Alert>
          )
        })
      }
      <div className="d-flex flex-row" style={{ flex: 1, maxHeight: 'calc(100vh - 1px)', overflow: 'auto' }}>
        {renderActiveStepComponent()}
      </div>
    </div>
  )
}



const createUniqueInterviewSession = (interviewConfigId, candidateFirstName, candidateLastName, candidateEmail, candidatePhone, callType, userId, teamId, sessionSpecificQuestions) => {
  console.log(interviewConfigId, candidateEmail, candidateFirstName, candidateLastName, userId, sessionSpecificQuestions)
  return axios.post(proxyEndpoint + '/sessions', { interviewConfigId, candidateFirstName, candidateLastName, candidateEmail, candidatePhone, callType, userId, teamId, sessionSpecificQuestions })
  .then(response => {
    console.log(response.data);
    return response.data;
  })
  .catch(err => {
    console.error(err);
  });
}

const getCompletedSessions = (id) => {
  return axios.post(proxyEndpoint + '/get-completed-sessions', { interviewId: id })
  .then(response => {
    console.log(response.data)
    return response.data;
  })
  .catch(err => {
    console.error(err);
  });
}

const updatePrompt = (interviewConfigId, prompt) => {
  return axios.post(proxyEndpoint + '/interview-configs/prompts', { interviewConfigId, prompt })
  .then(response => {
      console.log(response.data)
      return response.data
  })
  .catch(err => {
      console.error(err)
  })
}

const updateEvalPrompt = (interviewConfigId, prompt) => {
  return axios.post(proxyEndpoint + '/interview-configs/eval-prompts', { interviewConfigId, prompt })
  .then(response => {
      console.log(response.data)
      return response.data
  })
  .catch(err => {
      console.error(err)
  })
}

const getSession = (sessionId) => {
  return axios.post(proxyEndpoint + '/session', { id: sessionId }) // TODO: add session token auth
  .then(response => {
    console.log(response.data)
    return response.data;
  })
  .catch(err => {
    console.error(err);
  });
}

export const SessionDetail = ({ isPubliclyViewable }) => {
  const BASE_SCORE = 5;
  const DEFAULT_SCALE = 4; // based on 4 point scale before dynamic point scales implemented

  const location = useLocation();
  const { interview_id, session_id } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [interview, setInterview] = useState({});
  const [session, setSession] = useState({});
  const [sessionList, setSessionList] = useState([]);
  const [previousSessionId, setPreviousSessionId] = useState('');
  const [nextSessionId, setNextSessionId] = useState('');
  const [videoRecordingLink, setVideoRecordingLink] = useState('')
  const [audioRecordingLink, setAudioRecordingLink] = useState('');

  const [currentSideTab, setCurrentSideTab] = useState(0);
  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);

  // misc
  const [interviews, setInterviews] = useState([])
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false)
  const [getInterviewCalled, setGetInterviewCalled] = useState(false);
  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const [getSessionListCalled, setGetSessionListCalled] = useState(false);
  const [getVideoRecordingLinkCalled, setGetVideoRecordingLinkCalled] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [copied, setCopied] = useState(false)
  const [showMessages, setShowMessages] = useState(false)
  const [currentMessages, setCurrentMessages] = useState([])
  const [showScorecard, setShowScorecard] = useState(false)
  const [currentScorecard, setCurrentScorecard] = useState([])
  const [actionsOpen, setActionsOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [loomLinkReplaceCalled, setLoomLinkReplaced] = useState(false)
  const [team, setTeam] = useState({})
  const [teams, setTeams] = useState([])
  
  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );
  
  useEffect(() => {
    if(!user || !isAuthenticated) {
      return
    }

    if(Object.keys(team).length > 0 && teams.length > 0) {
      return
    }

    if(isPubliclyViewable) {
      return
    }

    const teamId = localStorage.getItem('teamId')
    console.log('teamId', teamId)
    if(!teamId) {
      navigate('/')
      return
    } else {
      if(Object.keys(team).length > 0) {
        return
      }
      var userId = user.email
      const overrideUser = localStorage.getItem('globalOverrideUser')
      if(overrideUser) {
        userId = overrideUser
      }

      api.getTeam({ userId: userId, teamId }).then((res) => {
        console.log('team', res.data)
        setTeam(res.data)
      }).catch((err) => {console.log(err)})

      api.getTeams({ userId: userId }).then((res) => {
        console.log('teams', res.data)
        setTeams(res.data)
      })
    }
  }, [isPubliclyViewable, user, isAuthenticated, team, teams, setTeam, setTeams])

  const actionsRef = React.useRef(null);
  const shareRef = React.useRef(null);

  useEffect(() => {
    if(isPubliclyViewable && !getInterviewCalled) {
      api.getPublicInterview({ sessionId: session_id })
      .then((response) => {
        setInterview(response.data)
        setGetInterviewCalled(true);
        getSession(session_id)
        .then((response) => {
          console.log(response)
          setAudioRecordingLink(response.recording_url)
          setCurrentMessages(response.messages)
          setCurrentScorecard(response.scorecard)
          setSession(response)
        })
        .catch((err) => {
          console.error(err)
        })
  
        if(!loomLinkReplaceCalled && Object.keys(session).length > 0) {
          loom.linkReplace('.loom-video')
          setLoomLinkReplaced(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })

      axios.post(`${proxyEndpoint}/generate_video_recording_link`, { id: session_id })
      .then((response) => {
        setVideoRecordingLink(response.data.url)
        setGetVideoRecordingLinkCalled(true)
        console.log(response.data.url)
      })
      .catch((error) => {
        console.error('Error generating video recording link:', error);
      });      
      return
    }

    if(user && !getInterviewCalled) {
      var userId = user.email
      const teamId = localStorage.getItem('teamId')
      var overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      api.getInterview(interview_id, userId, teamId)
      .then((response) => {
        setInterview(response.data)
        setGetInterviewCalled(true);
      })
      .catch((err) => {
        console.error(err)
      })
    }

    if(user && !getInterviewsCalled) {
      var userId = user.email
      var overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      if(!teamId) {
        navigate('/')
        return
      }
      api.getInterviews(userId, teamId)
      .then((response) => {
        // setInterviews(response.reverse())
        setInterviews(response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))        
        setGetInterviewsCalled(true)
      })
    }

    if(user && !getSessionCalled) {
      getSession(session_id)
      .then((response) => {
        console.log(response)
        setAudioRecordingLink(response.recording_url)
        setCurrentMessages(response.messages)
        setCurrentScorecard(response.scorecard)
        setSession(response)
      })
      .catch((err) => {
        console.error(err)
      })

      if(!loomLinkReplaceCalled && Object.keys(session).length > 0) {
        loom.linkReplace('.loom-video')
        setLoomLinkReplaced(true)
      }
    }

    if(user && !getSessionListCalled) {
      getCompletedSessions(interview_id)
      .then((response) => {
        const sessions = response
          .filter(session => session.status === 'Completed')
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map(session => session._id.$oid);
        setSessionList(sessions)

        const session_idx = sessions.indexOf(session_id);
        if (session_idx == -1) {
          console.log("session doesn't exist");
          return;
        }

        if (session_idx > 0) {
          setPreviousSessionId(sessions[session_idx - 1]);
        } else {
          setPreviousSessionId(null);
        }

        if (session_idx < sessions.length - 1) {
          setNextSessionId(sessions[session_idx + 1]);
        } else {
          setNextSessionId(null);
        }

        setGetSessionListCalled(true)
      })
      .catch((err) => {
        console.error(err)
      })
    }

    if(user && !getVideoRecordingLinkCalled) {
      axios.post(`${proxyEndpoint}/generate_video_recording_link`, { id: session_id })
      .then((response) => {
        console.log(response.data.url)
        setVideoRecordingLink(response.data.url)
        setGetVideoRecordingLinkCalled(true)
        console.log(response.data.url)
      })
      .catch((error) => {
        console.error('Error generating video recording link:', error);
      });
    }
  }, [getVideoRecordingLinkCalled, getInterviewsCalled, getInterviewCalled, interview_id, interview, user, session_id, videoRecordingLink, loomLinkReplaceCalled]);

  const handleOnCopy = (interviewConfigId) => {
    console.log('handleOnCopy', interviewConfigId)
    navigate(`/create-interview?copy_from=${interviewConfigId}`);
  }

  const [accountOpen, setAccountOpen] = useState(false);
  const accountRef = useRef(null);

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };
  const { logout } = useAuth0();
  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };

  const teamClicked = (teamId) => {
    if (team._id.$oid != teamId) {
      const new_team = teams.find((t) => t._id.$oid == teamId);
      localStorage.setItem('teamId', new_team._id.$oid)
      setTeam(new_team)
      navigate('/interviews')
    }
  }

  const callOverrideCandidateAction = (value) => {
    // value: 'reject' or 'accept'
    const override = {
      customerOverrideCandidateAction: value
    }
    const interview_session = Object.assign(session, override);
    return axios.post(proxyEndpoint + '/interview_sessions/' + session_id, { interview_session }) // TODO: add session token auth
    .then(response => {
      setSession(response.data);

      setSnackbarMsg(`Successfully set score to ${value} candidate`)
      setOpenSnackbar(true)

      return response.data;
    })
    .catch(err => {
      console.error(err);
    });
  }

  const navigate = useNavigate()

  const navigateToSession = (sessionId) => {
    setGetSessionCalled(false);
    setGetSessionListCalled(false);
    setGetVideoRecordingLinkCalled(false);
    setVideoRecordingLink('');
    setAudioRecordingLink('');

    navigate("/interviews/" + interview_id + "/sessions/" + sessionId);
  }

  const handleSideTabChange = (event, newValue) => {
    setCurrentSideTab(newValue);
  }

  const handleActionsToggle = () => {
    setActionsOpen((prevOpen) => !prevOpen);
  };

  const handleShareToggle = () => {
    setShareOpen((prevOpen) => !prevOpen);
  };

  const handleActionsClose = (event) => {
    if (
      actionsRef.current &&
      actionsRef.current.contains(event.target)
    ) {
      return;
    }
    setActionsOpen(false)
  }

  const handleShareClose = (event) => {
    if (
      shareRef.current &&
      shareRef.current.contains(event.target)
    ) {
      return;
    }
    setShareOpen(false)
  }

  function interviewSort(a, b) {
    // top_val comes first
    if (a.type && a.type == 'template') return 1;
    if (b.type && b.type == 'template') return -1;
    // neither matches `value` - compare them normally
    else return 0;
  }

  const normalizeScore = (score, scale) => {
    if (Number(score) == 0) return 1;
    return (Number(score) * 100 / scale);
  }

  const getColorFromScore = (score, scale) => {
    const range = normalizeScore(score, scale);
    if (range < 50) return '#E02E2E'
    if (range < 75) return '#E27C1E'
    else return '#5C9B0A';
  }

  const getColorBgFromScore = (score, scale) => {
    const range = normalizeScore(score, scale);
    if (range < 50) return '#F8D5D5'
    if (range < 75) return '#FFF7ED'
    else return '#EEF5E6';
  }

  const sessionScore = useMemo(() => {
    if (!currentScorecard || currentScorecard.length === 0) return -1;
    
    if (session.internalOverrideReject) {
      return 0;
    }

    const totalWeightCheck = currentScorecard.reduce((sum, item) => sum + (item.weight || 0), 0);
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      currentScorecard.forEach(item => item.weight = equalWeight);
    }

    let weightedSum = 0;
    let totalWeight = 0;

    currentScorecard.forEach((scorecardItem) => {
      const configItem = currentScorecard.find(item => item.signal === scorecardItem.signal);
      if (configItem) {
        const weight = (configItem.weight / 100) || 0;
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    return weightedAverage.toFixed(1);
  }, [currentScorecard, session])

  const passSession = (session) => {
    if (session.internalOverrideReject) {
      console.log('session has been internally overridden to reject')
      return false;
    }    

    if (!session.scorecard || session.scorecard.length === 0 || !session.metadata.interview_config || !currentScorecard) return -1;
    // Check if weights add up to 100, if not, set them to equal weights
    const totalWeightCheck = currentScorecard.reduce((sum, item) => sum + (item.weight || 0), 0);
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      currentScorecard.forEach(item => item.weight = equalWeight);
    }    
    
    let weightedSum = 0;
    let totalWeight = 0;

    session.scorecard.forEach((scorecardItem) => {
      const configItem = currentScorecard.find(item => item.signal === scorecardItem.signal);
      if (configItem) {
        const weight = (configItem.weight / 100) || 0;
        console.log('weight', weight)
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    const avgFixed = weightedAverage.toFixed(1);
    return avgFixed >= 3.0;
  }

  const renderRubricItemCount = (scorecard) => {
    let itemCount = 0;
    if(!scorecard || scorecard === undefined) {
      return 'N / A'
    }

    scorecard.map((rubricItem) => {
      if(rubricItem.score >= 3) {
        itemCount += 1
      }
      return 0
    })

    return `${itemCount} / ${scorecard.length} (${((itemCount / scorecard.length) * 100).toFixed(2)}%)`
  }

	const renderOutputValue = (value) => {
		if(!Array.isArray(value)){
			return value;
		}

		if (value.length === 0) {
			return '[]';
		}


		const formattedItems = value.map(item => {
			if (typeof item === 'string') {
				return `'${item}'`;
			}
			return String(item);
		});

		const output = '[\n  ' + formattedItems.join(',\n  ') + '\n]';
		return output;
	}

  const renderStructuredOutputs = (structuredOutputs) => {
    if(!structuredOutputs) {
      return <>
              <div className="d-flex flex-col section">
                <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                  No outputs found yet.
                </p>
              </div>
            </>
    }

    return structuredOutputs.map((output) => {
      return (
        <div className="d-flex flex-col section" style={{ padding: '12px', borderRadius: '12px', border: '1px solid lightgray' }}>
          {Object.entries(output).map(([key, value]) => (
            <>
              <div className="section-heading">
                <ChipTag variant="default" text={key} style={{ width: 'max-content' }} />
              </div>
              <p class="section-body" style={{ fontSize: '14px' }}>
                {renderOutputValue(value)}
              </p>
            </>
            )
          )}
        </div>
      )
    });
  }

  return (
    <Box className="w-100" sx={{display: 'flex'}}>
      {/* Side Nav (TODO REFACTOR: avoid unnecessary re-render of component) */}
      {!isPubliclyViewable &&
      <>
      <Drawer
          variant="permanent"
          sx={{
            width: 312,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              backgroundColor: 'var(--color-dark-mode)', width: 312, zIndex: "var(--zIndex-canvas)" },
          }}
        >
          <button
            className="btn-main btn-dark-mode btn-logo d-flex"
            style={{ height: "fit-content", backgroundColor: 'var(--color-dark-mode)', padding: '8px 12px' }}
            aria-label="menu"
            onClick={() => {
              if (location.pathname != "/your-team") navigate("/interviews");
            }}
          >
            <img className="mr-auto" alt="Wayfaster" style={{ height: '32px' }} src={WayfasterLogo} />
          </button>

          <div style={{ marginRight: '12px', marginLeft: '12px' }}>
            <Switcher currentTeam={team} teams={teams} popper={true} clicked={teamClicked}></Switcher>
          </div>

          <button
          className="btn-main btn-dark-mode d-flex"
          style={{
            margin: "8px 12px 0px",
            padding: '16px 12px',
            borderRadius: '8px',
            backgroundColor: 'transparent',
            border: '1px solid #3B3B3B',
            width: 'calc(100% - 24px)',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '56px',
            boxSizing: 'border-box',
            outline: 'none', // Remove default focus outline
            boxShadow: '0 0 0 1px #3B3B3B' // Add a white box shadow to create a visible border
          }}
            onClick={() => {
              navigate('/create-interview')
            }}
          >
            <span style={{ color: '#fff', fontSize: '14px', fontWeight: 600 }}>New Interview</span>
            <Add sx={{ color: '#fff', fontSize: '18px' }} />
          </button>            

          <div
            className={`d-flex flex-row clickable getting-started ${location.pathname === '/getting-started' ? 'selected' : ''}`}
            style={{
              margin: "8px 12px 0px", 
              padding: '12px', 
              borderRadius: '8px', 
              backgroundColor: location.pathname === '/getting-started' ? 'var(--color-hover-dark)' : 'var(--color-dark-mode)',
            }}
            onClick={() => {
              navigate('/getting-started');
            }}
          >
            <div className="d-flex align-items-center" style={{ width: '100%' }}>
              <PlayArrowIcon sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }} />
              <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Getting Started / Help Videos</h4>
            </div>
          </div>   

          <div className="d-flex flex-row clickable" style={{ margin: "8px 12px 0px", padding: '12px', borderRadius: '8px', backgroundColor: 'var(--color-hover-dark)' }}
            onClick={() => setInterviewAccordionOpen((prev) => !prev)}>
            <PeopleOutlinedIcon className="my-auto" sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }}></PeopleOutlinedIcon>
            <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Interviews</h4>
            <ExpandMoreIcon fontSize="14px" className="my-auto" style={{ transition: 'all .3s ease', marginLeft: 'auto', color: '#fff', transform: interviewAccordionOpen ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
          </div>
          {!getInterviewsCalled && <CircularProgress size={20} className="mx-auto" sx={{ color: 'var(--color-primary)', marginTop: '16px' }}/>}
          {interviews.length > 0 &&
            <>
            <Scrollbars renderThumbVertical={thumbVDark} style={{ transition: 'all .3s ease', flex: interviewAccordionOpen ? 5 : 0, scrollbarColor: 'rgba(255, 255, 255, 0.2) var(--color-dark-mode)' }}>
              <Box sx={{ overflow: 'hidden', padding: '0px 12px', boxShadow: 'inset -8px -12px 48px -8px var(--color-dark-mode)' }}>
                <List style={{ paddingTop: '0px' }} className="interview-list-wrapper">
                {interviews.length === 0 ?
                  <div style={{ margin: 'auto'  }}>
                    <p style={{ fontSize: '12px', color: 'var(--color-dark-mode-light)' }}>No interviews to show</p>
                  </div>
                  : interviews.sort(interviewSort).map((interview) => {
                  return (
                      <ListItem
                        className={interview_id === interview._id ? 'interview-list-item-selected' : "interview-list-item" }
                        style={{
                          padding: '4px 8px',
                          borderRadius: 0,
                          height: 'fit-content',
                          margin: '4px 0px',
                          width: '264px'
                        }}
                        onClick={() => {
                          navigate('/interviews/' + interview._id);
                        }}
                      >
                        <div class="d-flex flex-row" style={{ width: '100%' }}>
                          <div className="d-flex" style={{ flex: 11 }}>
                            <p style={{ lineHeight: '18px', fontSize: '14px', margin: '8px 4px', fontWeight: 600, color: interview_id === interview._id ? '#fff' :  'var(--color-dark-mode-light)'}}>
                              {interview.type && interview.type == 'template' && <Circle className="m-auto color-mid" style={{ fontSize: 8, marginRight: '4px' }}/>}
                              {interview.title}
                            </p>
                          </div>
                          <div className="d-flex" style={{ marginLeft: 'auto', flex: 1 }}>
                            <button className="btn-main small d-flex gray-dark outlined my-auto"
                              style={{ marginLeft: 'auto', width: '28px', height: '28px' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleOnCopy(interview._id)}
                              }>
                              <ContentCopyOutlined className="m-auto" sx={{ fontSize: '18px', color: 'var(--color-dark-mode-light)' }}></ContentCopyOutlined>
                            </button>
                          </div>
                        </div>
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
            </Scrollbars>
            </>
          }

          <Box style={{ flex: 3 }}>
            {/* placeholder */}
          </Box>
          
        {user && isAuthenticated && (
          <>
            <button
              className="btn-main btn-dark-mode btn-logo d-flex"
              style={{ height: "fit-content", backgroundColor: 'var(--color-dark-mode)', padding: '8px 12px' }}
              aria-label="menu"
              onClick={() => {
                  navigate("/settings");
              }}>
              <SettingsIcon className="my-auto" style={{ marginRight: '8px', fontSize: '18px', color: 'var(--color-dark-mode-light)' }}></SettingsIcon>
                          
              <p style={{ lineHeight: '20px', fontSize: '14px', margin: '8px 4px', fontWeight: 600, color: 'var(--color-dark-mode-light)' }}>
                Settings
              </p>
            </button>
            
            <div className="d-flex flex-row mx-auto" style={{ padding: '8px', width: '100%' }}>
              <button
                className="btn-main btn-dark-mode d-flex flex-row"
                style={{ height: "40px", width: '100%' }}
                ref={accountRef}
                onClick={handleAccountToggle}
              >
                <Avatar
                  className="my-auto"
                  sx={{
                    bgcolor: "var(--color-primary)",
                    color: "black",
                    width: 32,
                    height: 32,
                    textTransform: "capitalize",
                    fontSize: "16px",
                    marginTop: "-2px",
                    marginRight: "6px",
                  }}
                >
                  {Array.from(user.email)[0]}
                </Avatar>
                <h6
                  className="color-main my-auto"
                  style={{ marginRight: "4px", fontSize: "12px", color: "#fff" }}
                >
                  {user.email}
                </h6>
                <UnfoldMore className="color-gray my-auto" style={{ marginLeft: 'auto', fontSize: '24px' }} />
              </button>
            </div>
          </>
        )}

        <Popper
          open={accountOpen}
          anchorEl={accountRef.current}
          role={undefined}
          placement="bottom-end"
          style={{ width: "250px", zIndex: 9999 }}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleAccountClose}>
                  <MenuList
                    autoFocusItem={accountOpen}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    sx={{
                      ["& .MuiMenuItem-root"]: {
                        fontSize: "14px",
                      },
                      ["& .MuiTypography-root"]: {
                        fontSize: "14px",
                      },
                    }}
                  >
                      <>
                          <MenuItem
                          onClick={() => {
                              localStorage.removeItem("teamId");
                              localStorage.removeItem("globalOverrideUser");
                              logout({ returnTo: "/" });
                          }}
                          >
                          <ListItemIcon>
                              <LogoutIcon
                              className="color-danger"
                              fontSize="14px"
                              />
                          </ListItemIcon>
                          <ListItemText className="color-danger">
                              Logout
                          </ListItemText>
                          </MenuItem>
                      </>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Drawer>
      </>
      }

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />
      {/* Session */}
      <Box style={{ background: 'white', borderRadius: 6, width: '100%' }}>
        {(!session || Object.keys(session).length === 0) &&
          <>
            <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
          </>
        }
        {(session && Object.keys(session).length > 0) &&
          <Box container>
            {/* Session Header */}
            <Box style={{ width: '100%', padding: '16px 24px', borderBottom: '1px solid lightgray' }}>
              <Grid item xs={12}>
                <div className="d-flex flex-row">
                  {!isPubliclyViewable &&
                    <div className="d-flex flex-row" style={{ marginRight: 'auto' }}>
                      <button className="btn-main small d-flex gray outlined"
                        onClick={() => {navigate("/interviews/" + interview._id)}}>
                        <ArrowBack fontSize="14px" className="color-heading m-auto" />
                      </button>
                      <h5 style={{ margin: 'auto 8px', lineHeight: '20px' }}>My Interviews</h5>

                      <h5 className="inactive" style={{ margin: 'auto', lineHeight: '20px' }}>/</h5>

                      <h5 style={{ margin: 'auto 8px', lineHeight: '20px' }}>{ interview.title }</h5>
                    </div>
                  }

                  {!isPubliclyViewable &&
                    <div className="d-flex flex-row" style={{ marginLeft: 'auto' }}>
                      <button className={`btn-main small d-flex gray outlined ${previousSessionId ? "" : "inactive"}`}
                        disabled={!previousSessionId}
                        onClick={() => {navigateToSession(previousSessionId)}}>
                        <NavigateBefore fontSize="14px" className="color-heading m-auto" />
                      </button>
                      <h5 className={[ previousSessionId ? "" : "inactive" ]} style={{ margin: 'auto 8px', lineHeight: '20px' }}>Previous Session</h5>

                      <h5 className="inactive" style={{ margin: 'auto 4px', lineHeight: '20px' }}>|</h5>

                      <h5 className={[ nextSessionId ? "" : "inactive" ]} style={{ margin: 'auto 8px', lineHeight: '20px' }}>Next Session</h5>

                      <button className={`btn-main small d-flex gray outlined ${nextSessionId ? "" : "inactive"}`}
                        disabled={!nextSessionId}
                        onClick={() => {navigateToSession(nextSessionId)}}>
                        <NavigateNext fontSize="14px" className="color-heading m-auto" />
                      </button>
                    </div>
                  }
                </div>
              </Grid>
              <Box className="d-flex flex-row" style={{ width: '100%' }}>
                <div className="d-flex flex-row" style={{ width: '100%', marginTop: '16px' }}>
                  <div className="d-flex flex-col" style={{ marginRight: 'auto' }}>
                    <div className="d-flex flex-row" style={{ marginRight: 'auto' }}>
                      {session.candidate_metadata &&
                        <>
                          <h3 className="my-auto" style={{ fontSize: '20px' }}>{session.candidate_metadata.first_name} {session.candidate_metadata.last_name}</h3>
                        </>
                      }

                      {session.voicemail_detected ?
                        <ChipTag variant="default" text="Voicemail" style={{ marginLeft: '8px' }} />
                      :
                      <>
                        {session.status === 'Not Completed' ?
                          <ChipTag variant="in-progress" text="In Progress" style={{ marginLeft: '8px' }} /> :
                            session.status === 'Completed' ?
                            <ChipTag variant="completed" text="Completed" style={{ marginLeft: '8px' }} /> : ''
                        }


                        {session.status === 'Completed' ?
                            ((session.customerOverrideCandidateAction === 'accept' || passSession(session)) ?
                            <ChipTag variant="accept" text="Accept" style={{ marginLeft: '8px' }} /> :
                            <ChipTag variant="reject" text="Reject" style={{ marginLeft: '8px' }} />)
                            :
                            <ChipTag variant="pending" text="Pending" style={{ marginLeft: '8px' }}/>
                        }

                        {session.potentialCheating &&
                          <ChipTag variant="danger" text="Suspicious Activity" style={{ marginLeft: '8px', backgroundColor: '#FF4D4D', color: 'white' }} />
                        }
                      </>
                      }
                    </div>

                    <h5 className="color-gray" style={{ margin: '8px 0px', fontWeight: 500, fontSize: '14px' }}>
                      {session.created_at.$date ? (
                        new Date(session.created_at.$date).toLocaleDateString()
                      ) : (
                        new Date(session.created_at * 1000).toLocaleDateString()
                      )}
                    </h5>
                  </div>

                  <div className="d-flex flex-row my-auto" style={{ marginLeft: 'auto' }}>
                    <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0 }}>Invited on</h6>
                      <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                        {session.created_at.$date ?
                          <>
                            {new Date(session.created_at.$date).toLocaleDateString()} <br />
                            {new Date(session.created_at.$date).toLocaleTimeString()}
                          </> :
                          <>
                            {new Date(session.created_at * 1000).toLocaleDateString()} <br />
                            {new Date(session.created_at * 1000).toLocaleTimeString()}
                          </>
                        }
                      </p>
                    </div>
                    {session.status === 'Completed' && (
                      <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                        <h6 className="color-gray" style={{ margin: 0 }}>Conducted on</h6>
                        <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                          {new Date(session.date_completed_unix_timestamp).toLocaleDateString()} <br />
                          {new Date(session.date_completed_unix_timestamp).toLocaleTimeString()}
                        </p>
                      </div>
                    )}
                    {(session.metadata && session.metadata.interview_config) && <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0 }}>Duration</h6>
                      <p className="color-heading" style={{ fontSize: '12px', margin: '8px 0 0' }}>
                        {session.messages && session.messages.length > 1 &&
                          <>
                          {((session.messages[session.messages.length - 1].createdAt - session.messages[0].createdAt) / 60).toFixed(2)}
                          </>
                        } mins
                      </p>
                    </div>}
                    {session.client_type && <div className="d-flex flex-col" style={{ marginRight: '16px' }}>
                      <h6 className="color-gray" style={{ margin: 0 }}>Call Type</h6>
                      <p className="color-heading" style={{ margin: '8px 0 0' }}>
                        {session.client_type === 'desktop' && <ComputerIcon fontSize="16px" />}
                        {(session.client_type === 'phone' || (session.client_type === 'mobile' && session.candidate_metadata?.call_type !== 'web')) && <PhoneIcon fontSize="16px" />}
                        {(session.client_type === 'mobile' && session.candidate_metadata?.call_type === 'web') && <ComputerIcon fontSize="16px" />}
                      </p>
                    </div>}

                    {!isPubliclyViewable &&
                    <button className="btn-main d-flex gray outlined my-auto"
                      ref={shareRef}
                      style={{ margin: 'auto 16px' }}
                      onClick={handleShareToggle}>
                        <Share fontSize="14px" className="color-heading m-auto" />
                      </button>
                    }

                    {!isPubliclyViewable &&
                    <>
                    <Popper
                      open={shareOpen}
                      anchorEl={shareRef.current}
                      role={undefined}
                      placement="bottom-end"
                      transition>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: 'right top',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleShareClose}>
                              <MenuList
                                autoFocusItem={shareOpen}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                sx={{
                                  ['& .MuiMenuItem-root'] : {
                                    fontSize: '14px'
                                  },
                                  ['& .MuiTypography-root'] : {
                                    fontSize: '14px'
                                  }
                                }}
                              >
                                <MenuItem onClick={() => {
                                    navigator.clipboard.writeText('https://beta.techinterviewer.ai/?session=' + session.unique_session_id).then((val) => {
                                      setSnackbarMsg('Web link copied to clipboard successfully')
                                      setOpenSnackbar(true)
                                      setCopied(true)
                                      handleShareClose()
                                    })
                                    .catch((err) => {console.error('could not copy')})
                                  }}>
                                  <ListItemIcon>
                                    <ComputerIcon fontSize="14px"/>
                                  </ListItemIcon>
                                  <ListItemText>
                                    Copy Web Link
                                  </ListItemText>
                                </MenuItem>
                                {session.client_type && (session.client_type === 'phone' || session.client_type === 'mobile') && <MenuItem onClick={() => {
                                    navigator.clipboard.writeText('https://beta.techinterviewer.ai/phone?session=' + session.unique_session_id).then((val) => {
                                      setSnackbarMsg('Phone link copied to clipboard successfully')
                                      setOpenSnackbar(true)
                                      setCopied(true)
                                      handleShareClose()
                                    })
                                    .catch((err) => {console.error('could not copy')})
                                  }}>
                                  <ListItemIcon>
                                    <PhoneIcon fontSize="14px"/>
                                  </ListItemIcon>
                                  <ListItemText>
                                    Copy Phone Link
                                  </ListItemText>
                                </MenuItem>}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>

                    {/* TODO: handle click for Session Actions here (web / phone share? edit interview copy?) */}
                    <button className="btn-main d-flex gray outlined my-auto"
                        ref={actionsRef}
                        id="actions-button"
                        aria-controls={actionsOpen ? 'composition-menu' : undefined}
                        aria-expanded={actionsOpen ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleActionsToggle}>
                      Actions
                      <ExpandMore fontSize="14px" className="color-heading m-auto"
                        style={{ marginLeft: '8px' }}/>
                    </button>

                    {/* Actions */}
                    <Popper
                      open={actionsOpen}
                      anchorEl={actionsRef.current}
                      role={undefined}
                      placement="bottom-end"
                      transition>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: 'right top',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleActionsClose}>
                              <MenuList
                                autoFocusItem={actionsOpen}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                sx={{
                                  ['& .MuiMenuItem-root'] : {
                                    fontSize: '14px'
                                  }
                                }}
                              >
                                <h6 style={{ margin: 'auto', padding: '4px 16px' }}>Override Score</h6>
                                <MenuItem className="d-flex flex-row" onClick={() => {
                                    callOverrideCandidateAction('accept')
                                  }} style={{ width: '104px'}}
                                  selected={session.customerOverrideCandidateAction === 'accept' || passSession(session)}>
                                  Accept
                                  <Check className="color-accept" style={{ fontSize: '14px', marginLeft: 'auto' }} />
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    callOverrideCandidateAction('reject')
                                  }} style={{ width: '104px'}}
                                  selected={!(session.customerOverrideCandidateAction === 'accept' || passSession(session))}>
                                  Reject
                                  <Clear className="color-reject" style={{ fontSize: '14px', marginLeft: 'auto' }} />
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    </>
                    }
                  </div>
                </div>
              </Box>
            </Box>

            {/* Session Details */}
            <Box style={{ position: 'relative' }}>
              { session.status === 'Not Completed' && <div className="m-auto" style={{ background: 'white', borderRadius: '8px', position: 'absolute', zIndex: 1000, left: 0, right: 0, top: '124px', height: 'fit-content', width: '352px', padding: '24px', boxShadow: '0px 1px 14px 0px #00000029'}}>
                  <h3 className="section-heading">This interview isn't completed yet</h3>
                  <p className="section color-gray" style={{ marginTop: '0px', fontSize: '14px' }}>
                    Once the candidate has completed the interview, we will display the results here.
                  </p>

                  <button className="btn-main" style={{ width: '100%' }}
                    onClick={() => navigate("/interviews/" + interview._id)}>
                    Back to list of interview sessions
                  </button>
              </div>}
              <Box className={`d-flex flex-row ${session.status === 'Not Completed' ? 'blurred non-interactive' : ''}`}>
                {/* Main Details */}
                <Box className="d-flex flex-col" style={{ flex: 3, padding: '24px', overflow: 'auto' }}>
                  {/* Video / Audio */}
                  <Box className="section">
                    {/* <p style={{ fontWeight: 600 }}>Video Recording</p> */}
                    <div className="section" style={{ borderRadius: '6px', overflow: 'hidden' }}>
                      {(session && session.videoRecordingUrl && session.videoRecordingUrl.includes('loom')) &&
                        <a className="loom-video" href={session.videoRecordingUrl}></a>
                      }
                      {(session && (!session.videoRecordingUrl || (session.videoRecordingUrl && !session.videoRecordingUrl.includes('loom'))) ) &&
                        <>
                          {(videoRecordingLink.length > 0 && (session.client_type === 'desktop' || session.candidate_metadata && session.candidate_metadata.call_type === 'web')) ?
                            <video
                              width="100%"
                              type="video/mp4"
                              controls
                              preload="auto"
                            >
                              <source src={videoRecordingLink} type="video/mp4" />
                            </video>
                            :
                            <div className="d-flex color-empty-bg" style={{ width: '100%', aspectRatio: '16 / 9' }}>
                              <VideocamOffIcon className="m-auto" style={{ color: '#ACACAC' }}/>
                            </div>
                          }
                        </>
                      }
                    </div>
                    {audioRecordingLink && <div className="d-flex flex-col">
                      <h4 className="section-heading">Audio Recording</h4>
                      <audio controls
                        className="audio-player"
                        style={{ background: 'transparent', padding: '4px', width: '50%' }}>
                          <source src={audioRecordingLink} type="audio/mpeg" />
                      </audio>
                    </div>}
                  </Box>

                  {/* Summary */}
                  <Box className="section">
                    <h4 className="section-heading">Summary</h4>
                    <p style={{ fontSize: '14px', margin: 0 }}>
                      {(session.summary === undefined && !session.internalOverrideReject) ? 'N/A' : session.summary}
                    </p>
                    <div className="automatic-rejection-alert" style={{ marginTop: '10px' }}>
                      {session.internalOverrideReject && session.internalOverrideRejectReason ? (
                        <Alert severity="error" icon={<ErrorOutlineIcon fontSize="inherit" />}>
                          <AlertTitle>Automatic Rejection</AlertTitle>
                          <strong>{session.internalOverrideRejectReason}</strong>
                        </Alert>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </Box>
                  {(session.session_specific_questions && session.session_specific_questions.questions && session.session_specific_questions.questions.length > 0) &&
                    <Box className="section">
                    <h4 className="section-heading">Session-Specific Questions</h4>
                      <ol style={{ marginLeft: '18px', fontFamily: 'PPNeueMontreal', padding: 0 }}>
                      {session.session_specific_questions.questions.map((session_specific_question, index) => {
                        return (
                          <li key={index} style={{ marginBottom: '10px', fontSize: '14px' }}>{session_specific_question.question}</li>
                        )
                      })}
                      </ol>
                    </Box>
                  }
                </Box>

                {/* Side Bar (Transcript, Scorecard, Outputs) */}
                <Box className="d-flex flex-col" style={{ flex: 2, padding: '24px', borderLeft: '1px solid lightgray', overflow: 'hidden'}}>
                  <Tabs value={currentSideTab} onChange={handleSideTabChange}
                    style={{ background: 'white', marginBottom: '16px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'PPNeueMontreal',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                    <Tab label="Transcript" onClick={() => setCurrentMessages(session.messages)} disableRipple disabled={session.status === 'Not Completed' } />
                    <Tab label="Scorecard" onClick={() => setCurrentScorecard(session.scorecard)} disableRipple disabled={session.status === 'Not Completed' } />
                    <Tab label="Outputs" disableRipple disabled={session.status === 'Not Completed' } />
                    <Tab label="Questions Asked" disableRipple disabled={session.status === 'Not Completed' } />
                  </Tabs>
                  <Box style={{ maxHeight: '70vh'}}>
                    {/* Transcript */}
                    { currentSideTab == 0 &&
                      <Box className="section" style={{ overflowY: 'auto', maxHeight: '100%', paddingRight: '30px', scrollbarColor: '#d6d6d6 white' }}>
                        {(currentMessages && currentMessages.length > 0) ? currentMessages.map((msg) => {
                          return (
                            <div className="d-flex flex-col section">
                              <h5 className="section-heading">{msg.message.role === 'assistant' ? <b>Wayfaster</b> : <b>{session.candidate_metadata ?
                                <>
                                <span style={{ lineHeight: '24px' }}>
                                  {session.candidate_metadata.first_name + ' ' + session.candidate_metadata.last_name}
                                    <ChipTag className="m-auto" variant="default" text="Candidate" style={{ marginLeft: '12px', marginTop: '-2px' }} />
                                  </span>
                                </>
                                : 'Candidate' }</b>} &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="color-gray" style={{ fontSize: '12px', fontWeight: 400 }}>{new Date((msg.createdAt - currentMessages[0].createdAt)*1000).toISOString().substr(11, 8)}</span>
                              </h5>
                              <p className="section-body" style={{ fontSize: '14px' }}>{msg.message.content}</p>
                            </div>
                          )
                        }) :

                          <div className="d-flex flex-col section">
                            <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                              No transcript found yet.
                            </p>
                          </div>
                        }
                      </Box>
                    }

                    {/* Scorecard */}
                    { currentSideTab == 1 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(currentScorecard && currentScorecard.length > 0) ?
                          <>
                            <div className="d-flex flex-row section" style={{ backgroundColor: getColorBgFromScore(sessionScore, DEFAULT_SCALE), width: 'auto', padding: '20px', borderRadius: "20px" }}>
                              <div className="d-flex" style={{ backgroundColor: getColorFromScore(sessionScore, DEFAULT_SCALE), height: '48px', width: '48px', borderRadius: "12px", marginRight: '16px' }}>
                                <h2 className="m-auto" style={{ fontSize: '20px', color: 'white' }}>
                                  { sessionScore }
                                </h2>
                              </div>
                              <h5 className="color-main-light my-auto">Overall Score</h5>
                            </div>

                            {currentScorecard.map((scorecardItem, index) => (
                              <div className="d-flex flex-col section" key={index}>
                                <h5 className="section-heading">{scorecardItem.signal}</h5>
                                <div className="d-flex flex-row section-heading">
                                  <h5 style={{ margin: 0, marginRight: '16px', color: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE) }}>
                                    {scorecardItem.score}/{scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE}</h5>
                                  <LinearProgress className="my-auto" variant="determinate" value={normalizeScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE)}
                                    style={{ backgroundColor: "#DADADA", height: '4px', borderRadius: '4px', width: '128px' }}
                                    sx={{
                                      [`& .MuiLinearProgress-bar`]: {
                                        backgroundColor: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE),
                                      },
                                    }} />
                                </div>
                                <p className="section-body color-gray" style={{ fontWeight: 500, fontSize: '12px' }}>
                                  {scorecardItem.feedback}
                                </p>
                              </div>
                            ))}
                          </>
                          :
                          <div className="d-flex flex-col section">
                            {session.internalOverrideReject && session.internalOverrideRejectReason ? (
                              <div className="automatic-rejection-alert">
                                <Alert severity="error" icon={<ErrorOutlineIcon fontSize="inherit" />}>
                                  <AlertTitle>Automatic Rejection</AlertTitle>
                                  <strong>{session.internalOverrideRejectReason}</strong>
                                </Alert>
                              </div>
                            ) : (
                              <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                                No scorecard found yet.
                              </p>
                            )}
                          </div>

                        }
                      </Box>
                    }
                    {/* Questions Completed */}
                    { currentSideTab == 3 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(session.metadata && session.metadata.interview_config && session.metadata.interview_config.questions) ? (
                          <>
                            <div className="d-flex flex-row section" style={{ 
                              backgroundColor: getColorBgFromScore(
                                Math.round((session.parrot_text_index + 1) / session.metadata.interview_config.questions.length * 100), 
                                100
                              ),
                              width: 'auto', 
                              padding: '20px', 
                              borderRadius: "20px" 
                            }}>
                              <div className="d-flex" style={{ 
                                backgroundColor: getColorFromScore(
                                  Math.round((session.parrot_text_index + 1) / session.metadata.interview_config.questions.length * 100), 
                                  100
                                ), 
                                height: '48px', 
                                width: 'auto', 
                                minWidth: '48px', 
                                borderRadius: "12px", 
                                marginRight: '16px', 
                                padding: '0 8px' 
                              }}>
                                <h2 className="m-auto" style={{ fontSize: '16px', color: 'white', whiteSpace: 'nowrap' }}>
                                  {session.parrot_text_index + 1} / {session.metadata.interview_config.questions.length}
                                </h2>
                              </div>
                              <h5 className="color-main-light my-auto">Questions Asked</h5>
                            </div>

                            {session.metadata.interview_config.questions.map((question, index) => (
                              <div className="d-flex flex-col section" key={index}>
                                <div className="d-flex flex-row align-items-center">
                                  <Checkbox 
                                    checked={index <= session.parrot_text_index} 
                                    disabled 
                                    style={{padding: '0px', marginRight: '12px'}}
                                  />
                                  <h5 className={index <= session.parrot_text_index ? 'section-heading color-main' : 'section-heading color-gray'} style={{ margin: 0 }}>
                                    {question.question}
                                  </h5>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="d-flex flex-col section">
                            <p className="section-body color-gray" style={{ fontSize: '14px' }}>
                              No questions found.
                            </p>
                          </div>
                        )}
                      </Box>
                    }

                    {/* Outputs */}
                    { currentSideTab == 2 &&
                      <Box className="section" style={{ overflowY: 'auto', height: '100%' }}>
                        {(session.metadata && session.metadata.interview_config && session.metadata.interview_config.found_structured_outputs) ?
                          renderStructuredOutputs(session.metadata.interview_config.found_structured_outputs)
                          :
                          renderStructuredOutputs(null)
                        }
                      </Box>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}

const InterviewDetailDraggableRow = ({ idx, question, questions, setCurrentQuestion, setShowAddQuestionInput, setQuestionDeletionId, setQuestionDeletionIndex, setShowQuestionDeletionConfirmation }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: question.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        border: 'none', borderBottom: '1px solid lightgray', padding: '0px', margin: '0px' }}
    >
      <TableCell>
        <RowDragHandleCell rowId={question.id}/>
      </TableCell>
      <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', marginTop: '10px', fontWeight: 600, fontSize: '14px' }}>
        {question.question}
      </TableCell>
      <TableCell sx={{ padding: '0px', margin: '0px', paddingLeft: '17px' }}>{question.follow_ups}</TableCell>
      <TableCell sx={{ padding: '0px', margin: '0px', marginLeft: '40px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', marginBottom: 'auto' }}>
          {/* Edit */}
          <IconButton
            onClick={() => {
              setCurrentQuestion({
                text: question.question,
                numFollowUps: question.follow_ups,
                // section: question.section,
                editIndex: questions.findIndex((q) => q.id == question.id),
              })
              setShowAddQuestionInput(true)
            }}
            className="drop-shadow-btn"
            style={{ marginLeft: '15px', color: 'black',fontSize: '14px', fontWeight: 500, marginRight: '8px', borderRadius: '9px', border: '0.5px solid lightgray', padding: '6px 9px 6px 8px' }}
          >
            <img src={EditIconNewSvg} /> Edit
          </IconButton>
          {/* Delete */}
          <IconButton
            disabled={questions.length === 1}
            onClick={() => {
              setQuestionDeletionId(question.id);
              setShowQuestionDeletionConfirmation(true);
            }}
            className="drop-shadow-btn"
            style={{ background: '#FFF8F8', color: '#F33D3D',fontSize: '14px', fontWeight: 500, marginRight: '4px', borderRadius: '9px', border: '0.5px solid lightgray', padding: '6px 9px 6px 8px' }}
          >
            <img src={DeleteIconNewSvg} /> Delete
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export const RadioBtn = ({ value, setValue, expectedValue, label }) => {
  return (
    <div className="radio-btn" style={{
      borderRadius: 12,
      height: '70px',
      width: '245px',
      padding: '12px',
      border: value === expectedValue ? '1px solid black' : '1px solid #CDCDCD'
    }}
    onClick={() => {
      setValue(expectedValue)
    }}>
      <div style={{ marginTop: '5px' }}>
        <Radio
          checked={value === expectedValue}
          onChange={() => {setValue(expectedValue)}}
          value={expectedValue}
        />
        <span style={{ display: 'inline-block', fontSize: '14px', fontWeight: 500  }}>
          {label}
        </span>
      </div>
    </div>
  )
}

export const InterviewDetail = ({ importedInterviews }) => {
  const ANALYSIS_ACCESS = ['miguelpatricktaruc@gmail.com', 'varun@techinterviewer.ai', 
    'shreyas@techinterviewer.ai', 'shreyas.jaganmohan@gmail.com', 
    'jshrivastav@instawork.com', 'mshukr@instawork.com', 'shreyas@shreyasj.com', 'talha@tokenmetrics.com', 'obaid@tokenmetrics.com', 'mlaken@atlantic-grp.com'];
  
  const [accountOpen, setAccountOpen] = useState(false);
  const accountRef = useRef(null);

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };
  const { logout } = useAuth0();
  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };

  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);

  const { id } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const [interview, setInterview] = useState({});
  const [getInterviewCalled, setGetInterviewCalled] = useState(false);
  const [completedSessions, setCompletedSessions] = useState([])
  const [filteredSessions, setFilteredSessions] = useState([])
  const [sessionFilter, setSessionFilter] = useState(null);
  const [getCompletedSessionsCalled, setGetCompletedSessionsCalled] = useState(false);
  const [showStructuredOutputs, setShowStructuredOutputs] = useState(false);
  const [showScorecard, setShowScorecard] = useState(false);
  const [currentStructuredOutputs, setCurrentStructuredOutputs] = useState([]);
  const [currentScorecard, setCurrentScorecard] = useState([]);
  const [currentScorecards, setCurrentScorecards] = useState([]);
  const [newPrompt, setNewPrompt] = useState('')
  const [newEvalPrompt, setNewEvalPrompt] = useState('')
  const [currentMessages, setCurrentMessages] = useState([])
  const [showMessages, setShowMessages] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [currentSummary, setCurrentSummary] = useState('')
  const [sending, setSending] = useState(false)
  const [showCandidateLinkCreation, setShowCandidateLinkCreation] = useState(false)
  const [showBulkCandidateLinkCreation, setShowBulkCandidateLinkCreation] = useState(false)
  const [createBulkCandidateSessionActiveStep, setCreateBulkCandidateSessionActiveStep] = useState(0)
  const [bulkInviteType, setBulkInviteType] = useState('')
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [createSessionInputs, setCreateSessionInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    callType: 'web'
  })
  const [file, setFile] = useState("");
  const [CSVData, setCSVData] = useState([]);
  const [interviewRequiresSave, setInterviewRequiresSave] = useState(false)
  const [uniqueSessionLink, setUniqueSessionLink] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [copied, setCopied] = useState(false)
  const [showVideoRecording, setShowVideoRecording] = useState(false)
  const [, setCurrentVideoRecording] = useState('')
  const [currentTab, setCurrentTab] = useState(0);
  const [uniqueSessionId, setUniqueSessionId] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [newInterviewTitle, setNewInterviewTitle] = useState('')
  const [newInterviewLength, setNewInterviewLength] = useState(10)
  const [sessionSpecificContext, setSessionSpecificContext] = useState('')
  const [errMsgs, setErrMsgs] = useState('')
  const [createCandidateSessionActiveStep, setCreateCandidateSessionActiveStep] = useState(0)
  const [editGeneratedQuestionsIndex, setEditGeneratedQuestionsIndex] = useState(-1)
  const [quickActionsAnchorEl, setQuickActionsAnchorEl] = useState(null);
  const [shareOpenIdx, setShareOpenIdx] = useState(-1)
  const [rerunningIdxes, setRerunningIdxes] = useState([])
  const [rerunningIds, setRerunningIds] = useState([]);
  const [runningBulk, setRunningBulk] = useState(false);
  const [sessionSpecificQuestionsStarted, setSessionSpecificQuestionsStarted] = useState(false)
  const [globalOverrideUser, setGlobalOverrideUser] = useState('')
  const [sections, setSections] = useState(SECTIONS_OPTIONS)
  const [newSectionName, setNewSectionName] = useState("")
  
  // render settings
  const [currentVoice, setCurrentVoice] = useState({});
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    console.log('overrideUserLocal', overrideUserLocal)
    console.log(globalOverrideUser)
    if(globalOverrideUser !== undefined && globalOverrideUser.length === 0 && overrideUserLocal != null) {
      setGlobalOverrideUser(overrideUserLocal)
    }
  }, [globalOverrideUser, setGlobalOverrideUser])

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )
  const anchorRef = useRef(null)
  const shareRef = React.useRef(null);

  const handleListShareToggle = (e, idx) => {
    e.preventDefault()
    e.stopPropagation()
    if (quickActionsAnchorEl == e.currentTarget) {
      handleListShareClose(e);
    } else {
      setQuickActionsAnchorEl(e.currentTarget);
      setShareOpenIdx(idx);
    }
  };

  const assumeUser = () => {
    localStorage.setItem('globalOverrideUser', globalOverrideUser)
    fetch(proxyEndpoint + '/me', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: globalOverrideUser }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      response.json().then((data) => {
        console.log(data)
        localStorage.setItem('teamId', data.teams[0]._id)
        window.location.reload();
      })
    })
    .then(data => {
      console.log(data);
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }

  const teamClicked = (teamId) => {
    if (team._id.$oid != teamId) {
      const new_team = teams.find((t) => t._id.$oid == teamId);
      localStorage.setItem('teamId', new_team._id.$oid)
      setTeam(new_team)
      navigate('/interviews')
    }
  }

  const handleListShareClose = (event) => {
    setQuickActionsAnchorEl(null);
    setShareOpenIdx(-1);
  };


  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setShareOpenIdx(-1);
    } else if (event.key === 'Escape') {
      setShareOpenIdx(-1);
    }
  }

  // Questions page
  const [currentQuestion, setCurrentQuestion] = useState({
    text: '',
    numFollowUps: 0,
    // section: '',
    editIndex: -1
  })
  const [showAddQuestionInput, setShowAddQuestionInput] = useState(false)
  const [showQuestionDeletionConfirmation, setShowQuestionDeletionConfirmation] = useState(false)
  const [questionDeletionIndex, setQuestionDeletionIndex] = useState(-1)
  const [questionDeletionId, setQuestionDeletionId] = useState('')

  // Scorecard page
  const [showAddSignalInput, setShowAddSignalInput] = useState(false)
  const [showAddSignalAssistInput, setShowAddSignalAssistInput] = useState(false)
  const [currentSignal, setCurrentSignal] = useState({
    '4PointCriteria': '',
    '3PointCriteria': '',
    '2PointCriteria': '',
    '1PointCriteria': '',
    'weight': 1,
    'scale': 4,
    'signal': '',
    'description': '',
    'editIndex': -1
  })
  const [generatedCriteria, setGeneratedCriteria] = useState({});
  const [signalDeletionIndex, setSignalDeletionIndex] = useState(-1)
  const [showSignalDeletionConfirmation, setShowSignalDeletionConfirmation] = useState(false)
  
  const [scoringGenerating, setScoringGenerating] = useState(false);
  const [scoringAssistStep, setScoringAssistStep] = useState(0);

  const [scoringAddTab, setScoringAddTab] = useState(0);
  const [scoringActiveCriteria, setScoringActiveCriteria] = useState('1PointCriteria');

  // Structured Outputs page
  const [showAddOutputInput, setShowAddOutputInput] = useState(false)
  const [currentOutput, setCurrentOutput] = useState({})
  const [outputDeletionIndex, setOutputDeletionIndex] = useState(-1)
  const [showOutputDeletionConfirmation, setShowOutputDeletionConfirmation] = useState(false)
  const [generatedSessionQuestions, setGenerateSessionQuestions] = useState([])
  const [generateSessionSpecificQuestionsLoading, setGenerateSessionSpecificQuestionsLoading] = useState(false)

  // misc
  const [interviews, setInterviews] = useState(importedInterviews ? importedInterviews : [])
  const [previousInterviewId, setPreviousInterviewId] = useState('');
  const [nextInterviewId, setNextInterviewId] = useState('');
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false)

  const [statusOrder, setStatusOrder] = useState('asc')
  const [completedOrder, setCompletedOrder] = useState('asc')
  // const [completedOrderSetOnPageLoad, setCompletedOrderSetOnPageLoad] = useState(false)

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (filteredSessions.length > 0 && completedSessions.length === filteredSessions.length && completedOrder === 'asc' && !completedOrderSetOnPageLoad) {
  //       handleSortCompleted();
  //       setCompletedOrder('desc');
  //       setCompletedOrderSetOnPageLoad(true)
  //     }
  //   }, 1000);
  // }, [filteredSessions, completedSessions, completedOrderSetOnPageLoad]);
  const [invitedOrder, setInvitedOrder] = useState('asc')
  const [scoreOrder, setScoreOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('status')
  const ref = useRef(null)

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    callType: false,
    inviteMethod: false,
  });
  const [inviteType, setInviteType] = useState('')
  const [callType, setCallType] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [generatedCandidateSessionLink, setGeneratedCandidateSessionLink] = useState('')
  const [candidateEmailSent, setCandidateEmailSent] = useState(false)
  const [candidateEmail, setCandidateEmail] = useState('');
  const [candidatePhoneNumber, setCandidatePhoneNumber] = useState('')
  const [createUniqueInterviewSessionFailed, setCreateUniqueInterviewSessionFailed] = useState(false)
  const [sessionCreationActiveStep, setSessionCreationActiveStep] = useState(-1)
  const [creationFlow, setCreationFlow] = useState('')
  const [videoRecorder, setVideoRecorder] = useState('builtin')
  const [practiceChatEnabled, setPracticeChatEnabled] = useState(false)
  const [roleBackground, setRoleBackground] = useState({
    interviewTitle: '',
    assessmentLanguage: 'English',
    jobTitle: '',
    interviewLength: 10
  })

  // preview
  const [previewOpen, setPreviewOpen] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState({});
  const [interviewPreview, setInterviewPreview] = useState([])

  const setSelectedPreview = async (question) => {
    setCurrentPreviewQuestion(question);
    if (question.sample) {
      setInterviewPreview(question.sample);
    } else {
      let sample = await callGenerateConversationPreview({
        topic: question.question,
        follow_ups: String(question.follow_ups),
        context_type: 'question_preview',
        job_title: interview.jobTitle, 
        title: interview.title
      })
      if (sample) setInterviewPreview(sample);
      else setCurrentPreviewQuestion({});
      console.log('sample', sample)
      question = {...question, sample}
      updateInQuestions(question.id, question)
    }
  }

  const updateInQuestions = async (questionId, updatedQuestion) => {
    let updatedQuestions = [...interview.questions];
    let updatedIndex = updatedQuestions.findIndex((question) => question.id == questionId)
    if (updatedIndex >= 0) updatedQuestions[updatedIndex] = updatedQuestion
    else updatedQuestions = [...updatedQuestions, updatedQuestion]
    setInterview({...interview, questions: updatedQuestions})
  }

  const callGenerateConversationPreview = ({topic, follow_ups, context_type, job_title, title }) => {
    setLoadingPreview(true);
    return api.generateConversationPreview({ topic, follow_ups, context_type, job_title, title })
    .then((response) => {
      setLoadingPreview(false);
      return(response.data.sample)
    })
    .catch((error) => {
      console.log(error)
      setLoadingPreview(false);
      return null;
    })
  }

  const truncate = (str) => {
    let max = 48;
    return str.length > max ? str.substring(0, max) + "..." : str;
  }

  useEffect(() => {
    if(!importedInterviews && user && !getInterviewCalled) {
      let tmpId = id
      const teamId = localStorage.getItem('teamId')
      if(importedInterviews && importedInterviews.length > 0) {
        tmpId = importedInterviews[0]._id
      }
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      api.getInterview(tmpId, userId, teamId)
      .then((response) => {
        setInterview(response.data)
        setNewInterviewTitle(response.data.title)
        setNewInterviewLength(response.data.length)
        setVideoRecorder(response.data.videoRecorder)
        setPracticeChatEnabled(response.data.practiceChatEnabled)
        refreshSections(response.data);
        setNewPrompt(response.data.prompt ? response.data.prompt : '')
        setNewEvalPrompt(response.data.eval_prompt ? response.data.eval_prompt : '')

        // get voices
        api.getVoices().then((voice_res) => {
          let voices = voice_res.data.voices
          setVoices(voices)
          
          let current_voice = response.data.voice ? response.data.voice : voices.find((voice) => voice.voice_id == '11labs-Jason');
          setCurrentVoice(current_voice);
        })
        setGetInterviewCalled(true);
      })
      .catch((err) => {
        console.error(err)
      })
    }
    console.log(importedInterviews, user, getCompletedSessionsCalled)
    if(!importedInterviews && user && !getCompletedSessionsCalled) {
      getCompletedSessions(id)
      .then((response) => {
        setCompletedSessions(response)
        handleSortCompleted(response, 'desc');
        setCompletedOrder('desc');        
        setGetCompletedSessionsCalled(true)
      })
    }

    if(!importedInterviews && user && !getInterviewsCalled) {
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      if(!teamId) {
        navigate('/')
        return
      }

      api.getInterviews(userId, teamId)
      .then((response) => {
        console.log('response', response)
        const interviewsResponse = response.reverse();
        setInterviews(response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))                

        const interviewIds = interviewsResponse.map((interview) => interview._id);
        const interview_idx = interviewIds.indexOf(id);
        if (interview_idx == -1) {
          console.log("interview doesn't exist");
          return;
        }

        if (interview_idx > 0) {
          setPreviousInterviewId(interviewIds[interview_idx - 1]);
        } else {
          setPreviousInterviewId(null);
        }

        if (interview_idx < interviewIds.length - 1) {
          setNextInterviewId(interviewIds[interview_idx + 1]);
        } else {
          setNextInterviewId(null);
        }

        setGetInterviewsCalled(true)
      })
      .catch((err) => {
        console.log(err)
        return
      })
    }
  }, [interview, importedInterviews, getInterviewsCalled, getCompletedSessionsCalled, getInterviewCalled, id, user]);
  const [team, setTeam] = useState({})
  const [teams, setTeams] = useState([])
  useEffect(() => {
    if(!user || !isAuthenticated) {
      return
    }

    if(Object.keys(team).length > 0 && teams.length > 0) {
      return
    }

    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    var userId = user.email
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }

    const teamId = localStorage.getItem('teamId')
    console.log('teamId', teamId)
    console.log(Object.keys(team).length, teams.length)
    if(Object.keys(team).length > 0 && teams.length > 0) {
      return
    }

    if(!teamId) {
      navigate('/')
      return
    } else {
      api.getTeam({ userId: userId, teamId }).then((res) => {
        console.log('team', res.data)
        setTeam(res.data)
      }).catch((err) => {
        localStorage.removeItem('teamId')
        navigate('/')
        return
      })

      api.getTeams({ userId: userId }).then((res) => {
        console.log('teams', res.data)
        setTeams(res.data)
      })
    }
  }, [user, isAuthenticated, team, teams, setTeam, setTeams])
  const [showErr, setShowErr] = useState(false)

  const navigate = useNavigate()
  const createCandidateSessionSteps = ['Session Details', 'Specific Questions'];
  const [warnings, setWarnings] = useState([])
  const createBulkCandidateSessionSteps = ['Upload CSV', 'Sessions'];
  const [bulkCreating, setBulkCreating] = useState(false)
  
  const handleScoringAddTabChange = (event, newValue) => {
    // out of bounds, greater than previous scale
    let criteriaLabel = `${newValue + 1}PointCriteria`;
    if (!(criteriaLabel in currentSignal)) {
      setCurrentSignal({
        ...currentSignal,
        [criteriaLabel]: "", // init new criteria
      });
    }
    setScoringAddTab(newValue);
  }


  if(isLoading) {
    return <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
  }
  if(!isAuthenticated) {
    navigate('/')
  }

  const isCreateCandidateSessionStepOptional = (currentStep) => {
    if(currentStep === 0) {
      return true
    }
    return false
  };

  const isCreateCandidateSessionStepSkipped = (step) => {
    return false;
  };

  const handleGenerateSessionSpecificQuestions = () => {
    console.log('handleGenerateSessionSpecificQuestions')
    axios.post(`${proxyEndpoint}/generate_interview_questions`, {
      interview_config_id: id,
      context: sessionSpecificContext,
      context_type: 'session_specific'
    })
      .then(response => {
        setGenerateSessionQuestions(response.data.questions)
      })
      .catch(error => {
        console.error(error);
      });
  }

  const phoneNumIsCorrect = (phoneNum) => {
    const regex = /^\+1\d{10}$/;
    return regex.test(phoneNum);
  }

  const sessionDetails = () => {
    console.log('sessionDetails')
    const validateForm = () => {
      let isValid = true;
      let errors = {
        firstName: false,
        lastName: false,
        email: false,
        phoneNumber: false,
        inviteMethod: false,
        callType: false,
      };

      if (!firstName.trim()) {
        errors.firstName = true;
        isValid = false;
      }

      if (!lastName.trim()) {
        errors.lastName = true;
        isValid = false;
      }

      if (inviteType === 'email' && !validateEmail(candidateEmail)) {
        errors.email = true;
        isValid = false;
      }

      if (callType === 'phone' && !phoneNumIsCorrect(candidatePhoneNumber)) {
        errors.phoneNumber = true;
        isValid = false;
      }

      if(inviteType === '') {
        errors.inviteMethod = true;
        isValid = false
      }

      if(callType === '') {
        errors.callType = true;
        isValid = false;
      }

      setFormErrors(errors);
      return isValid;
    };

    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p style={{ fontWeight: 600, fontSize: 20, fontFamily: 'PPNeueMontreal'}}>Create candidate session </p>
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontSize: '14px', fontFamily:'PPNeueMontreal' }}> Enter candidate first name</p>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <TextField
              value={firstName}
              placeholder={'Joe'}
              onChange={(e) => {setFirstName(e.target.value)}}
              fullWidth={true}
              error={showErr && formErrors.firstName}
              helperText={showErr ? formErrors.firstName ? "First name is required" : "": ""}
            />
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontSize: '14px', fontFamily:'PPNeueMontreal' }}> Enter candidate last name</p>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <TextField
              value={lastName}
              placeholder={'Smith'}
              onChange={(e) => {setLastName(e.target.value)}}
              fullWidth={true}
              error={showErr && formErrors.lastName}
              helperText={showErr ? formErrors.lastName ? "Last name is required" : "": ""}
            />
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontSize: '14px', fontFamily:'PPNeueMontreal' }}> Call type </p>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn value={callType} setValue={setCallType} expectedValue={'web'} label={'Web'} />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn value={callType} setValue={setCallType} expectedValue={'phone'} label={'Phone'} />
          </Grid>
          {callType === 'phone' &&
            <>
              <Grid item xs={12}>
                <p style={{ fontSize: '14px', fontFamily:'PPNeueMontreal' }}> Candidate phone number</p>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <TextField
                  fullWidth={true}
                  style={{ outline: 0, textDecoration: 'none', fontFamily: 'PPNeueMontreal', fontSize: '14px'  }}
                  value={candidatePhoneNumber}
                  type="text"
                  placeholder={'+15555555555'}
                  onChange={(e) => {
                    setCandidatePhoneNumber(e.target.value)
                  }}
                  error={formErrors.phoneNumber}
                  helperText={formErrors.phoneNumber ? 'Invalid phone number format' : ''}
                />
              </Grid>
            </>
          }
          {(showErr && formErrors.callType) &&
            <Grid item xs={12}>
              <p style={{ fontWeight: 400, color: '#d32f2f', fontSize: '12px', fontFamily: 'PPNeueMontreal', margin: 0, padding: 0, marginBottom: '10px' }}>
              Select a call type.
              </p>
            </Grid>
          }
          <Grid item xs={12}>
            <p style={{ fontSize: '14px', fontFamily:'PPNeueMontreal' }}> How do you want to send the invite?</p>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn value={inviteType} setValue={setInviteType} expectedValue={'session_link'} label={'Session link'} />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn value={inviteType} setValue={setInviteType} expectedValue={'email'} label={'Send invitation via Email'} />
          </Grid>
          {inviteType === 'email' &&
            <>
              <Grid item xs={12}>
                <p style={{ fontSize: '14px', fontFamily:'PPNeueMontreal' }}> Candidate Email </p>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <TextField
                  fullWidth={true}
                  style={{ outline: 0, textDecoration: 'none', fontFamily: 'PPNeueMontreal', fontSize: '14px'  }}
                  value={candidateEmail}
                  onChange={(e) => {setCandidateEmail(e.target.value)}}
                  placeholder={'joe.smith@gmail.com'}
                  error={formErrors.email}
                  helperText={formErrors.email ? 'Invalid email format' : ''}
                />
              </Grid>
            </>
          }
          {(showErr && formErrors.inviteMethod) &&
            <Grid item xs={12}>
              <p style={{ fontWeight: 400, color: '#d32f2f', fontSize: '12px', fontFamily: 'PPNeueMontreal', margin: 0, padding: 0, marginBottom: '10px' }}>
              Select an invite method.
              </p>
            </Grid>
          }
          <Grid item xs={12} style={{ marginBottom: '100px' }}>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className="btn-inverted"
                style={{
                  fontSize: '14px',
                  padding: '10px 15px',
                  marginRight: '16px'
                }}
                onClick={() => {setShowCandidateLinkCreation(false)}}
              >
                Cancel
              </Button>
              <Button
                className="btn-main"
                style={{
                  fontSize: '14px',
                  padding: '10px 15px'
                }}
                onClick={() => {
                  if (validateForm()) {
                    setCreateCandidateSessionActiveStep(createCandidateSessionActiveStep + 1);
                  } else {
                    setShowErr(true);
                  }
                }}
              >
                Next step <ArrowForwardIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    )
  }

  const handleCreateCandidateSession = () => {
    /*
      interview_config_id = request.json.get('interviewConfigId')
      user_id = request.json.get('userId')
      unique_session_id = str(uuid.uuid4())
      candidate_first_name = request.json.get('candidateFirstName')
      candidate_last_name = request.json.get('candidateLastName')
      candidate_email = request.json.get('candidateEmail')
      candidate_phone = request.json.get('candidatePhone')
      call_type = request.json.get('callType')
      session_specific_questions = request.json.get('sessionSpecificQuestions', [])
    */
    var userId = user.email
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    const teamId = localStorage.getItem('teamId')

    const payload = {
      interviewConfigId: interview._id,
      userId: userId,
      candidateFirstName: firstName,
      candidateLastName: lastName,
      candidateEmail: candidateEmail,
      candidatePhone: candidatePhoneNumber,
      callType: callType,
      sessionSpecificQuestions: generatedSessionQuestions,
      teamId: teamId,
    }

    console.log(payload)

    createUniqueInterviewSession(payload.interviewConfigId, payload.candidateFirstName, payload.candidateLastName, payload.candidateEmail, payload.candidatePhone, payload.callType, payload.userId, payload.teamId, payload.sessionSpecificQuestions)
    .then((response) => {
      console.log(response)
      if(payload.candidateEmail.length > 0) {
        setCandidateEmailSent(true)
      }
      if(payload.candidatePhone.length > 0) {
        setGeneratedCandidateSessionLink('https://beta.techinterviewer.ai/phone?session=' + response)
      } else {
        setGeneratedCandidateSessionLink('https://beta.techinterviewer.ai/?session=' + response)
      }
    })
    .catch((response) => {
      console.log('failed to make request', response)
      setCreateUniqueInterviewSessionFailed(true)
    })
  }

  const sessionSpecificQuestions = () => {
    console.log('generatedSessionQuestions', generatedSessionQuestions)
    if(sessionSpecificQuestionsStarted) {
      return (
        <Grid container spacing={2}>
        <Grid item xs={12}>
          {(generatedSessionQuestions.questions === undefined || generatedSessionQuestions.questions.length === 0) &&
            <CreateInterviewJD
              setCreationFlow={setCreationFlow}
              setActiveStep={setSessionCreationActiveStep}
              setAddQuestions={setGenerateSessionQuestions}
              setShowAddQuestionInput={setShowAddQuestionInput}
              setRoleBackground={setRoleBackground}
              roleBackground={roleBackground}
              fullWidth={true}
              btnText={'Generating questions'}
              showInterviewSetup={false}
              headerText={'Enter session-specific information'}
              label={'Session-Specific Information'}
            />
          }
          {(generatedSessionQuestions.questions && generatedSessionQuestions.questions.length > 0) &&
            <div>
              <Paper style={{ minHeight: '400px', padding: '25px', margin: 'auto', marginTop: "24px", width: '100%', boxShadow: 'none', borderRadius: '6px', border: '1px solid #D9D9D9' }}>
                <h3 className="section-heading" style={{ marginTop: 0 }}>Session-specific Questions</h3>
                <Grid container>
                  <TableContainer style={{ padding: 0 , width: '100%', margin: 0}}>
                    <Table style={{ padding: 0, margin: 0, width: '100%'}}>
                      <TableHead style={{ padding: 0, margin: 0, width: '100%'}}>
                        <TableRow style={{ padding: 0, margin: 0, width: '100%'}}>
                          <TableCell style={{ padding: 0, margin: 0, width: '100%', fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            Question
                          </TableCell>
                          <TableCell style={{ display: 'flex', minWidth: '80px', padding: '4px', margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500, width: '72px'}}>
                            Follow-ups
                          </TableCell>
                          <TableCell style={{ fontSize: 16, color: '#656565', border: 'none'}}>
                            {/* Actions */}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <SortableContext
                          items={generatedSessionQuestions.questions.map((question, idx) => {return question.id })}
                          strategy={verticalListSortingStrategy}
                        >
                        {generatedSessionQuestions.questions.map((question, idx) => (
                          <SessionSpecificDraggableRow
                            idx={idx}
                            row={question}
                            setAddQuestions={setGenerateSessionQuestions}
                            setShowAddQuestionInput={setShowAddQuestionInput}
                            stateAddQuestions={generatedSessionQuestions}
                          />
                        ))}
                        </SortableContext>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <button className="btn-main d-flex" style={{ marginTop: '16px' }}
                    onClick={() => {triggerShowAddModal('question')}}>
                    <Add className="my-auto" fontSize="24px"/>
                    Add Question
                  </button>     */}
                </Grid>
              </Paper>
            </div>
          }
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '100px' }}>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className="btn-inverted"
              style={{
                fontSize: '14px',
                padding: '10px 15px',
                marginRight: '16px'
              }}
              onClick={() => {
                setShowCandidateLinkCreation(false);
                setCreateCandidateSessionActiveStep(0);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-main"
              style={{
                fontSize: '14px',
                padding: '10px 15px'
              }}
              onClick={() => {
                setCreateCandidateSessionActiveStep(createCandidateSessionActiveStep+1);
                handleCreateCandidateSession();
              }}
            >
              Next step <ArrowForwardIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
            </Button>
          </Box>
        </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img style={{ marginLeft: '5%'  }} src={MakeInterviewUnique} />
          </Grid>
          <Grid item xs={12}>
            <IconButton className="btn-main" style={{ marginLeft: '225px', marginTop: '25px', fontSize: '14px', paddingTop: '15px', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px' }} onClick={() => {setSessionSpecificQuestionsStarted(true)}}>
              <img src={PlayIcon} /> &nbsp;&nbsp;Let's start
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ color: '#70707B', fontSize: '16px', textAlign: 'center', marginTop: '25px' }}>This step is not required.</h3>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '100px' }}>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className="btn-inverted"
                style={{
                  fontSize: '14px',
                  padding: '10px 15px',
                  marginRight: '16px'
                }}
                onClick={() => {
                  setShowCandidateLinkCreation(false);
                  setCreateCandidateSessionActiveStep(0);
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn-main"
                style={{
                  fontSize: '14px',
                  padding: '10px 15px'
                }}
                onClick={() => {
                  setCreateCandidateSessionActiveStep(createCandidateSessionActiveStep + 1);
                  handleCreateCandidateSession();
                }}
              >
                Next step <ArrowForwardIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      )
    }
  }


  const uploadBulkCSV = () => {
    const VisuallyHiddenInput = styled("input")({
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: 1,
      overflow: "hidden",
      position: "absolute",
      bottom: 0,
      left: 0,
      whiteSpace: "nowrap",
      width: 1,
    });

    const readCSV = (data) => {
      console.log(data);
      const headerRow = [
          "First",
          "Last",
          "Phone #",
          "Email",
          "Worker ID",
          "Shift ID"
      ]

      const isHeaderRow = (row) => {
        if(row.length !== 6) {
          return false
        }

        var headerRowFound = true
        for (let elIdx = 0; elIdx < row.length; elIdx++) {
          let el = row[elIdx]
          if(el !== headerRow[elIdx]) {
            headerRowFound = false
          }
        }

        return headerRowFound
      }

      var currentWarnings = []
      var candidateValids = data.map((row, idx) => {
        console.log(row)
        const humanIdx = idx + 1

        var valid = true
        if(row.length !== 6 && idx !== data.length - 1) {
          currentWarnings.push(`Row ${humanIdx} should have 6 columns`)
          return false
        }

        if(row.length !== 6 && idx === data.length - 1) {
          return false
        }

        // do row validations
        if(isHeaderRow(row)) {
          return false
        }

        // first name
        if(row[0].length === 0 ) {
          currentWarnings.push(`Row ${humanIdx} First (col 1) is empty`)
          valid = false
        }

        // last name
        if(row[1].length === 0 ) {
          currentWarnings.push(`Row ${humanIdx} Last (col 2) is empty`)
          valid = false
        }

        // phone number
        if(row[2].length > 0 && !phoneNumIsCorrect(row[2])) {
          currentWarnings.push(`Row ${humanIdx} has an invalid phone number (col 3)`)
          valid = false
        }

        // email
        function isValidEmail(email) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
        }
        if(row[3].length > 0 && !isValidEmail(row[3])) {
          currentWarnings.push(`Row ${humanIdx} has an invalid email (col 4)`)
          valid = false
        }

        // worker id
        if(row[4].length === 0) {
          currentWarnings.push(`Row ${humanIdx} Worker ID (col 5) is empty`)
          valid = false
        }

        // shift id
        if(row[5].length === 0) {
          currentWarnings.push(`Row ${humanIdx} Shift ID (col 6) is empty`)
          valid = false
        }

        return valid
      })

      var validCandidates = []
      for (let idx = 0; idx < candidateValids.length; idx++) {
        const candidateValid = candidateValids[idx]
        if(candidateValid) {
          validCandidates.push(data[idx])
        }
      }

      setWarnings(currentWarnings)
      setCSVData(validCandidates);
    }

    const handleFileUpload = (e) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        setFile(file);
        processFile(file, readCSV);
      }
    };

    const processFile = (file, callback) => {
      if (file.type === "text/csv") {
        console.log('is csv')
        Papa.parse(file, {
          complete: function(results) {
            console.log(results)
            callback(results.data)
          }
        })
      }
    };

    const clearFile = () => {
      setFile("");
      setCSVData("");
    };

    return (
      <>
        <TooltipCustom
          title={
            <>
              <p className="m-auto">Supports .csv files, formatted to have six columns: First, Last, Phone #, Email, Worker ID, Shift ID</p>
            </>
          }
        >
          <Button
            className="btn-upload d-flex flex-col"
            sx={{
              height: "fit-content",
              alignItems: "flex-start",
              padding: "12px",
              backgroundColor: "var(--color-lightgray) !important",
              boxShadow: "none",
              border: "1px dashed lightgray !important",
            }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
          >
            <p className="section-heading">
              Upload a CSV of candidate information, formatted to five columns:
              First, Last, Phone #, Email, Worker ID, Shift ID
            </p>
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileUpload}
              accept="text/csv"
            />
            <div className="d-flex flex-row">
              <div className="btn-main no-hover">Upload file</div>
              {file && (
                <>
                  <p style={{ fontSize: "12px", margin: "auto 12px" }}>
                    {file.name}
                  </p>

                  <ClearIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      clearFile();
                    }}
                    className="color-danger my-auto"
                    fontSize="24px"
                  ></ClearIcon>
                </>
              )}
            </div>
          </Button>
        </TooltipCustom>

        <Grid item xs={12} style={{ marginTop: '32px' }}>
          {warnings.length > 0 &&
            <>
              <Alert severity="error">
                Issues found with the uploaded CSV:
                <br/>
                <ol style={{ marginLeft: 10, padding: 0 }}>
                  {warnings.map((warning, idx) => {
                    return (
                      <li>
                        {warning}
                      </li>
                    )
                  })}
                </ol>
              </Alert>
              <br/>
            </>
          }
          <Box display="flex" justifyContent="flex-end">
            <Button
              className="btn-main"
              style={{
                fontSize: '14px',
                padding: '10px 15px'
              }}
              onClick={() => {
                setCreateBulkCandidateSessionActiveStep((prev) => prev + 1);
                // handleCreateBulkCandidateSessions();
              }}
              disabled={CSVData.length == 0}
            >
              {CSVData.length == 0 ? "Next step" : `Found ${CSVData.length} valid candidate${CSVData.length > 1 ? 's' : ''}`} <ArrowForwardIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
            </Button>
          </Box>
        </Grid>
      </>
    )
  }
  const inviteBulk = () => {
    const handleCreateBulkCandidateSessions = (data) => {
      /*
        interview_config_id = request.json.get('interviewConfigId')
        user_id = request.json.get('userId')
        unique_session_id = str(uuid.uuid4())
        candidate_first_name = request.json.get('candidateFirstName')
        candidate_last_name = request.json.get('candidateLastName')
        candidate_email = request.json.get('candidateEmail')
        candidate_phone = request.json.get('candidatePhone')
        call_type = request.json.get('callType')
        session_specific_questions = request.json.get('sessionSpecificQuestions', [])
      */
     setBulkCreating(true)
     let candidates = []
     for (const row of data) {
      candidates.push(
        {
          first_name: row[0],
          last_name: row[1],
          phone: row[2],
          email: row[3],
          worker_id: row[4],
          shift_id: row[5],
          call_type: 'web' // TODO: update hardcode, likely add default setting and show visual indication
        }
      )
     }
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      const payload = {
        interviewConfigId: interview._id,
        userId: userId,
        candidates: candidates,
        teamId: teamId,
        inviteType: bulkInviteType
      }
      api.createBulkInterviewSessions(payload)
      .then((response) => {
        const result = response.data
        let successes = result.filter(candidate => candidate.success).length
        setShowBulkCandidateLinkCreation(false)
        setGetCompletedSessionsCalled(false)
        setBulkCreating(false)
        setCSVData([])
        setCreateBulkCandidateSessionActiveStep(0)

        setSnackbarMsg(`Successfully created sessions for ${successes} candidate${successes > 1 ? 's' : ''}.`)
        setOpenSnackbar(true)
      })
      .catch((error) => {
        console.log(error)
        setGetCompletedSessionsCalled(false)

        setSnackbarMsg(`Something went wrong creating bulk sessions.`)
        setOpenSnackbar(true)
        setBulkCreating(false)
      })
    }

    return (
      <>
        <h4>{`Found ${CSVData.length} candidate${CSVData.length > 1 ? 's' : ''}`}</h4>
        <TableContainer style={{ marginTop: 0 }}>
          <Table style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow sx={{
                      '& .MuiTableCell-root': {
                        lineHeight: '20px',
                        backgroundColor: '#FAFAFA',
                        color: 'var(--color-heading)',
                        padding: '12px 16px'
                      }
                    }}>
                <TableCell>
                  First
                </TableCell>
                <TableCell>
                  Last
                </TableCell>
                <TableCell>
                  Phone #
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Worker ID
                </TableCell>
                <TableCell>
                  Shift ID
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CSVData.length > 0 && CSVData.map((row) => {
                return (
                  <TableRow>
                    <TableCell>
                      {row[0]}
                    </TableCell>
                    <TableCell>
                      {row[1]}
                    </TableCell>
                    <TableCell>
                      {row[2]}
                    </TableCell>
                    <TableCell>
                      {row[3]}
                    </TableCell>
                    <TableCell>
                      {row[4]}
                    </TableCell>
                    <TableCell>
                      {row[5]}
                    </TableCell>
                  </TableRow>
                )
              })

              }
            </TableBody>
          </Table>
        </TableContainer>

        <Grid item xs={12} style={{ marginTop: '24px' }}>
          <p style={{ fontSize: '14px', fontFamily:'PPNeueMontreal' }}> How do you want to send the invite?</p>
        </Grid>
        <div className="d-flex flex-row">
          <Grid item xs={6} style={{ paddingTop: 0, marginRight: '16px' }}>
            <RadioBtn value={bulkInviteType} setValue={setBulkInviteType} expectedValue={'session_link'} label={'Session link'} />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <RadioBtn value={bulkInviteType} setValue={setBulkInviteType} expectedValue={'email'} label={'Send invitation via Email'} />
          </Grid>
        </div>
        <Grid item xs={12} style={{ marginTop: '32px' }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className="btn-main gray outlined"
              style={{
                fontSize: '14px',
                padding: '10px 15px',
                marginRight: '8px'
              }}
              onClick={() => {
                setCreateBulkCandidateSessionActiveStep((prev) => prev - 1);
              }}
            >
              Go back
            </Button>
            <Button
              className="btn-main"
              style={{
                fontSize: '14px',
                padding: '10px 15px'
              }}
              onClick={() => {
                handleCreateBulkCandidateSessions(CSVData);
              }}
              disabled={CSVData.length == 0 || bulkInviteType == '' || bulkCreating}
            >
              Create Sessions <ArrowForwardIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
            </Button>
          </Box>
        </Grid>
      </>
    )
  }

  const candidateLinkResults = () => {
    return (
      <Grid container spacing={2}>
        {createUniqueInterviewSessionFailed &&
          <Grid item xs={12}>
            <p style={{ fontSize: 14 }}>
              Candidate session could not be created.
              <br/><br/>
              Please try again or send us an email: <a href="mailto:shreyas@techinterviewer.ai">shreyas@techinterviewer.ai</a>. We will get back to you with a fix in less than 10 minutes.
            </p>
            <Button className="btn-main" onClick={() => {
              handleCreateCandidateSession();
            }}>
              Try again
            </Button>
          </Grid>
        }
        {!createUniqueInterviewSessionFailed &&
          <>
            {(inviteType === 'session_link') &&
              <>
              {generatedCandidateSessionLink.length > 0 &&
                <>
                  <Grid item xs={12}>
                    <p style={{ fontWeight: 600, fontSize: 20, fontFamily: 'PPNeueMontreal'}}>
                      Candidate Session Link
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p style={{ fontSize: 14 }}>
                      Share this session link with the candidate:
                    </p>
                    <p style={{ color: 'gray', fontSize: 12, border: '1px solid lightgray', borderRadius: 10, padding: 10 }}>
                      {generatedCandidateSessionLink}
                    </p>
                  </Grid>
                </>
              }
              {generatedCandidateSessionLink.length === 0 &&
                <Grid item xs={12}>
                <p style={{ fontSize: '14px' }}>Generating candidate session link...</p>
                  <br/><br/><LinearProgress />
                </Grid>
              }
              </>
            }
            {(inviteType === 'email') &&
              <>
              {candidateEmailSent &&
                <Grid item xs={12}>
                  <p style={{ fontSize: '14px'}}>
                    Candidate email has been sent!
                    <br/><br/>
                    Feel free to let the candidate know to check their inbox for an invite link.
                  </p>
                </Grid>
              }
              {!candidateEmailSent &&
                <Grid item xs={12}>
                <p style={{ fontSize: '14px' }}>Sending session invite to candidate's email...</p>
                  <br/><br/><LinearProgress />
                </Grid>
              }
              </>
            }
          </>
        }
      </Grid>
    )
  }

  const renderActiveStepComponent = () => {
    switch (createCandidateSessionActiveStep) {
      case 0:
        return sessionDetails();
      case 1:
        return sessionSpecificQuestions();
      case 2:
        return candidateLinkResults()
      default:
        return null;
    }
  }

  const renderActiveBulkStepComponent = () => {
    switch (createBulkCandidateSessionActiveStep) {
      case 0:
        return uploadBulkCSV();
      case 1:
        return inviteBulk();
      default:
        return null;
    }
  }

  const onCreateUniqueSession = () => {
    setSending(true)
    var userId = user.email
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    createUniqueInterviewSession(interview._id, createSessionInputs.firstName, createSessionInputs.lastName, createSessionInputs.email, createSessionInputs.phone, createSessionInputs.callType, userId, generatedSessionQuestions)
    .then((sessionId) => {
      setUniqueSessionLink(`https://beta.techinterviewer.ai?session=${sessionId}`)
      setUniqueSessionId(sessionId)
      setSending(false)
    })
    .catch((err) => {
      console.error(err)
      setSending(false)
    })
  }

  const handleCreateCandidateLink = () => {
    setShowCandidateLinkCreation(true)
  }

  const handleBulkCandidateLink = () => {
    setShowBulkCandidateLinkCreation(true)
  }

  const handleExport = () => {
    if (completedSessions.length === 0) {
      setOpenSnackbar(true);
      setSnackbarMsg('No sessions to export');
      return;
    }

    // Define the headers for the CSV
    // First, gather all unique signals from completedSessions
    const allSignals = Array.from(new Set(completedSessions.flatMap(session => 
      session.scorecard ? session.scorecard.map(item => item.signal) : []
    )));

    // Gather all unique structured output keys
    const allStructuredOutputKeys = Array.from(new Set(completedSessions.flatMap(session => 
      session.metadata && session.metadata.interview_config && session.metadata.interview_config.found_structured_outputs
        ? session.metadata.interview_config.found_structured_outputs.flatMap(output => Object.keys(output))
        : []
    )));

    // Define the headers for the CSV
    const headers = [
      'Worker ID',
      'Shift ID',
      'Candidate Name',
      'Status',
      'Score',
      'Date Invited',
      'Date Completed',
      'Duration (minutes)',
      'Email',
      'Phone',
      'Web Link',
      'Phone Link',
      'Admin Link',
      'Overall',
      ...allSignals,
      ...allStructuredOutputKeys,
      'Recording',
      'Summary',
      'Questions Asked',
      'Questions Total',
      'Suspicious Activity',
      'Voicemail Detected'
    ];

    // Map the sessions to CSV rows
    const csvData = completedSessions.map(session => {
      const scorecardMap = session.scorecard ? 
        Object.fromEntries(session.scorecard.map(item => [item.signal, item.score])) : {};
      
      const overallAverage = session.scorecard ? 
        (session.scorecard.reduce((sum, item) => sum + item.score, 0) / session.scorecard.length).toFixed(1) : 'N/A';

      const structuredOutputs = session.metadata && session.metadata.interview_config && session.metadata.interview_config.found_structured_outputs
        ? session.metadata.interview_config.found_structured_outputs.reduce((acc, output) => ({ ...acc, ...output }), {})
        : {};

      return [
        session.candidate_metadata.worker_id,
        session.candidate_metadata.shift_id,
        `${session.candidate_metadata.first_name} ${session.candidate_metadata.last_name}`,
        session.status,
        session.scorecard ? passSession(session) ? 'Accept' : 'Reject' : '',
        `"${new Date(session.created_at.$date).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}"`,
        `"${session.date_completed_unix_timestamp ? new Date(parseInt(session.date_completed_unix_timestamp)).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) : ''}"`,
        `"${session.messages && session.messages.length > 0 ? ((session.messages[session.messages.length - 1].createdAt - session.messages[0].createdAt) / 60).toFixed(2) : ''}"`,
        session.candidate_metadata.email,
        session.candidate_metadata.phone,
        `https://beta.techinterviewer.ai?session=${session.unique_session_id}`,
        (session.client_type === 'phone' || session.client_type === 'mobile') ? `https://beta.techinterviewer.ai/phone?session=${session.unique_session_id}` : '',
        `https://admin.techinterviewer.ai/interviews/${interview._id}/sessions/${session._id.$oid}`,
        overallAverage,
        ...allSignals.map(signal => scorecardMap[signal] || 'N/A'),
        ...allStructuredOutputKeys.map(key => {
          const value = structuredOutputs[key];
          if (typeof value === 'string') {
            return `"${value.replace(/"/g, '""')}"`;
          } else if (value !== undefined && value !== null) {
            return `"${String(value).replace(/"/g, '""')}"`;
          } else {
            return 'N/A';
          }
        }),
        session.status === 'Completed' ? `"${session.recording_url}"` || '' : '',
        session.status === 'Completed' ? `"${session.summary ? session.summary.replace(/"/g, '""') : ''}"` : '',
        session.status === 'Completed' && session.metadata && session.metadata.interview_config && session.metadata.interview_config.questions ? 
          session.parrot_text_index + 1 : 
          'N/A',
        session.status === 'Completed' && session.metadata && session.metadata.interview_config && session.metadata.interview_config.questions ? 
          session.metadata.interview_config.questions.length : 
          'N/A',
        session.potentialCheating ? 'Yes' : 'No',
        session.voicemail_detected ? 'Yes' : 'No'
      ];
    });

    // Prepend headers to csvData
    csvData.unshift(headers);

    // Convert to CSV string
    const csvContent = csvData.map(row => row.join(',')).join('\n');

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and trigger download
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `interview_sessions_${interview._id}_${new Date().toISOString()}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setOpenSnackbar(true);
    setSnackbarMsg('Export completed successfully');
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const viewStructuredOutputs = (session) => {
    console.log('viewStructuredOutputs', session.metadata.interview_config.found_structured_outputs)
    setCurrentStructuredOutputs(session.metadata.interview_config.found_structured_outputs)
    setShowStructuredOutputs(true)
  }

  const viewScorecard = (session) => {
    console.log('viewScorecard', session)
    if(session.scorecards) {
      setCurrentScorecards(session.scorecards)
    } else {
      setCurrentScorecard(session.scorecard)
    }
    setShowScorecard(true)
  }

  const viewTranscript = (session) => {
    console.log('viewTranscript', session)
    setCurrentMessages(session.messages)
    setShowMessages(true)
  }

  const viewSummary = (session) => {
    setCurrentSummary(session.summary)
    setShowSummary(true)
  }

  const viewVideoRecording = (session) => {
    setCurrentVideoRecording(session.videoRecording)
    setShowVideoRecording(true)
  }

  const onUpdatePrompt = () => {
    var promptVal = newPrompt.length > 0 ? newPrompt : '    '
    updatePrompt(interview._id, promptVal)
    .then((resp) => {
      setInterview(resp)
      setOpenSnackbar(true)
      setSnackbarMsg('Interview Prompt changes saved')
    })
    .catch((err) => {
      console.error(err)
    })
  }

  const onUpdateEvalPrompt = () => {
    var promptVal = newEvalPrompt.length > 0 ? newEvalPrompt : '    '
    updateEvalPrompt(interview._id, promptVal)
    .then((resp) => {
      setInterview(resp)
      setOpenSnackbar(true)
      setSnackbarMsg('Eval Prompt changes saved')
    })
    .catch((err) => {
      console.error(err)
    })
  }

  const handleDragEnd = (event) => {
    console.log('handleDragEnd')
    const { active, over } = event;

    if (active.id !== over.id) {
      let updatedInterview = interview

      const oldIndex = interview.questions.findIndex((question) => question.id === active.id);
      const newIndex = interview.questions.findIndex((question) => question.id === over.id);
      updatedInterview = {
        ...interview,
        questions: arrayMove(interview.questions, oldIndex, newIndex)
      }

      axios.post(`${proxyEndpoint}/interviews/${interview._id}`, {
        interview: updatedInterview
      })
        .then(response => {
          setInterview(response.data)
          setCurrentQuestion({
            text: '',
            numFollowUps: 1,
            // section: '',
            editIndex: -1
          })
          setOpenSnackbar(true);
          setSnackbarMsg('Questions have been updated');
        })
        .catch(error => {
          console.error('Failed to update interview questions:', error);
        });
    }
  };

  const handleDragEndSessionSpecific = (event) => {
    console.log('handleDragEndSessionSpecific')
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = generatedSessionQuestions.questions.findIndex((question) => question.id === active.id);
      const newIndex = generatedSessionQuestions.questions.findIndex((question) => question.id === over.id);
      const updatedGeneratedQuestions = {
        ...generatedSessionQuestions,
        questions: arrayMove(generatedSessionQuestions.questions, oldIndex, newIndex)
      }
      setGenerateSessionQuestions(updatedGeneratedQuestions)
    }
  };

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }

  function groupBy(arr, property) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur];
      return acc;
    }, {});
  }

  function refreshSections(interview) {
    const foundSections = interview.questions.reduce((acc, question) => {
      if (!acc.includes(question.section)) acc.push(question.section);
      return acc;
    }, []).sort(sectionSort);
    setSections(foundSections);
  }

  function sectionSort(a, b) {
    const top_val = 'Introduction'
    const null_val = 'undefined'

    // top_val comes first
    if (a == top_val) return -1;
    if (b == top_val) return 1;


    // nulls sort after anything else
    if (a === null_val) {
      return 1;
    }
    if (b === null_val) {
        return -1;
    }

    // neither matches `value` - compare them normally
    return a.localeCompare(b);
  }

  const renderQuestions = () => {
    console.log('renderQuestions', interview.questions)

    const question_sections = groupBy(interview.questions, 'section')
    // show sections only if at least 2 different sections specified
    const showSections = Object.keys(question_sections).length > 1;

    return (
      <div>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          {errMsgs.length > 0 &&
            errMsgs.map((errMsg) => {
              return (
                <Alert severity="error" style={{ width: '80%', margin: 'auto', marginBottom: '15px'}}>{errMsg}</Alert>
              )
            })
          }
          <>
              {/* TODO: style rest of interview tabs to match consistent action buttons, modals */}
              <div className="d-flex flex-row" style={{ padding: '12px 16px', borderBottom: '1px solid lightgray', marginBottom: 0 }}>
                <div style={{ backgroundColor: 'var(--color-lightgray)', borderRadius: '8px', fontSize: '14px', fontWeight: '600', padding: '6px 8px' }}>
                  All Questions ({interview.questions.length})
                </div>
              
                <button className="btn-main outlined gray" style={{ marginLeft: 'auto', marginBottom: 0, padding: '6px 9.24px 6px 8px', fontWeight: 600, fontSize: '14px' }}
                  disabled={getInterviewCalled == false}
                  onClick={() => {
                    setCurrentPreviewQuestion(interview.questions[0]);
                    setSelectedPreview(interview.questions[0])
                    setPreviewOpen(true);
                  }}>
                    Simulate interview
                </button>
                <button className="btn-main" style={{ marginLeft: '12px', marginBottom: 0, padding: '6px 9.24px 6px 8px', fontWeight: 600, fontSize: '14px' }}
                  onClick={() => {setShowAddQuestionInput(true)}}>
                    + Add Question
                </button>
              </div>
              <Grid container style={{ marginTop: 0 }}>
                <TableContainer style={{ marginTop: 0 }}>
                  <Table style={{ marginTop: 0 }}>
                    <TableHead>
                      <TableRow sx={{
                              '& .MuiTableCell-root': {
                                lineHeight: '20px',
                                backgroundColor: '#FAFAFA',
                                color: 'var(--color-heading)',
                                padding: '12px 16px'
                              }
                            }}>
                        <TableCell colSpan={2} style={{ width: '60%' }}>
                          Question
                        </TableCell>
                        <TableCell style={{ minWidth: '72px' }}>
                          Follow Ups
                        </TableCell>
                        <TableCell>
                          Quick Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(question_sections).sort(sectionSort).map((keyName, keyIndex) => (
                        <SortableContext
                          items={question_sections[keyName].map((question, idx) => {return question.id })}
                          strategy={verticalListSortingStrategy}
                        >
                          {showSections && keyName != 'undefined' && <TableSection label={keyName}></TableSection>}
                          {question_sections[keyName].map((question, idx) => (
                            <>
                              <InterviewDetailDraggableRow
                                idx={idx}
                                question={question}
                                questions={interview.questions}
                                setCurrentQuestion={setCurrentQuestion}
                                setQuestionDeletionId={setQuestionDeletionId}
                                setQuestionDeletionIndex={setQuestionDeletionIndex} // TODO: Adjust question delete to base on id not index
                                setShowQuestionDeletionConfirmation={setShowQuestionDeletionConfirmation}
                                setShowAddQuestionInput={setShowAddQuestionInput}
                              />
                            </>
                          ))}
                        </SortableContext>
                      ))
                    }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          {/* Add Question */}
          <Dialog open={showAddQuestionInput}>
              <DialogTitle>
                {showAddQuestionInput ? currentQuestion.editIndex > -1 ? 'Edit Question' : 'Add Question' : 'Questions'}
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12} style={{ height: '220px'}}>
                    <FormField
                      label="Enter Your Question"
                      multiline
                      minRows={8}
                      placeholder="What has been the highlight of your past role?"
                      value={currentQuestion.text}
                      onChange={(e) => {setCurrentQuestion({ ...currentQuestion, text: e.target.value })}}
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      label="Number of Follow Up Questions "
                      type="number"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">Wayfaster assessments are fully dynamic, meaning follow up questions are generated based on what the candidate says.
                        <span style={{ color: '#20C58A'}}> Watch a small demo here to see how it works</span>.
                        </p>
                      </>}
                      value={currentQuestion.numFollowUps}
                      onChange={(e) => {
                        setCurrentQuestion({ ...currentQuestion, numFollowUps: e.target.value })
                      }}
                      />
                    {/* <p style={{ fontSize: 14, marginLeft: 10, marginTop: 20, fontWeight: '500px' }}>What are follow up questions? Wayfaster assessments are fully dynamic, meaning questions are generated based on what the candidate says. <span style={{ color: '#20C58A'}}>Watch a small demo here to see how it works.</span></p> */}
                  </Grid>

                {/*<Grid item xs={12}>
                    <FormField
                      label="Add to a Section"
                      select
                      // defaultValue="Background"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Assign the question to a section of the interview (i.e. Introduction).
                        </p>
                      </>}
                      value={currentQuestion.section}
                      onChange={(e) => {setCurrentQuestion({ ...currentQuestion, section: e.target.value })}}
                      >
                        {sections.map((section) =>
                        <MenuItem key={section} value={section}>
                          {section}
                        </MenuItem>)}
                        <div className="d-flex flex-row">
                          <TextField
                          placeholder="Add a section..."
                          variant="standard"
                          fullWidth={true}
                          InputProps={{
                            disableUnderline: true,
                            style: { padding: '8px 16px', marginBottom: '0px',
                            border: 'none', borderRadius: '6px', fontSize: '16px' },
                            autocomplete: "off",
                            autoFocus: "off"
                          }}
                          sx={{
                            ['& .MuiInput-input'] : {
                              padding: 0,
                            }
                          }}
                          onKeyDown={(e) => e.stopPropagation()}
                          value={newSectionName}
                          onChange={(e) => {
                            setNewSectionName(e.target.value);
                          }}
                        />
                        {newSectionName.trim() != '' && !sections.includes(newSectionName) && <button className="btn-main small d-flex gray outlined my-auto"
                          style={{ margin: 'auto 16px' }}
                          onClick={() => {
                            setSections((prev) => [...prev, newSectionName]);
                            setNewSectionName("");
                          }}>
                          <Add fontSize="14px" className="color-heading m-auto" />
                        </button>}
                      </div>
                      </FormField>
                  </Grid>*/}
                </Grid>
              </DialogContent>
              {/* Error Messages */}
              <div class="d-flex" style={{ width: '100%', marginTop: '-16px'}}>
                {errMsgs.length > 0 &&
                    <Alert key={'alert'} severity="error" style={{ width: '100%', margin: '8px auto' }}>
                      {errMsgs.map((errMsg) => {
                        return <p>{errMsg}</p>
                      })}
                    </Alert>
                }
              </div>
              <DialogActions>
                <div style={{display : 'flex', padding: '16px'}}>
                  <Button className="btn gray outlined"
                    fullWidth={false}
                    style={{ flex: 1, marginRight: '12px', maxWidth: '62px' }}
                    onClick={() => {
                      setShowAddQuestionInput(false)
                      setCurrentQuestion({
                        text: '',
                        numFollowUps: 1,
                        // section: '',
                        editIndex: -1
                      })
                    }}>
                      Cancel
                  </Button>
                  <IconButton className="btn black"
                    style={{ flex: 1, width: '140px' }}
                    onClick={() => {
                      const questionText = currentQuestion.text;
                      const words = questionText.split(' ');
                      let msgs = []
                      if (words.length < 5) {
                        msgs.push('Question must have at least 5 words.');
                      }

                        const numFollowUps = parseInt(currentQuestion.numFollowUps);
                        if (isNaN(numFollowUps) || numFollowUps < 0) {
                          msgs.push('Number of follow up questions must be at least 0.');
                        }

                        if(msgs.length > 0 ) {
                          setErrMsgs(msgs)
                        } else {
                          setErrMsgs([])
                          if(currentQuestion.editIndex >= 0) {
                            let updatedQuestions = [...interview.questions];
                            updatedQuestions[currentQuestion.editIndex] = {
                              id: interview.questions[currentQuestion.editIndex]['id'],
                              question: currentQuestion.text,
                              follow_ups: currentQuestion.numFollowUps,
                              // section: currentQuestion.section
                            }
                            let updatedInterview = {...interview};
                            updatedInterview.questions = updatedQuestions;

                            axios.post(`${proxyEndpoint}/interviews/${interview._id}`, {
                              interview: updatedInterview
                            })
                              .then(response => {
                                if (response.data.message) {
                                  setOpenSnackbar(true);
                                  setSnackbarMsg(response.data.message);
                                } else {
                                  setInterview(response.data)
                                  // refreshSections(response.data);
                                  setCurrentQuestion({
                                    text: '',
                                    numFollowUps: 1,
                                    // section: '',
                                    editIndex: -1
                                  })

                                  setOpenSnackbar(true);
                                  setSnackbarMsg('Question updated successfully');
                                }
                              })
                              .catch(error => {
                                console.error('Failed to update interview questions:', error);
                              });
                          } else {
                            let updatedInterview = {...interview};
                            const newQuestionId = uuidv4();
                            updatedInterview.questions = [...updatedInterview.questions, {
                              question: currentQuestion.text,
                              follow_ups: currentQuestion.numFollowUps,
                              // section: currentQuestion.section,
                              id: newQuestionId,
                            }]

                            axios.post(`${proxyEndpoint}/interviews/${interview._id}`, {
                              interview: updatedInterview
                            })
                              .then(response => {
                                if (response.data.message) {
                                  setOpenSnackbar(true);
                                  setSnackbarMsg(response.data.message);
                                } else {
                                  setInterview(response.data)
                                  // refreshSections(response.data);
                                  setCurrentQuestion({
                                    text: '',
                                    numFollowUps: 1,
                                    // section: '',
                                    editIndex: -1
                                  })

                                  setOpenSnackbar(true);
                                  setSnackbarMsg('Question added successfully');
                                }
                              })
                              .catch(error => {
                                console.error('Failed to update interview questions:', error);
                              });
                          }
                          setShowAddQuestionInput(false)
                        }
                    }}>
                      Save
                    <ArrowForwardIcon style={{ marginLeft: '8px', fontSize: '16px' }} />
                  </IconButton>
                </div>
              </DialogActions>
          </Dialog>
          {/* QUESTION DELETION */}
          <Dialog
            open={showQuestionDeletionConfirmation}
            onClose={() => setShowQuestionDeletionConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Question Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this question? This action cannot be undone:
                {questionDeletionId != '' &&
                  <p style={{ fontSize: 16, padding: '25px', border: '0.5px solid darkgray', borderRadius: '10px' }}>
                    <b>Question:</b> {interview.questions.find((question) => question.id == questionDeletionId) &&
                      <>
                        {interview.questions.find((question) => question.id == questionDeletionId).question}
                      </>
                    }
                    <br/><br/>
                    <b>Follow Ups:</b> {interview.questions.find((question) => question.id == questionDeletionId) &&
                      <>
                      {interview.questions.find((question) => question.id == questionDeletionId).follow_ups}
                      </>
                    }
                  </p>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="btn outlined" onClick={() => setShowQuestionDeletionConfirmation(false)} color="primary">
                Close
              </Button>
              <Button className="btn outlined danger" onClick={() => {
                // Assuming there's a function to handle the deletion
                const tempInterview = { ...interview };
                let delete_idx = tempInterview.questions.findIndex((question) => question.id == questionDeletionId);
                tempInterview.questions.splice(delete_idx, 1);
                fetch(`${proxyEndpoint}/interviews/${id}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    interview: tempInterview
                  }),
                })
                .then(response => response.json())
                .then(data => {
                  setInterview(data);
                  refreshSections(data);
                  setSnackbarMsg('Question has been deleted!')
                  setOpenSnackbar(true)
                  console.log('Question deleted successfully');
                })
                .catch(error => {
                  console.error('Error deleting question:', error);
                });
                setQuestionDeletionId('')
                setQuestionDeletionIndex(-1)
                setShowQuestionDeletionConfirmation(false);
              }} color="primary" autoFocus>
                Delete!
              </Button>
            </DialogActions>
          </Dialog>
        </DndContext>
      </div>
    )
  }

  const renderScorecard = () => {
    const SCORE_SCALES = [2, 3, 4, 5, 6]

    const saveAddScoring = () => {
      let msgs = []

      // VALIDATION LOGIC
      if (currentSignal.signal == "") {
        msgs.push('Skill name must be filled in.')
      }
      if (currentSignal.weight <= 0) {
        msgs.push('Weight must be greater than 0%')
      }
      if (currentSignal.weight >= 100) {
        msgs.push('Weight must not be greater than 100%')
      }

      if (msgs.length > 0 ) {
        setErrMsgs(msgs)
      } else {
        setErrMsgs([])

          // get criteria
        let criteria = {}
        for (let i = 0; i < Number(currentSignal.scale); i++) {
          criteria[`${i + 1}PointCriteria`] = currentSignal[`${i + 1}PointCriteria`];
        }

        let updatedInterview = {...interview}
        if (currentSignal.editIndex >= 0) {
          let updatedSignals = [...interview.scorecard];
          updatedSignals[currentSignal.editIndex] = {
            signal: currentSignal.signal,
            scale: currentSignal.scale,
            ...criteria,
            weight: currentSignal.weight
          }
          updatedInterview = {...updatedInterview, scorecard: updatedSignals}
        } else {
          updatedInterview = { ...updatedInterview, scorecard: [...interview.scorecard, {
            signal: currentSignal.signal,
            scale: currentSignal.scale,
            ...criteria,
            weight: currentSignal.weight
          }]}
        }

        axios.post(`${proxyEndpoint}/interviews/${interview._id}`, {
          interview: updatedInterview
        })
        .then(response => {
          let message = 'Scorecard changes saved successfully'
          console.log(response)
          if (response.data.message) message = response.data.message // no changes to interview
          else setInterview(response.data)

          cancelShowAddModal('signal')
          cancelShowAddModal('signal-assist')

          setCurrentSignal({
            'signal': '',
            'weight': 0,
            'scale': 4,
            '4PointCriteria': '',
            '3PointCriteria': '',
            '2PointCriteria': '',
            '1PointCriteria': '',
            'editIndex': -1
          })
          

          setSnackbarMsg(message)
          setOpenSnackbar(true)
        })
        .catch(error => {
          console.error('Failed to update interview scorecard:', error);
        });
      }
    }

    // returns a normalized criteria set based on all generated criteria
    const normalizeCriteria = (generatedCriteria, scale) => {
      switch (scale) {
        case 2:
          // Poor, Excellent  
          return {
            '1PointCriteria': generatedCriteria['1PointCriteria'],
            '2PointCriteria': generatedCriteria['6PointCriteria']
          }
        
        case 3:
          // Poor, Average, Excellent
          return {
            '1PointCriteria': generatedCriteria['1PointCriteria'],
            '2PointCriteria': generatedCriteria['3PointCriteria'],
            '3PointCriteria': generatedCriteria['6PointCriteria']
          }
            
        case 4:
          // Poor, Fair, Good, Excellent
          return {
            '1PointCriteria': generatedCriteria['1PointCriteria'],
            '2PointCriteria': generatedCriteria['2PointCriteria'],
            '3PointCriteria': generatedCriteria['4PointCriteria'],
            '4PointCriteria': generatedCriteria['6PointCriteria']
          }
          
        case 5:
          // Poor, Fair, Average, Good, Excellent
          return {
            '1PointCriteria': generatedCriteria['1PointCriteria'],
            '2PointCriteria': generatedCriteria['2PointCriteria'],
            '3PointCriteria': generatedCriteria['3PointCriteria'],
            '4PointCriteria': generatedCriteria['4PointCriteria'],
            '5PointCriteria': generatedCriteria['6PointCriteria']
          }
          
        case 6:
          // Poor, Fair, Average, Good, Very Good, Excellent
          return generatedCriteria;
          
        default:
          // Poor, Fair, Average, Good, Very Good, Excellent
          return generatedCriteria;
      }
    }

    const handleGenerateScoringCriteria = ({ context, scale, signal }) => {
      setScoringGenerating(true);
      api.generateScoringCriteria({
        context,
        signal,
        scale,
        title: interview.title,
        job_title: interview.jobTitle
      })
        .then((response) => {
          let data = response.data;
          let criteria = {}
  
          for (let i = 0; i < Number(data.scale); i++) {
            let point = data.criteria[`${i + 1}PointCriteria`]
            criteria[`${i + 1}PointCriteria`] = ""
            for (const [key, value] of Object.entries(point)) {
              criteria[`${i + 1}PointCriteria`] += `• ${key}: ${value}\n`;
            }
          }
          setGeneratedCriteria({
            ...criteria
          });
  
          setCurrentSignal({
            signal: data.signal,
            weight: 0,
            scale: scale,
            ...normalizeCriteria(criteria, scale),
            description: context
          })
          setScoringActiveCriteria('1PointCriteria');
          setScoringAssistStep((prev) => prev + 1);
          setScoringGenerating(false);
        })
        .catch((error) => {
          console.log(error)
          setScoringGenerating(false);
        })
    }

    const cancelShowAddModal = (type) => {
      setErrMsgs([]);
      switch (type) {
        case 'signal':
          setScoringAssistStep(0);
          setScoringAddTab(0);
          setScoringActiveCriteria('1PointCriteria');
          setCurrentSignal({
            'signal': '',
            'weight': 0,
            'scale': 4,
            '4PointCriteria': '',
            '3PointCriteria': '',
            '2PointCriteria': '',
            '1PointCriteria': '',
            'editIndex': -1
          })
          return setShowAddSignalInput(false);
        case 'signal-assist':
          setScoringAssistStep(0);
          setScoringAddTab(0);
          setScoringActiveCriteria('1PointCriteria');
          setCurrentSignal({
            'signal': '',
            'weight': 0,
            'scale': 4,
            '4PointCriteria': '',
            '3PointCriteria': '',
            '2PointCriteria': '',
            '1PointCriteria': '',
            'editIndex': -1
          })
          return setShowAddSignalAssistInput(false);
        default:
          return;
      }
    }
    
    const getScoringPlaceholder = () => {
      let placeholder = "Criteria for "
      switch (scoringActiveCriteria) {
        case '6PointCriteria':
          return placeholder + `"${getScoringDescriptor(6)}"` + ' score (6)'
        case '5PointCriteria':
          return placeholder + `"${getScoringDescriptor(5)}"` + ' score (5)'
        case '4PointCriteria':
          return placeholder + `"${getScoringDescriptor(4)}"` + ' score (4)'
        case '3PointCriteria':
          return placeholder + `"${getScoringDescriptor(3)}"` + ' score (3)'
        case '2PointCriteria':
          return placeholder + `"${getScoringDescriptor(2)}"` + ' score (2)'
        case '1PointCriteria':
          return placeholder + `"${getScoringDescriptor(1)}"` + ' score (1)'
        default:
          return null
      }
    }

    const getScoringDescriptor = (i) => {
      let scale = Number(currentSignal.scale);

      // min / max value base cases
      if (i == 1) return "Poor";
      if (i == scale || i == SCORE_SCALES[SCORE_SCALES.length - 1]) return "Excellent"

      switch (scale) {
        case 3:
          if (i == 2) return "Fair";
        case 4:
          if (i == 2) return "Fair";
          if (i == 3) return "Good";
        case 5:
          if (i == 2) return "Fair";
          if (i == 3) return "Average";
          if (i == 4) return "Good";
        case 6:
          if (i == 2) return "Fair";
          if (i == 3) return "Average";
          if (i == 4) return "Good";
          if (i == 5) return "Very Good";
      }
    }

    return (
      <div>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          {errMsgs.length > 0 &&
            errMsgs.map((errMsg) => {
              return (
                <Alert severity="error" style={{ width: '80%', margin: 'auto', marginBottom: '15px'}}>{errMsg}</Alert>
              )
            })
          }
          <>
              <div className="d-flex flex-row" style={{ padding: '12px 16px', borderBottom: '1px solid lightgray', marginBottom: 0 }}>
                <div style={{ backgroundColor: 'var(--color-lightgray)', borderRadius: '8px', fontSize: '14px', fontWeight: '600', padding: '6px 8px' }}>
                    Scorecard
                </div>
                <button className="btn-main assistant d-flex" style={{ marginLeft: 'auto' }}
                  onClick={() => {setShowAddSignalAssistInput(true);}}>
                  <AutoAwesomeOutlinedIcon className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                  Scoring Assistant
                </button>
                <button className="btn-main" style={{ marginLeft: '12px', marginBottom: 0, padding: '6px 9.24px 6px 8px', fontWeight: 600, fontSize: '14px' }}
                  onClick={() => {setShowAddSignalInput(true)}}>
                    + Add Skill
                </button>
              </div>
              <Grid container style={{ marginTop: 0 }}>
                <TableContainer style={{ marginTop: 0 }}>
                  <Table style={{ marginTop: 0 }}>
                    <TableHead>
                      <TableRow sx={{
                          '& .MuiTableCell-root': {
                            lineHeight: '20px',
                            backgroundColor: '#FAFAFA',
                            color: 'var(--color-heading)',
                            padding: '12px 16px'
                          }
                        }}>
                        <TableCell>
                          Skill
                        </TableCell>
                        <TableCell>
                          Weight (%)
                        </TableCell>
                        <TableCell>
                          Quick Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {interview.scorecard && interview.scorecard.map((signal, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{signal.signal}</TableCell>
                          <TableCell>{signal.weight}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                // edit
                                setCurrentSignal({
                                  ...interview.scorecard[idx],
                                  editIndex: idx,
                                })
                                setShowAddSignalInput(true)
                              }}
                              className="drop-shadow-btn"
                              style={{ color: 'black',fontSize: '14px', fontWeight: 500, marginRight: '8px', borderRadius: '9px', border: '0.5px solid lightgray', padding: '6px 9px 6px 8px' }}
                            >
                              <img src={EditIconNewSvg} alt="Edit" /> Edit
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setSignalDeletionIndex(idx)
                                setShowSignalDeletionConfirmation(true)
                              }}
                              className="drop-shadow-btn"
                              style={{ background: '#FFF8F8', color: '#F33D3D',fontSize: '14px', fontWeight: 500, marginRight: '4px', borderRadius: '9px', border: '0.5px solid lightgray', padding: '6px 9px 6px 8px' }}
                            >
                              <img src={DeleteIconNewSvg} alt="Delete" /> Delete
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>

          
        {/* Add / Edit Signal */}
        <Dialog open={showAddSignalInput} maxWidth={'md'}>
            <DialogContent>
              <h3 className="section-heading" style={{ fontSize: '20px', marginTop: 0 }}>
                Add scoring expectations
              </h3>
              <p style={{ fontSize: '16px', color: 'gray', margin: 0}}>
                Determine what the candidate should be graded on in the interview.
              </p>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={currentSignal.signal}
                      onChange={(e) => {setCurrentSignal({ ...currentSignal, signal: e.target.value })}}
                    />
                </Grid>

                  
                <Grid item xs={2}>
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setCurrentSignal({ ...currentSignal, scale: scale_num });
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
                <Grid item xs={10}>
                <h5 className="input-label d-flex">
                  Scores
                </h5>
                  <Tabs value={scoringAddTab} onChange={handleScoringAddTabChange}
                    style={{ background: 'white', marginBottom: '16px', marginTop: '8px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'PPNeueMontreal',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                      {[...Array((Number(currentSignal.scale)))].map((e, i) => {
                        return <Tab label={`${i + 1} (${getScoringDescriptor(i + 1)})`} onClick={() => setScoringActiveCriteria(`${i + 1}PointCriteria`)} disableRipple />
                      })}
                  </Tabs>
                </Grid>

                <FormField
                    label={getScoringPlaceholder()}
                    tooltip
                    tooltipTitle={<>
                      <p className="m-auto">
                        Write a criteria description that the candidate has to meet to be evaluated for this score. 
                      </p>
                    </>}
                    style={{ marginBottom: '16px' }}
                    multiline
                    minRows={8}
                    placeholder={getScoringPlaceholder()}
                    value={currentSignal[scoringActiveCriteria]}
                    onChange={(e) => {setCurrentSignal({ ...currentSignal, [scoringActiveCriteria]: e.target.value })}}
                    />

                <FormField
                    label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                    value={currentSignal.weight}
                    type="Number"
                    onChange={(e) => {setCurrentSignal({ ...currentSignal, weight: Number(e.target.value) })}}
                  />

              </Grid>
            </DialogContent>
            {/* Error Messages */}
            <div className="d-flex" style={{ width: '100%' }}>
              {errMsgs.length > 0 &&
                errMsgs.map((errMsg) => {
                  return (
                    <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                  )
                })
              }
            </div>
            <DialogActions>
                <div className="d-flex flex-row" style={{ width: '100%', padding: '16px'}}>
                  <Button className="btn-main gray outlined"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => cancelShowAddModal('signal')}>
                      Cancel
                  </Button>
                  <Button className="btn-main"
                    style={{ marginLeft: '12px' }}
                    onClick={() => saveAddScoring()}>
                      Save
                  </Button>
                </div>
            </DialogActions>
        </Dialog>

        
        {/* Add Signal Assist */}
        <Dialog open={showAddSignalAssistInput} maxWidth={'md'}>
            <DialogContent>
              <h3 className="section-heading" style={{ fontSize: '20px', marginTop: 0 }}>
                {scoringAssistStep === 0 ? 
                'Generate score outputs with scoring assistant' 
                : 'Generated expectations from candidate'
                }
              </h3>
              <p style={{ fontSize: '16px', color: 'gray', margin: 0}}>
                Determine what the candidate should be graded on in the interview.
              </p>
              { scoringAssistStep === 0 &&
               <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={currentSignal.signal}
                      onChange={(e) => {setCurrentSignal({ ...currentSignal, signal: e.target.value })}}
                    />
                  <FormField
                      label="Describe what is most important to you in this signal."
                      multiline
                      minRows={8}
                      placeholder="e.g. It is very important to me that the candidate is fluent in English, has good sentence construction and understands what I say to him/her"
                      value={currentSignal.description}
                      onChange={(e) => {setCurrentSignal({ ...currentSignal, description: e.target.value })}}
                      />
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setCurrentSignal({ ...currentSignal, scale: scale_num });
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
              </Grid>}
              
              { scoringAssistStep === 1 &&
               <Grid container>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <FormField
                      label="What is your candidate signal called?"
                      placeholder="e.g. English proficiency"
                      value={currentSignal.signal}
                      onChange={(e) => {setCurrentSignal({ ...currentSignal, signal: e.target.value })}}
                    />
                </Grid>
               
                <Grid item xs={2}>
                  <FormSelect
                      label="Point Scale"
                      tooltip
                      tooltipTitle={<>
                        <p className="m-auto">
                          Determines the scale to grade points over, from a minimum score of 0 points to this maximum value.
                        </p>
                      </>}
                      variant="outlined"
                      value={currentSignal.scale}
                      onChange={(e) => {
                        let scale_num = Number(e.target.value)
                        setCurrentSignal({
                          signal: currentSignal.signal,
                          weight: currentSignal.weight,
                          scale: scale_num,
                          ...normalizeCriteria(generatedCriteria, scale_num),
                          description: currentSignal.description
                        })
                        setScoringActiveCriteria('1PointCriteria')
                        setScoringAddTab(0)
                      }}
                      >
                      {SCORE_SCALES.map((score) => {
                        return <MenuItem value={score}>{score}</MenuItem>
                      })}
                      
                  </FormSelect>
                </Grid>
                <Grid item xs={10}>
                <h5 className="input-label d-flex">
                  Scores
                </h5>
                  <Tabs value={scoringAddTab} onChange={handleScoringAddTabChange}
                    style={{ background: 'white', marginBottom: '16px', marginTop: '8px' }}
                    sx={{
                      ['& .MuiTabs-indicator']: {
                        backgroundColor: 'transparent',
                      },
                      ['& .MuiTab-root']: {
                        color: "var(--color-main-light)",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'PPNeueMontreal',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      ['& .MuiTab-root.Mui-selected']: {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                      {[...Array((Number(currentSignal.scale)))].map((e, i) => {
                        return <Tab label={`${i + 1} (${getScoringDescriptor(i + 1)})`} onClick={() => setScoringActiveCriteria(`${i + 1}PointCriteria`)} disableRipple />
                      })}
                  </Tabs>
                </Grid>

                <FormField
                    label={getScoringPlaceholder()}
                    tooltip
                    tooltipTitle={<>
                      <p className="m-auto">
                        Write a criteria description that the candidate has to meet to be evaluated for this score. 
                      </p>
                    </>}
                    style={{ marginBottom: '16px' }}
                    multiline
                    minRows={8}
                    placeholder={getScoringPlaceholder()}
                    value={currentSignal[scoringActiveCriteria]}
                    onChange={(e) => {setCurrentSignal({ ...currentSignal, [scoringActiveCriteria]: e.target.value })}}
                    />

                <FormField
                    label="How much do you weigh this signal? Give a percentage (%) between 1 and 100."
                    value={currentSignal.weight}
                    type="Number"
                    onChange={(e) => {setCurrentSignal({ ...currentSignal, weight: Number(e.target.value) })}}
                  />
              </Grid>}
                
              {/* Error Messages */}
              <div className="d-flex" style={{ width: '100%' }}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
            </DialogContent>
            <DialogActions>
              <div className="d-flex flex-row" style={{ width: '100%', padding: '16px' }}>
                <Button className="btn-main gray outlined"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {cancelShowAddModal('signal-assist')
                    setErrMsgs([])}}>
                    Cancel
                </Button>
                {scoringAssistStep > 0 && <Button className="btn-main gray outlined"
                  style={{ marginLeft: '12px' }}
                  onClick={() => {setScoringAssistStep((prev) => prev - 1 )
                    setErrMsgs([])}}>
                    Back
                </Button>}
                {scoringAssistStep === 0 && <Button className="btn-main assistant"
                  disabled={
                    scoringGenerating || (currentSignal.signal == "" || currentSignal.description == "")
                  }
                  style={{ marginLeft: '12px' }}
                  onClick={() => handleGenerateScoringCriteria({
                    signal: currentSignal.signal,
                    scale: currentSignal.scale,
                    context: currentSignal.description
                  })}>
                    { scoringGenerating ? 
                    <>
                      Generating...
                      &nbsp;&nbsp;
                      <CircularProgress
                        className="m-auto"
                        size={14}
                        style={{ color: "white", marginLeft: "8px" }}
                        />
                    </>
                    : <>
                    <AutoAwesomeOutlinedIcon className="my-auto" fontSize="24px" style={{ marginRight: '6px' }}/>
                    Generate
                    </> }
                </Button>}
                
                {scoringAssistStep === 1 && <Button className="btn-main"
                  style={{ marginLeft: '12px' }}
                  onClick={() => {saveAddScoring()}}>
                    Save
                </Button>}
              </div>
            </DialogActions>
        </Dialog>
          <Dialog
            open={showSignalDeletionConfirmation}
            onClose={() => setShowSignalDeletionConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Skill Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this skill? This action cannot be undone:
                {signalDeletionIndex > -1 &&
                  <p style={{ fontSize: 16, padding: '25px', border: '0.5px solid darkgray', borderRadius: '10px' }}>
                    <b>Skill:</b> {interview.scorecard[signalDeletionIndex].signal}
                    <br/><br/>
                    <b>Weight:</b> {interview.scorecard[signalDeletionIndex].weight}%
                  </p>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="btn outlined" onClick={() => setShowSignalDeletionConfirmation(false)} color="primary">
                Close
              </Button>
              <Button className="btn outlined danger" onClick={() => {
                const tempInterview = { ...interview };
                tempInterview.scorecard.splice(signalDeletionIndex, 1);
                fetch(`${proxyEndpoint}/interviews/${interview._id}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    interview: tempInterview
                  }),
                })
                .then(response => response.json())
                .then(data => {
                  setInterview(data);
                  setSnackbarMsg('Skill has been deleted!')
                  setOpenSnackbar(true)
                  console.log('Skill deleted successfully');
                })
                .catch(error => {
                  console.error('Error deleting skill:', error);
                });
                setSignalDeletionIndex(-1)
                setShowSignalDeletionConfirmation(false);
              }} color="primary" autoFocus>
                Delete!
              </Button>
            </DialogActions>
          </Dialog>
        </DndContext>
      </div>
    )
  }

  const renderOutputs = () => {
    return (
      <div>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          {errMsgs.length > 0 &&
            errMsgs.map((errMsg) => {
              return (
                <Alert severity="error" style={{ width: '80%', margin: 'auto', marginBottom: '15px'}}>{errMsg}</Alert>
              )
            })
          }
          <>
              <div className="d-flex flex-row" style={{ padding: '12px 16px', borderBottom: '1px solid lightgray', marginBottom: 0 }}>
                <div style={{ backgroundColor: 'var(--color-lightgray)', borderRadius: '8px', fontSize: '14px', fontWeight: '600', padding: '6px 8px' }}>
                  Structured Outputs ({interview.structuredOutputs.length})
                </div>
                <button className="btn-main" style={{ marginLeft: 'auto', marginBottom: 0, padding: '6px 9.24px 6px 8px', fontWeight: 600, fontSize: '14px' }}
                  onClick={() => {setShowAddOutputInput(true)}}>
                    + Add Output
                </button>
              </div>
              <Grid container style={{ marginTop: 0 }}>
                <TableContainer style={{ marginTop: 0 }}>
                  <Table style={{ marginTop: 0 }}>
                    <TableHead>
                      <TableRow sx={{
                          '& .MuiTableCell-root': {
                            lineHeight: '20px',
                            backgroundColor: '#FAFAFA',
                            color: 'var(--color-heading)',
                            padding: '12px 16px'
                          }
                        }}>
                        <TableCell>
                          Key
                        </TableCell>
                        <TableCell>
                          Output Description
                        </TableCell>
                        <TableCell style={{ minWidth: '56px' }}>
                          Data Type
                        </TableCell>
                        <TableCell>
                          Quick Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {interview.structuredOutputs && interview.structuredOutputs.map((output, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{output.key}</TableCell>
                          <TableCell>{output.extraction_instructions}</TableCell>
                          <TableCell>{output.data_type}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setCurrentOutput({
                                  ...interview.structuredOutputs[idx],
                                  editIndex: idx,
                                })
                                setShowAddOutputInput(true)
                              }}
                              className="drop-shadow-btn"
                              style={{ color: 'black',fontSize: '14px', fontWeight: 500, marginRight: '8px', borderRadius: '9px', border: '0.5px solid lightgray', padding: '6px 9px 6px 8px' }}
                            >
                              <img src={EditIconNewSvg} alt="Edit" /> Edit
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOutputDeletionIndex(idx)
                                setShowOutputDeletionConfirmation(true)
                              }}
                              className="drop-shadow-btn"
                              style={{ background: '#FFF8F8', color: '#F33D3D',fontSize: '14px', fontWeight: 500, marginRight: '4px', borderRadius: '9px', border: '0.5px solid lightgray', padding: '6px 9px 6px 8px' }}
                            >
                              <img src={DeleteIconNewSvg} alt="Delete" /> Delete
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          {/* Add/Edit Output */}
          <Dialog open={showAddOutputInput}>
              <DialogTitle>
                {currentOutput.editIndex > -1 ? 'Edit Output' : 'Add Output'}
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: '25px'}}>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      label="Key"
                      placeholder="past_employers"
                      value={currentOutput.key}
                      onChange={(e) => {setCurrentOutput({ ...currentOutput, key: e.target.value })}}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '25px'}}>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      label="Extraction Instructions"
                      placeholder="Get a list of companies the person has worked at"
                      value={currentOutput.extraction_instructions}
                      onChange={(e) => {setCurrentOutput({ ...currentOutput, extraction_instructions: e.target.value })}}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '25px'}}>
                    <Select
                      label="Data Type"
                      variant="outlined"
                      fullWidth={true}
                      value={currentOutput.data_type}
                      onChange={(e) => {setCurrentOutput({ ...currentOutput, data_type: e.target.value })}}
                      >
                      <MenuItem value="List">List</MenuItem>
                      <MenuItem value="Text">Text</MenuItem>
                      <MenuItem value="Number">Number</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </DialogContent>
              {/* Error Messages */}
              <div className="d-flex" style={{ width: '100%', marginTop: '-16px'}}>
                {errMsgs.length > 0 &&
                  errMsgs.map((errMsg) => {
                    return (
                      <Alert severity="error" style={{ width: '100%', margin: 'auto' }}>{errMsg}</Alert>
                    )
                  })
                }
              </div>
              <DialogActions>
                  <div style={{display : 'flex', padding: '16px'}}>
                    <Button className="btn gray outlined"
                      fullWidth={false}
                      style={{ flex: 1, marginRight: '12px', maxWidth: '62px' }}
                      onClick={() => {
                        setShowAddOutputInput(false)
                        setCurrentOutput({
                          key: '',
                          data_type: 'List',
                          extraction_instructions: '',
                          editIndex: -1
                        })
                      }}>
                        Cancel
                    </Button>
                    <IconButton className="btn black"
                      style={{ flex: 1, width: '140px' }}
                      onClick={() => {
                        let msgs = []
                        if (!currentOutput.extraction_instructions || !currentOutput.data_type || !currentOutput.key) {
                          msgs.push('All fields must be filled in');
                        }

                        if(msgs.length > 0 ) {
                          setErrMsgs(msgs)
                        } else {
                          setErrMsgs([])
                          let updatedInterview = {...interview}
                          if(currentOutput.editIndex >= 0) {
                            let updatedOutputs = [...interview.structuredOutputs];
                            updatedOutputs[currentOutput.editIndex] = {
                              key: currentOutput.key,
                              data_type: currentOutput.data_type,
                              extraction_instructions: currentOutput.extraction_instructions
                            }
                            updatedInterview = {...updatedInterview, structuredOutputs: updatedOutputs}
                          } else {
                            updatedInterview = { ...updatedInterview, structuredOutputs: [...interview.structuredOutputs, {
                              key: currentOutput.key,
                              data_type: currentOutput.data_type,
                              extraction_instructions: currentOutput.extraction_instructions
                            }]}
                          }

                          axios.post(`${proxyEndpoint}/interviews/${interview._id}`, {
                            interview: updatedInterview
                          })
                            .then(response => {
                              setInterview(response.data)
                              setCurrentOutput({
                                key: '',
                                data_type: 'List',
                                extraction_instructions: '',
                                editIndex: -1
                              })
                              setSnackbarMsg('Structured Output changes saved successfully')
                              setOpenSnackbar(true)
                            })
                            .catch(error => {
                              console.error('Failed to update interview structured outputs:', error);
                            });

                          setShowAddOutputInput(false)
                        }
                      }}>
                        Save
                      <ArrowForwardIcon style={{ marginLeft: '8px', fontSize: '16px' }} />
                    </IconButton>
                  </div>
              </DialogActions>
          </Dialog>
          <Dialog
            open={showOutputDeletionConfirmation}
            onClose={() => setShowOutputDeletionConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Structured Output Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this structured output? This action cannot be undone:
                {outputDeletionIndex > -1 &&
                  <p style={{ fontSize: 16, padding: '25px', border: '0.5px solid darkgray', borderRadius: '10px' }}>
                    <b>Key:</b> {interview.structuredOutputs[outputDeletionIndex].key}
                    <br/><br/>
                    <b>Description:</b> {interview.structuredOutputs[outputDeletionIndex].extraction_instructions}
                    <br/><br/>
                    <b>Data Type:</b> {interview.structuredOutputs[outputDeletionIndex].data_type}
                  </p>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="btn outlined" onClick={() => setShowOutputDeletionConfirmation(false)} color="primary">
                Close
              </Button>
              <Button className="btn outlined danger" onClick={() => {
                const tempInterview = { ...interview };
                tempInterview.structuredOutputs.splice(outputDeletionIndex, 1);
                fetch(`${proxyEndpoint}/interviews/${interview._id}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    interview: tempInterview
                  }),
                })
                .then(response => response.json())
                .then(data => {
                  setInterview(data);
                  setSnackbarMsg('Structured Output has been deleted!')
                  setOpenSnackbar(true)
                  console.log('Structured Output deleted successfully');
                })
                .catch(error => {
                  console.error('Error deleting structured output:', error);
                });
                setOutputDeletionIndex(-1)
                setShowOutputDeletionConfirmation(false);
              }} color="primary" autoFocus>
                Delete!
              </Button>
            </DialogActions>
          </Dialog>
        </DndContext>
      </div>
    )
  }

  const renderScorecards = () => {
    return (
      <div>
        {currentScorecards.map((scorecard) => {
          return (
            <>
              <h4>{scorecard.title}</h4>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Skill</TableCell>
                    <TableCell>Feedback</TableCell>
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scorecard.results.map((scorecardItem, index) => (
                    <TableRow key={index}>
                      <TableCell>{scorecardItem.signal}</TableCell>
                      <TableCell>{scorecardItem.feedback}</TableCell>
                      <TableCell>{scorecardItem.score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )
        })}
      </div>
    )
  }

  const deleteInterviewConfiguration = () => {
    return axios.post(proxyEndpoint + '/interviews/delete', { id: interview._id })
    .then(response => {
      navigate('/interviews')
      setSnackbarMsg('Interview configuration has been deleted!')
      setOpenSnackbar(true)
    })
    .catch(err => {
      console.error(err);
      // handle error here
    });
  }

  const onDeleteInterviewConfiguration = () => {
    setShowDeleteModal(true)
  }

  const voiceClicked = (voice) => {
    axios.post(proxyEndpoint + '/interviews/' + interview._id, {
      interview: {
        ...interview,
        voice: voice,
      }
    })
    .then(response => {
      if(response.data.message) {
        setSnackbarMsg(response.data.message);
        setOpenSnackbar(true);
        return;
      }
      setInterview(response.data);
      setCurrentVoice(voice);
      setSnackbarMsg(`Voice has been set to ${voice.voice_name}.`);
      setOpenSnackbar(true);
    })
    .catch(err => {
      console.error(err);
      // handle error here
    });
  }

  const renderSettings = () => {
    return (
      <div style={{ padding: '16px', width: '80%', margin: 'auto '}}>
        <p className="section" style={{ fontSize: '22px', fontWeight: 600, paddingBottom: 0, marginBottom: 0 }}>Settings</p>
        <p style={{ fontSize: '14px', color: '#475467', paddingTop: 0 }}>Update your settings here</p>
        <br/><br/>
        <Grid container spacing={2}>
        {/* Title */}
          <Box className="d-flex flex-col section" style={{ marginLeft: '15px'}}>
            <FormField
              label="Interview Title"
              value={newInterviewTitle}
              style={{ width: '400px'}}
              onChange={(e) => setNewInterviewTitle(e.target.value)}
            />
            <button className="btn-main my-aut`o" style={{ width: 'fit-content' }}
              onClick={() => {
                axios.post(proxyEndpoint + '/interviews/' + interview._id, {
                  interview: {
                    ...interview,
                    title: newInterviewTitle,
                  }
                })
                .then(response => {
                  if(response.data.message) {
                    setSnackbarMsg(response.data.message);
                    setOpenSnackbar(true);
                    return
                  }
                  setInterview(response.data);
                  setSnackbarMsg('Interview title has been updated!');
                  setOpenSnackbar(true);
                })
                .catch(err => {
                  console.error(err);
                  // handle error here
                });
              }}>
                Save
            </button>
          </Box>
          
          <Grid item xs={12}>
            <h3 className="section" style={{ marginBottom: '5px' }}>Interviewer Voice</h3>
            <p className="color-gray" style={{ marginTop: '0px', fontSize: '14px' }}>Change the voice profile of the AI interviewer.</p>
            
            <Grid item xs={4}>
              <VoiceSelector currentVoice={currentVoice} voices={voices} clicked={voiceClicked}></VoiceSelector>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <h3 className="section" style={{ marginBottom: '5px' }}>Default video recorder</h3>
            <p className="color-gray" style={{ marginTop: '0px', fontSize: '14px' }}>You can select which tool you'd like to use as your default recorder. If neither is selected, the built-in option is used.</p>
          </Grid>
          <Grid item xs={12}>
            <Grid container className="video-recorder-select" style={{ width: '100%', borderRadius: '6px', border: '1px solid #EAECF0', padding: '10px', height: '92px', border: videoRecorder === 'loom' ? '1px solid black' : '1px solid #EAECF0' }} onClick={() => {setVideoRecorder('loom')}}>
              <Grid item xs={1}>
                <img src={LoomLogoSvg} style={{ marginTop: '25px' }} />
              </Grid>
              <Grid item xs={10}>
                <Grid item xs={12}>
                  <p style={{ fontSize: '14px', marginTop: '7px', marginBottom: '0px', fontWeight: '600' }}>Loom</p>
                </Grid>
                <Grid item xs={12}>
                  <p style={{ fontSize: '12px', marginTop: '2px', marginBottom: '0px' }}>Record candidate video via Loom</p>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox style={{ marginLeft: 0, paddingLeft: 0 }} checked={videoRecorder === 'loom'}
                    onSelect={() => {setVideoRecorder('loom')}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className="video-recorder-select" style={{ width: '100%', borderRadius: '6px', padding: '10px', height: '92px', border: videoRecorder === 'builtin' ? '1px solid black' : '1px solid #EAECF0' }} onClick={() => {setVideoRecorder('builtin')}}>
              <Grid item xs={1}>
                <img src={CamLogoSvg} style={{ marginTop: '25px' }} />
              </Grid>
              <Grid item xs={10}>
                <Grid item xs={12}>
                  <p style={{ fontSize: '14px', marginTop: '7px', marginBottom: '0px', fontWeight: '600' }}>Built-in</p>
                </Grid>
                <Grid item xs={12}>
                  <p style={{ fontSize: '12px', marginTop: '2px', marginBottom: '0px'  }}>Uses the standard system video recorder</p>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox style={{ marginLeft: 0, paddingLeft: 0 }} checked={videoRecorder === 'builtin'}
                    onSelect={() => {setVideoRecorder('builtin')}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <button className="btn-main my-auto" style={{ width: 'fit-content' }}
              onClick={() => {
                axios.post(proxyEndpoint + '/interviews/' + interview._id, {
                  interview: {
                    ...interview,
                    videoRecorder: videoRecorder,
                  }
                })
                .then(response => {
                  if(response.data.message) {
                    setSnackbarMsg(response.data.message);
                    setOpenSnackbar(true);
                    return
                  }

                  setInterview(response.data);
                  setSnackbarMsg('Interview video recorder has been updated!');
                  setOpenSnackbar(true);
                })
                .catch(err => {
                  console.error(err);
                  // handle error here
                });
              }}>
                Save
            </button>
          </Grid>
        </Grid>
        <br/><br/>

        <Box className="d-flex flex-col section">
          <h3 className="section">Candidate Onboarding Practice Chat</h3>
          <Grid item xs={12}>
            <Grid container className="video-recorder-select" style={{ width: '100%', borderRadius: '6px', padding: '10px', height: '110px', border: practiceChatEnabled ? '1px solid black' : '1px solid #EAECF0' }} onClick={() => {setPracticeChatEnabled(!practiceChatEnabled)}}>
              <Grid item xs={10}>
                <Grid item xs={12}>
                  <p style={{ fontSize: '14px', marginTop: '7px', marginBottom: '0px', fontWeight: '600' }}>Practice Chat Enabled</p>
                </Grid>
                <Grid item xs={12}>
                  <p style={{ fontSize: '12px', marginTop: '2px', marginBottom: '0px'  }}>Runs the candidate through a 30 second practice session with Steve during onboarding before the start of the real interview.</p>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox style={{ marginLeft: 0, paddingLeft: 0 }} checked={practiceChatEnabled}
                    onSelect={() => {setPracticeChatEnabled(!practiceChatEnabled)}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br/>
          <Grid item xs={12}>
            <button className="btn-main my-auto" style={{ width: 'fit-content' }} onClick={() => {
              axios.post(proxyEndpoint + '/interviews/' + interview._id, {
                interview: {
                  ...interview,
                  practiceChatEnabled: practiceChatEnabled,
                }
              })
              .then(response => {
                if(response.data.message) {
                  setSnackbarMsg(response.data.message);
                  setOpenSnackbar(true);
                  return
                }
                setInterview(response.data);
                setSnackbarMsg(`Practice chat has been ${practiceChatEnabled ? 'enabled' : 'disabled'}!`);
                setOpenSnackbar(true);
              })
              .catch(err => {
                console.error(err);
                // handle error here
              });
            }}>
              Save
            </button>
          </Grid>
        </Box>

        <br/><br/>

        <h3 className="section">Custom Instructions</h3>
        <Box className="d-flex flex-col section">
          <FormField
            label="Interview Prompt"
            tooltip
            tooltipTitle={<>
              <p className="m-auto">
                What will be used for conversing with the candidate.
              </p>
            </>}
            value={newPrompt}
            multiline
            minRows={8}
            onChange={(e) => {setNewPrompt(e.target.value)}}
          />
          <button className="btn-main my-auto" style={{ width: 'fit-content' }} onClick={onUpdatePrompt}>
            Save
          </button>
        </Box>

        <Box className="d-flex flex-col section">
          <FormField
            label="Evaluation Prompt"
            tooltip
            tooltipTitle={<>
              <p className="m-auto">
                What will be used for scoring the candidate once the candidate session has ended.
              </p>
            </>}
            value={newEvalPrompt}
            multiline
            minRows={8}
            onChange={(e) => {setNewEvalPrompt(e.target.value)}}
          />
          <button className="btn-main my-auto" style={{ width: 'fit-content' }} onClick={onUpdateEvalPrompt}>
            Save
          </button>
        </Box>

        <Box className="d-flex flex-col section">
          <h3 className="section">Max Interview Length (minutes)</h3>
          <FormField
            label="Max Interview Length"
            value={newInterviewLength}
            style={{ width: '400px' }}
            onChange={(e) => setNewInterviewLength(e.target.value)}
          />
          <button className="btn-main my-auto" style={{ width: 'fit-content' }} onClick={() => {
            axios.post(proxyEndpoint + '/interviews/' + interview._id, {
              interview: {
                ...interview,
                length: newInterviewLength,
              }
            })
            .then(response => {
              if(response.data.message) {
                setSnackbarMsg(response.data.message);
                setOpenSnackbar(true);
                return
              }
              setInterview(response.data);
              setSnackbarMsg('Interview length has been updated!');
              setOpenSnackbar(true);
            })
            .catch(err => {
              console.error(err);
              // handle error here
            });
          }}>
            Save
          </button>
        </Box>

        <button className="btn-main outlined danger" style={{ marginTop: '24px' }}
          onClick={onDeleteInterviewConfiguration}>
          Delete Interview
        </button>
      </div>
    )
  }

  const passSession = (session) => {
    // Check if session has been internally overridden to reject
    if (session.internalOverrideReject) {
      console.log('session has been internally overridden to reject')
      return false;
    }

    if (!session.scorecard || session.scorecard.length === 0 || !session.metadata.interview_config || !session.scorecard) return -1;

    // Check if weights add up to 100, if not, set them to equal weights
    const totalWeightCheck = session.scorecard.reduce((sum, item) => sum + (item.weight || 0), 0);
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      session.scorecard.forEach(item => item.weight = equalWeight);
    }    
    
    let weightedSum = 0;
    let totalWeight = 0;

    session.scorecard.forEach((scorecardItem) => {
      const configItem = session.scorecard.find(item => item.signal === scorecardItem.signal);
      if (configItem) {
        const weight = (configItem.weight / 100) || 0;
        console.log('weight', weight)
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    const avgFixed = weightedAverage.toFixed(1);
    return avgFixed >= 3.0;
  }

  /**
   * Navigates URL to interviews/[_id] slug and sets relevant
   * flags to trigger destination interview re-fetch.
   */
  const navigateAndPopulateInterview = (interviewId) => {
    // set bools within useEffect dependency array to trigger re-fetch
    setGetInterviewCalled(false);
    setGetCompletedSessionsCalled(false);    

    // reset some UI
    setPreviewOpen(false);
    setCurrentPreviewQuestion({});
    setInterviewPreview([]);

    navigate('/interviews/' + interviewId);
  }

  const handleOnCopy = (interviewConfigId) => {
    console.log('handleOnCopy', interviewConfigId)
    navigate(`/create-interview?copy_from=${interviewConfigId}`);
  }

  // Custom "Basic" styling override of MUI Menu component
  const MenuBasic = styled((props) => (
    <Menu
      {...props}
    />
    ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow:
        'none !important',
      },
  }));

  const handleSortCompleted = (sessions, order) => {
    setCompletedOrder(order);
    setOrderBy('completed');

    let sortedSessions = [...sessions];

    sortedSessions.sort((a, b) => {
      const aTimestamp = a.date_completed_unix_timestamp || 0;
      const bTimestamp = b.date_completed_unix_timestamp || 0;
      
      if (order === 'asc') {
        return aTimestamp - bTimestamp;
      } else {
        return bTimestamp - aTimestamp;
      }
    });

    setFilteredSessions(sortedSessions);
  }

  const handleSortInvited = () => {
    let tmpOrder = invitedOrder === 'asc' ? 'desc' : 'asc';
    setInvitedOrder(tmpOrder);
    setOrderBy('invited');

    let sortedSessions = [...filteredSessions];

    sortedSessions.sort((a, b) => {
      const aStatus = a.status;
      const bStatus = b.status;
      const aTimestamp = (a.client_type === 'phone' || a.client_type === 'mobile') 
        ? a.created_at * 1000 
        : new Date(a.created_at.$date).getTime();
      const bTimestamp = (b.client_type === 'phone' || b.client_type === 'mobile') 
        ? b.created_at * 1000 
        : new Date(b.created_at.$date).getTime();
      
      if (tmpOrder === 'asc') {
        // For ascending order, always start with in-progress ones
        if (aStatus === 'Not Completed' && bStatus !== 'Not Completed') return -1;
        if (bStatus === 'Not Completed' && aStatus !== 'Not Completed') return 1;
        return aTimestamp - bTimestamp;
      } else {
        // For descending order
        if (aStatus === 'Not Completed' && bStatus !== 'Not Completed') return 1;
        if (bStatus === 'Not Completed' && aStatus !== 'Not Completed') return -1;
        return bTimestamp - aTimestamp;
      }
    });

    setFilteredSessions(sortedSessions);
  }

  const handleSortStatus = () => {
    let tmpOrder = statusOrder === 'asc' ? 'desc' : 'asc';
    setStatusOrder(tmpOrder);

    let sortedSessions = [];
    var tmpSessions = filteredSessions;

    if (tmpOrder === 'desc') {
      // First, add completed and accepted sessions (excluding voicemail)
      sortedSessions = tmpSessions.filter(session => 
        session.status === 'Completed' && passSession(session) && !session.voicemail_detected
      );

      // Then, add completed and rejected sessions (excluding voicemail)
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(session => 
          session.status === 'Completed' && !passSession(session) && !session.voicemail_detected
        )
      );

      // Then, add voicemail detected sessions
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(session => session.voicemail_detected)
      );

      // Finally, add in-progress sessions
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(session => session.status === 'Not Completed')
      );
    } else {
      // For ascending order
      // First, add in-progress sessions
      sortedSessions = tmpSessions.filter(session => 
        session.status === 'Not Completed'
      );

      // Then, add voicemail detected sessions
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(session => session.voicemail_detected)
      );

      // Then, add completed and rejected sessions (excluding voicemail)
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(session => 
          session.status === 'Completed' && !passSession(session) && !session.voicemail_detected
        )
      );

      // Finally, add completed and accepted sessions (excluding voicemail)
      sortedSessions = sortedSessions.concat(
        completedSessions.filter(session => 
          session.status === 'Completed' && passSession(session) && !session.voicemail_detected
        )
      );
    }

    setFilteredSessions(sortedSessions);
  }

  function interviewSort(a, b) {
    // top_val comes first
    if (a.type && a.type == 'template') return 1;
    if (b.type && b.type == 'template') return -1;
    // neither matches `value` - compare them normally
    else return 0;
  }

  const handleSortScore = () => {
    let tmpOrder = scoreOrder
    if(scoreOrder === 'asc') {
      setScoreOrder('desc')
      tmpOrder = 'desc'
    } else {
      setScoreOrder('asc')
      tmpOrder = 'asc'
    }

    let sortedSessions = []
    completedSessions.map((session, idx) => {
      const isPass = passSession(session)
      if(isPass == null) {
        sortedSessions.push(session)
      }
    })

    completedSessions.map((session, idx) => {
      const isPass = passSession(session)
      if(isPass === false) {
        sortedSessions.push(session)
      }
    })

    completedSessions.map((session, idx) => {
      const isPass = passSession(session)
      if(isPass) {
        sortedSessions.push(session)
      }
    })

    if(tmpOrder === 'desc') {
      sortedSessions.reverse()
    }

    setCompletedSessions(sortedSessions);
  }

  const rerunAnalysis = (session, idx) => {
    console.log('rerunAnalysis', session)
    setRerunningIdxes([...rerunningIdxes, idx])
    api.analyzeSessions({callIds: [session.call_id]})
      .then((response) => {
        setSnackbarMsg('Analysis is currently running. Refresh page to see changes.')
        setOpenSnackbar(true)
        // setRerunningIdxes(rerunningIdxes.filter((index) => index !== idx))
      })
      .catch((error) => {
        setSnackbarMsg('Analysis has failed. Refresh page and try again.')
        setOpenSnackbar(true)
      })    
  }

  const handleCreateInterview = () => {
    navigate('/create-interview')
  }

  const toggleSessionSelected = (session_id) => {
    let selected_sessions = selectedSessions;
    let idx = selected_sessions.findIndex((session) => session == session_id);
    if (idx == -1) {
      setSelectedSessions([...selectedSessions, session_id]);
    } else {
      selected_sessions.splice(idx, 1);
      setSelectedSessions([...selected_sessions]);
    }
  }

  const handleAnalyzeBulk = () => {
    let callIds = [...selectedSessions];
    setRerunningIds([...rerunningIds, ...callIds]);
    setRunningBulk(true);
    api.analyzeSessions({callIds})
      .then((response) => {
        setSnackbarMsg('Analysis has been re-run for ' + `${callIds.length} session${callIds.length > 1 ? 's' : ''}. Refresh page to see changes.`)
        setOpenSnackbar(true)
        setRunningBulk(false);
        setRerunningIds(rerunningIds.filter((id) => !callIds.includes(id)))
      })
      .catch((error) => {
        setSnackbarMsg('Analysis has failed when re-run on ' + `${callIds.length} session${callIds.length > 1 ? 's' : ''}. Refresh page to see changes.`)
        setOpenSnackbar(true)
        setRunningBulk(false);
      })
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEndSessionSpecific}
      sensors={sensors}
    >
    <div className="w-100">
      <Box sx={{display: 'flex'}}>
        <Drawer
          variant="permanent"
          sx={{
            width: 312,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              backgroundColor: 'var(--color-dark-mode)', width: 312, zIndex: "var(--zIndex-canvas)" },
          }}
        >
          <button
            className="btn-main btn-dark-mode btn-logo d-flex"
            style={{ height: "fit-content", backgroundColor: 'var(--color-dark-mode)', padding: '8px 12px' }}
            aria-label="menu"
            onClick={() => {
              if (location.pathname != "/your-team") navigate("/interviews");
            }}
          >
            <img className="mr-auto" alt="Wayfaster" style={{ height: '32px' }} src={WayfasterLogo} />
          </button>

          <div style={{ marginRight: '12px', marginLeft: '12px' }}>
            <Switcher currentTeam={team} teams={teams} popper={true} clicked={teamClicked}></Switcher>
          </div>

          <button
          className="btn-main btn-dark-mode d-flex"
          style={{
            margin: "8px 12px 0px",
            padding: '16px 12px',
            borderRadius: '8px',
            backgroundColor: 'transparent',
            border: '1px solid #3B3B3B',
            width: 'calc(100% - 24px)',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '56px',
            boxSizing: 'border-box',
            outline: 'none', // Remove default focus outline
            boxShadow: '0 0 0 1px #3B3B3B' // Add a white box shadow to create a visible border
          }}
            onClick={() => {
              navigate('/create-interview')
            }}
          >
            <span style={{ color: '#fff', fontSize: '14px', fontWeight: 600 }}>New Interview</span>
            <Add sx={{ color: '#fff', fontSize: '18px' }} />
          </button>               

          <div 
            className="d-flex flex-row clickable" 
            style={{ 
              margin: "8px 12px 0px", 
              padding: '12px', 
              borderRadius: '8px', 
              backgroundColor: 'var(--color-dark-mode)',
              ':hover': {
                backgroundColor: 'var(--color-hover-dark)'
              }
            }}
            onClick={() => navigate('/getting-started')}>
            <PlayArrowIcon className="my-auto" sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }}></PlayArrowIcon>
            <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Getting Started / Help Videos</h4>
          </div>    

          <div className="d-flex flex-row clickable" style={{ margin: "8px 12px 0px", padding: '12px', borderRadius: '8px', backgroundColor: 'var(--color-hover-dark)' }}
            onClick={() => setInterviewAccordionOpen((prev) => !prev)}>
            <PeopleOutlinedIcon className="my-auto" sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }}></PeopleOutlinedIcon>
            <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Interviews</h4>
            <ExpandMoreIcon fontSize="14px" className="my-auto" style={{ transition: 'all .3s ease', marginLeft: 'auto', color: '#fff', transform: interviewAccordionOpen ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
          </div>
          {!getInterviewsCalled && <CircularProgress size={20} className="mx-auto" sx={{ color: 'var(--color-primary)', marginTop: '16px' }}/>}
          {interviews.length > 0 &&
            <>
            <Scrollbars renderThumbVertical={thumbVDark} style={{ transition: 'all .3s ease', flex: interviewAccordionOpen ? 5 : 0, scrollbarColor: 'rgba(255, 255, 255, 0.2) var(--color-dark-mode)' }}>
              <Box sx={{ overflow: 'hidden', padding: '0px 12px', boxShadow: 'inset -8px -12px 48px -8px var(--color-dark-mode)' }}>
                <List style={{ paddingTop: '0px' }} className="interview-list-wrapper">
                {interviews.length === 0 ?
                  <div style={{ margin: 'auto'  }}>
                    <p style={{ fontSize: '12px', color: 'var(--color-dark-mode-light)' }}>No interviews to show</p>
                  </div>
                  : interviews.sort(interviewSort).map((interview) => {
                  return (
                      <ListItem
                        className={id === interview._id ? 'interview-list-item-selected' : "interview-list-item" }
                        style={{
                          padding: '4px 8px',
                          borderRadius: 0,
                          height: 'fit-content',
                          margin: '4px 0px',
                          width: '264px'
                        }}
                        onClick={() => {
                          navigateAndPopulateInterview(interview._id)
                        }}
                      >
                        <div class="d-flex flex-row" style={{ width: '100%' }}>
                          <div className="d-flex" style={{ flex: 11 }}>
                            <p style={{ lineHeight: '18px', fontSize: '14px', margin: '8px 4px', fontWeight: 600, color: id === interview._id ? '#fff' :  'var(--color-dark-mode-light)'}}>
                              {interview.type && interview.type == 'template' && <Circle className="m-auto color-mid" style={{ fontSize: 8, marginRight: '4px' }}/>}
                              {interview.title}
                            </p>
                          </div>
                          <div className="d-flex" style={{ marginLeft: 'auto', flex: 1 }}>
                            <button className="btn-main small d-flex gray-dark outlined my-auto"
                              style={{ marginLeft: 'auto', width: '28px', height: '28px' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleOnCopy(interview._id)}
                              }>
                              <ContentCopyOutlined className="m-auto" sx={{ fontSize: '18px', color: 'var(--color-dark-mode-light)' }}></ContentCopyOutlined>
                            </button>
                          </div>
                        </div>
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
            </Scrollbars>
            </>
          }

          <Box style={{ flex: 3 }}>
            {/* placeholder */}
          </Box>
          
          {(user.email === 'varun@techinterviewer.ai' || user.email === 'shreyas@techinterviewer.ai' || user.email === 'shreyas.jaganmohan@gmail.com' || user.email == 'miguelpatricktaruc@gmail.com') &&
            <div className="d-flex flex-row" style={{ width: "100%", marginTop: '8px' }}>
              <TextField 
                variant="outlined"
                sx={{
                  margin: 'auto',
                  flex: 2,
                  '& .MuiOutlinedInput-input': {
                    height: '20px', 
                    padding: '8px 12px',
                    fontSize: '14px',
                    margin: 'auto'
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  style: 
                    { border: '1px solid var(--color-hover-dark)',
                    borderRadius: '6px', backgroundColor: 'var(--color-main)', color: 'white' }
                }}
              style={{ color: 'white', width: 'fit-content', fontSize: '14px', margin: '10px' }} 
              value={globalOverrideUser} onChange={(e) => {setGlobalOverrideUser(e.target.value)}} />
              <button className="btn-main btn-dark-mode my-auto" onClick={() => {assumeUser()}} 
                style={{ fontSize: '12px', flex: 1, height: '36px', marginRight: '8px' }}>
                Assume user
              </button>
            </div>
          }

          
        {user && isAuthenticated && (
          <>
            <button
              className="btn-main btn-dark-mode btn-logo d-flex"
              style={{ height: "fit-content", backgroundColor: 'var(--color-dark-mode)', padding: '8px 12px' }}
              aria-label="menu"
              onClick={() => {
                  navigate("/settings");
              }}>
              <SettingsIcon className="my-auto" style={{ marginRight: '8px', fontSize: '18px', color: 'var(--color-dark-mode-light)' }}></SettingsIcon>
                          
              <p style={{ lineHeight: '20px', fontSize: '14px', margin: '8px 4px', fontWeight: 600, color: 'var(--color-dark-mode-light)' }}>
                Settings
              </p>
            </button>
            
            <div className="d-flex flex-row mx-auto" style={{ padding: '8px', width: '100%' }}>
              <button
                className="btn-main btn-dark-mode d-flex flex-row"
                style={{ height: "40px", width: '100%' }}
                ref={accountRef}
                onClick={handleAccountToggle}
              >
                <Avatar
                  className="my-auto"
                  sx={{
                    bgcolor: "var(--color-primary)",
                    color: "black",
                    width: 32,
                    height: 32,
                    textTransform: "capitalize",
                    fontSize: "16px",
                    marginTop: "-2px",
                    marginRight: "6px",
                  }}
                >
                  {Array.from(user.email)[0]}
                </Avatar>
                <h6
                  className="color-main my-auto"
                  style={{ marginRight: "4px", fontSize: "12px", color: "#fff" }}
                >
                  {user.email}
                </h6>
                <UnfoldMore className="color-gray my-auto" style={{ marginLeft: 'auto', fontSize: '24px' }} />
              </button>
            </div>
          </>
        )}

        <Popper
          open={accountOpen}
          anchorEl={accountRef.current}
          role={undefined}
          placement="bottom-end"
          style={{ width: "250px", zIndex: 9999 }}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleAccountClose}>
                  <MenuList
                    autoFocusItem={accountOpen}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    sx={{
                      ["& .MuiMenuItem-root"]: {
                        fontSize: "14px",
                      },
                      ["& .MuiTypography-root"]: {
                        fontSize: "14px",
                      },
                    }}
                  >
                      <>
                          <MenuItem
                          onClick={() => {
                              localStorage.removeItem("teamId");
                              localStorage.removeItem("globalOverrideUser");
                              logout({ returnTo: "/" });
                          }}
                          >
                          <ListItemIcon>
                              <LogoutIcon
                              className="color-danger"
                              fontSize="14px"
                              />
                          </ListItemIcon>
                          <ListItemText className="color-danger">
                              Logout
                          </ListItemText>
                          </MenuItem>
                      </>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </Drawer>

        {!interview.title &&
          <Box style={{ padding: 25, background: 'white', borderRadius: 6, width: '70%', margin: 'auto', marginTop: '100px' }}>
            {interviews.length === 0 &&
              <>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="457px" style={{ borderRadius: '8px', background: '#F7F7F7', marginLeft: '15px' }}>
                  <AssignmentIcon style={{ fontSize: '92px', color: 'gray' }} />
                  <p style={{ color: '#ACACAC', fontSize: '18px', fontFamily: 'PPNeueMontreal', weight: 600, marginBottom: 0 }}>
                    You haven't created any interviews yet.
                  </p>
                  <p style={{ color: '#ACACAC', fontSize: '14px', fontWeight: 400, fontFamily: 'PPNeueMontreal', marginBottom: '25px'}}>
                    Get started by creating a new interview below.
                  </p>
                  <button className="btn-main" style={{ fontSize: '12px' }} onClick={handleCreateInterview}>
                    Get started
                  </button>
                </Box>
              </>
            }
          </Box>
        }


        {interview.title &&
          <>
          <Box style={{ background: '#FAFAFA', width: '100%', margin: 'auto', paddingBottom: '96px' }}>
            {/* Interview Header */}
            <Box style={{ width: '100%', background: 'white', padding: '24px 32px 16px 32px' }}>
              <div className="d-flex flex-row" style={{ width: '100%' }}>
                <Box className="d-flex" style={{ background: 'white' }}>
                  <Box className="d-flex flex-col">
                    <Box className="d-flex flex-row">
                      <h3 className="my-auto" style={{ marginRight: '8px' }}>{interview.title}</h3>
                      { interview.type && interview.type == 'template' ? <ChipTag variant="action" text="Template" /> : <ChipTag variant="in-progress" text="In Progress" /> }
                      
                    </Box>
                    <h5 className="color-gray my-auto" style={{ fontWeight: 500, marginTop: '6px' }}>
                      {new Date(interview.createdAt).toDateString()}
                    </h5>
                  </Box>
                </Box>

                {/* Interview Previous / Next */}
                <div className="d-flex flex-row"  style={{ marginLeft: 'auto', height: '24px' }}>
                  <div className="d-flex flex-row" style={{ marginLeft: 'auto' }}>
                    <button className={`btn-main small d-flex gray outlined ${previousInterviewId ? "" : "inactive"}`}
                      disabled={!previousInterviewId}
                      onClick={() => {navigateAndPopulateInterview(previousInterviewId)}}>
                      <NavigateBefore fontSize="14px" className="color-heading m-auto" />
                    </button>
                    <h5
                      onClick={() => {navigateAndPopulateInterview(previousInterviewId)}}
                      className={[ previousInterviewId ? "active-link" : "inactive" ]} style={{ margin: 'auto 8px', lineHeight: '20px' }}>Previous Interview</h5>

                    <h5 className="inactive" style={{ margin: 'auto 4px', lineHeight: '20px' }}>|</h5>

                    <h5
                      onClick={() => {navigateAndPopulateInterview(nextInterviewId)}}
                      className={[ nextInterviewId ? "active-link" : "inactive" ]} style={{ margin: 'auto 8px', lineHeight: '20px' }}>Next Interview</h5>

                    <button className={`btn-main small d-flex gray outlined ${nextInterviewId ? "" : "inactive"}`}
                      disabled={!nextInterviewId}
                      onClick={() => {navigateAndPopulateInterview(nextInterviewId)}}>
                      <NavigateNext fontSize="14px" className="color-heading m-auto" />
                    </button>
                  </div>
                </div>
              </div>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', background: 'white' }}>
              <Tabs value={currentTab} onChange={handleTabChange} disableRipple
                style={{ padding: '0px 32px', background: 'white', }}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#0D0E14',
                  }
                }}>
                <Tab label="Sessions" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === 0 ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === 0 ? 'black': 'darkgray', zIndex: currentTab === 0 ? 10 : 0 }} />
                <Tab label="Questions" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === 1 ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === 1 ? 'black' : 'darkgray', zIndex: currentTab === 1 ? 10 : 0 }} />
                <Tab label="Scorecard" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === 2 ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === 2 ? 'black' : 'darkgray', zIndex: currentTab === 2 ? 10 : 0 }} />
                <Tab label="Structured Outputs" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === 3 ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === 3 ? 'black' : 'darkgray', zIndex: currentTab === 3 ? 10 : 0 }} />
                <Tab label="Settings" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === 4 ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === 4 ? 'black' : 'darkgray', zIndex: currentTab === 4 ? 10 : 0 }} />
              </Tabs>
            </Box>

            <Box style={{ margin: '24px 32px', background: 'white', border: '1px solid lightgray', borderRadius: '12px' }}>
              {currentTab === 0 &&
                <>
                  {(!interview.type || interview.type != 'template') && <div className="d-flex flex-row" style={{ padding: '12px 16px', borderBottom: '1px solid lightgray', marginBottom: 0 }}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Button
                        onClick={() => {
                          setFilteredSessions(completedSessions);
                          setSessionFilter(null);
                          
                          if (completedOrder === 'desc') {
                            handleSortCompleted(completedSessions, completedOrder);
                          } else if (invitedOrder === 'desc') {
                            handleSortInvited();
                          } else if (statusOrder === 'desc') {
                            handleSortStatus();
                          }
                        }}
                        style={{ 
                          backgroundColor: sessionFilter === null ? 'var(--color-lightgray)' : '#fff', 
                          borderRadius: '8px', 
                          fontSize: '14px', 
                          fontWeight: '600', 
                          padding: '4px 8px', 
                          textTransform: 'none',
                          color: 'var(--color-main)'
                        }}
                        disabled={completedSessions.length === 0}
                      >
                        All Sessions ({completedSessions.length})
                      </Button>
                      <Button
                        onClick={() => {
                          const filtered = completedSessions.filter(session => 
                            session.status === 'Completed' && 
                            (passSession(session) || session.customerOverrideCandidateAction === 'accept') &&
                            !session.voicemail_detected
                          );
                          setFilteredSessions(filtered);
                          setSessionFilter('accept');
                        }}
                        style={{ 
                          backgroundColor: sessionFilter === 'accept' ? 'var(--color-accept-bg)' : '#fff', 
                          borderRadius: '8px', 
                          fontSize: '14px', 
                          fontWeight: '600', 
                          padding: '4px 8px', 
                          textTransform: 'none',
                          color: 'var(--color-main)'
                        }}
                        disabled={completedSessions.length === 0}
                      >
                        
                        <Circle className="m-auto color-completed" style={{ fontSize: 8, marginRight: '4px' }}/>
                        Accepted ({completedSessions.length > 0 ? completedSessions.filter(session => 
                          session.status === 'Completed' && 
                          (passSession(session) || session.customerOverrideCandidateAction === 'accept') &&
                          !session.voicemail_detected
                        ).length : 0})
                      </Button>
                      <Button
                        onClick={() => {
                          const filtered = completedSessions.filter(session => 
                            session.status === 'Completed' && (!passSession(session) && session.customerOverrideCandidateAction !== 'accept' && !session.voicemail_detected)
                          );
                          setFilteredSessions(filtered);
                          setSessionFilter('reject');
                        }}
                        style={{ 
                          backgroundColor: sessionFilter === 'reject' ? 'var(--color-reject-bg)' : '#fff', 
                          borderRadius: '8px', 
                          fontSize: '14px', 
                          fontWeight: '600', 
                          padding: '4px 8px', 
                          textTransform: 'none',
                          color: 'var(--color-main)'
                        }}
                        disabled={completedSessions.length === 0}
                      >
                        <Circle className="m-auto color-reject" style={{ fontSize: 8, marginRight: '4px' }}/>
                        Rejected ({completedSessions.length > 0 ? completedSessions.filter(session => session.status === 'Completed' && (!passSession(session) && session.customerOverrideCandidateAction !== 'accept' && !session.voicemail_detected)).length : 0})
                      </Button>
                      <Button
                        onClick={() => {
                          const filtered = completedSessions.filter(session => session.status !== 'Completed' || session.voicemail_detected);
                          setFilteredSessions(filtered);
                          setSessionFilter('in-progress');
                        }}
                        style={{ 
                          backgroundColor: sessionFilter === 'in-progress' ? 'var(--color-progress-bg)' : '#fff', 
                          borderRadius: '8px', 
                          fontSize: '14px', 
                          fontWeight: '600', 
                          padding: '4px 8px', 
                          textTransform: 'none',
                          color: 'var(--color-main)'
                        }}
                        disabled={completedSessions.length === 0}
                      >
                        <Circle className="m-auto color-progress" style={{ fontSize: 8, marginRight: '4px' }}/>
                        In Progress ({completedSessions.length > 0 ? completedSessions.filter(session => session.status !== 'Completed' || session.voicemail_detected).length : 0})
                      </Button>
                    </div>
                    <button disabled={completedSessions.length === 0} className="btn-main btn-text d-flex" 
                      style={{ marginLeft: 'auto', marginBottom: 0, padding: '6px 9.24px 6px 8px', fontWeight: '700 !important', fontSize: '14px' }}
                      onClick={handleExport}>
                        <FileDownloadIcon className="my-auto" sx={{ fontSize: '16px', marginRight: '4px' }}></FileDownloadIcon> Export
                    </button>                    
                    <button className="btn-main outlined gray d-flex" 
                      style={{ marginLeft: '12px', marginBottom: 0, padding: '6px 12px', fontWeight: 600, fontSize: '14px' }}
                      onClick={handleBulkCandidateLink}>
                        Bulk Upload
                    </button>
                    {ANALYSIS_ACCESS.includes(user.email) && <button className="btn-main outlined gray d-flex" 
                      disabled={runningBulk || selectedSessions.length === 0}
                      style={{ marginLeft: '12px', marginBottom: 0, padding: '6px 12px', fontWeight: 600, fontSize: '14px' }}
                      onClick={handleAnalyzeBulk}>
                        Bulk Re-run Analysis
                    </button>}                    
                    <button className="btn-main d-flex" 
                      style={{ marginLeft: '12px', marginBottom: 0, padding: '6px 9.24px 6px 8px', fontWeight: 600, fontSize: '14px' }}
                      onClick={handleCreateCandidateLink}>
                        <Add fontSize="14px" className="my-auto" sx={{ marginRight: '2px' }}></Add> Add Candidate
                    </button>
                  </div>}
                  {completedSessions.length === 0 &&
                    <>
                      {(interview.type == 'template') ?
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="921px" style={{ textAlign: 'center', margin: 'auto', marginTop: '50px', marginBottom: '50px' }}>
                          <p className="color-gray" style={{ fontSize: '18px', fontWeight: '600', marginBottom: 0 }}>Templates do not use sessions.</p>
                        </Box>
                        :
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="921px" style={{ textAlign: 'center', margin: 'auto', marginTop: '50px', marginBottom: '24px' }}>
                          <img src={CreateSession} />
                          <p style={{ fontSize: '20px', fontWeight: '600', marginBottom: 0 }}>Create your first session</p>
                          <p style={{ fontSize: '16px', fontWeight: '500', marginTop: '20px', width: '521px' }}>Take the first step towards a successful hiring process by creating a new candidate session link.</p>
                          <Grid container spacing={2} style={{ textAlign: 'left', background: '#ECFCCB', borderRadius: '12px', width: '642px', marginTop: '25px', height: '120px', padding: '0px 15px 15px 15px' }}>
                            <Grid item xs={10}>
                              <p style={{ fontSize: '12px', lineHeight: '18px', fontWeight: '600' }}>Automatically personalize each candidate's interview! &nbsp; 🌟</p>
                              <p style={{ fontSize: '12px', lineHeight: '18px' }}>You can now generate specific questions based on the candidate's experience and background, making interview process more insightful.</p>
                            </Grid>
                            <Grid item xs={2}>
                              <Button className="btn-main" style={{ width: '92px', height: '44px', fontSize: '14px', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', marginTop: '25px' }} onClick={handleCreateCandidateLink}>
                                Get started
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      }
                    </>
                  }                  
                  {completedSessions.length > 0 &&
                  <>
                  <TableContainer style={{ overflowX: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{
                            '& .MuiTableCell-root': {
                              lineHeight: '20px',
                              backgroundColor: '#FAFAFA',
                              color: 'var(--color-heading)',
                              padding: '12px 16px'
                            }
                          }}>
                          {ANALYSIS_ACCESS.includes(user.email) && 
                            <TableCell sx={{
                              width: '42px'
                            }}>
                              <Checkbox style={{ marginLeft: 0, paddingLeft: 0 }} 
                                checked={selectedSessions.length === filteredSessions.filter(session => session.scorecard && Object.keys(session.scorecard).length > 0).length}
                                onClick={() => {
                                  if (selectedSessions.length < filteredSessions.length) {
                                    const validSessions = filteredSessions.filter(session => session.scorecard && Object.keys(session.scorecard).length > 0);
                                    setSelectedSessions(validSessions.map((session) => session.call_id));
                                  } else {
                                    setSelectedSessions([]);
                                  }
                                }}
                              />
                            </TableCell>
                          }
                          <TableCell>
                            Candidate Name
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={true}
                              direction={statusOrder}
                              onClick={() => handleSortStatus()}
                            >
                              Status
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            Score
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={true}
                              direction={invitedOrder}
                              onClick={() => handleSortInvited()}
                            >
                              Invited
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={true}
                              direction={completedOrder}
                              onClick={() => handleSortCompleted(filteredSessions, completedOrder === 'asc' ? 'desc' : 'asc')}
                            >
                              Completed
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            Quick Actions
                          </TableCell>
                          {ANALYSIS_ACCESS.includes(user.email) ?
                            <TableCell>
                              Re-run Analysis
                            </TableCell>
                          :
                          <TableCell>
                          </TableCell>}
                          {ANALYSIS_ACCESS.includes(user.email) &&
                            <TableCell>
                              Call Candidate's Phone
                            </TableCell>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredSessions.map((session, idx) => {
                          return (
                            <TableRow 
                              className={`clickableRow ${selectedSessions.includes(session._id.$oid) ? `clickableRowSelected` : ''}`}
                              key={idx}
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(`/interviews/${interview._id}/sessions/${session._id.$oid}`, '_blank');
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {ANALYSIS_ACCESS.includes(user.email) && 
                                <TableCell sx={{
                                  width: '50px'
                                }}>
                                  <Checkbox style={{ marginLeft: 0, paddingLeft: 0 }} 
                                    checked={selectedSessions.includes(session.call_id)}
                                    disabled={!session.scorecard || Object.keys(session.scorecard).length === 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      if (session.scorecard && Object.keys(session.scorecard).length > 0) {
                                        toggleSessionSelected(session.call_id);
                                      }
                                    }}
                                  />
                                </TableCell>
                              }
                              <TableCell>
                                {session.candidate_metadata &&
                                  <>
                                    {session.candidate_metadata.first_name + ' ' + session.candidate_metadata.last_name}
                                  </>
                                }
                                {!session.candidate_metadata &&
                                  <>
                                    N/A
                                  </>
                                }
                              </TableCell>
                              <TableCell>
                                {session.voicemail_detected ?
                                  <ChipTag variant="default" text="Voicemail" />
                                :
                                <>
                                  {session.status === 'Not Completed' &&
                                    <ChipTag variant="in-progress" text="In Progress" />
                                  }
                                  {session.status === 'Completed' &&
                                    <ChipTag variant="completed" text="Completed" />
                                  }                                
                                </>
                                }
                              </TableCell>
                              <TableCell>
                                {!session.voicemail_detected && session.status === 'Completed' ?
                                    ((session.customerOverrideCandidateAction === 'accept' || passSession(session)) ?
                                    <ChipTag variant="accept" text="Accept" /> : <ChipTag variant="reject" text="Reject" />)
                                    :
                                    <ChipTag variant="pending" text="Pending" />
                                }
                                {session.potentialCheating &&
                                  <ChipTag variant="danger" text="Suspicious Activity" style={{ marginLeft: '8px', backgroundColor: '#FF4D4D', color: 'white' }} />
                                }                                
                              </TableCell>
                              <TableCell>
                                {session.created_at.$date ? new Date(session.created_at.$date).toLocaleString() : new Date(session.created_at * 1000).toLocaleString()}
                              </TableCell>
                              <TableCell>
                              {session.date_completed_unix_timestamp ? 
                                new Date(session.date_completed_unix_timestamp).toLocaleString() 
                                : 
                                'N/A'
                              }
                              </TableCell>
                              <TableCell>
                                <button className="btn-main d-flex gray outlined my-auto"
                                  ref={shareRef.current}
                                  style={{ margin: 'auto 12px' }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleListShareToggle(e, idx);
                                  }}>
                                  <Share fontSize="14px" className="color-heading m-auto" />
                                </button>

                                {/* Share Actions */}
                                <Popper
                                  open={shareOpenIdx == idx}
                                  anchorEl={quickActionsAnchorEl}
                                  role={undefined}
                                  placement="bottom-end"
                                  transition>
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                      {...TransitionProps}
                                      style={{
                                        transformOrigin: 'right top',
                                      }}
                                    >
                                      <Paper>
                                        <ClickAwayListener onClickAway={handleListShareClose}>
                                          <MenuList
                                            autoFocusItem={shareOpenIdx == idx}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            sx={{
                                              ['& .MuiMenuItem-root'] : {
                                                fontSize: '14px'
                                              },
                                              ['& .MuiTypography-root'] : {
                                                fontSize: '14px'
                                              }
                                            }}
                                            onKeyDown={handleListKeyDown}
                                          >
                                            <MenuItem onClick={(e) => {
                                                  e.preventDefault()
                                                  e.stopPropagation()
                                                  navigator.clipboard.writeText('https://beta.techinterviewer.ai?session=' + session.unique_session_id).then((val) => {
                                                    setSnackbarMsg('Web link copied to clipboard successfully')
                                                    setOpenSnackbar(true)
                                                    setCopied(true)
                                                    handleListShareClose()
                                                  })
                                                  .catch((err) => {console.error(err)})
                                                }}>
                                              <ListItemIcon>
                                                <ComputerIcon fontSize="14px"/>
                                              </ListItemIcon>
                                              <ListItemText>
                                                Copy Web Link
                                              </ListItemText>
                                            </MenuItem>
                                            {session.client_type && (session.client_type === 'phone' || session.client_type === 'mobile') && <MenuItem onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    navigator.clipboard.writeText('https://beta.techinterviewer.ai/phone?session=' + session.unique_session_id).then((val) => {
                                                      setSnackbarMsg('Phone link copied to clipboard successfully')
                                                      setOpenSnackbar(true)
                                                      setCopied(true)
                                                      handleListShareClose()
                                                    })
                                                    .catch((err) => {console.error(err)})
                                                  }
                                                }>
                                              <ListItemIcon>
                                                <PhoneIcon fontSize="14px"/>
                                              </ListItemIcon>
                                              <ListItemText>
                                                Copy Phone Link
                                              </ListItemText>
                                            </MenuItem>}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>
                              </TableCell>
                              {(ANALYSIS_ACCESS.includes(user.email) && session.call_id) &&
                                <TableCell>
                                  <button className="btn-main" onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    rerunAnalysis(session, idx)
                                  }}
                                  disabled={rerunningIdxes.includes(idx) || rerunningIds.includes(session._id.$oid) || 
                                    session.running_summary_analysis || session.running_structured_outputs_analysis || session.running_scoring_analysis}
                                  >
                                    {(rerunningIdxes.includes(idx) || rerunningIds.includes(session._id.$oid) || 
                                      session.running_summary_analysis || session.running_structured_outputs_analysis || session.running_scoring_analysis) 
                                      ? 'Running...' : 'Re-run analysis'}
                                  </button>
                                </TableCell>
                              }
                              {(!ANALYSIS_ACCESS.includes(user.email) || !session.call_id) &&
                                <TableCell>
                                </TableCell>
                              }
                              <TableCell>
                                {session.candidate_metadata && session.candidate_metadata.phone && /^\+\d{11}$/.test(session.candidate_metadata.phone) && (
                                  <button className="btn-main" onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setSnackbarMsg('Calling their phone now, please wait a moment...');
                                    setOpenSnackbar(true);
                                    
                                    fetch(`https://retell-main-proxy-69440b772c45.herokuapp.com/register-phone-call`, {
                                      method: 'POST',
                                      headers: {
                                          'Content-Type': 'application/json',
                                      },
                                      body: JSON.stringify({ sessionId: session.unique_session_id }),
                                      })
                                      .then(response => response.json())
                                      .then(data => {
                                          setSnackbarMsg('Called phone successfully, they will be notified shortly.');
                                          setOpenSnackbar(true);
                                      })
                                      .catch(error => {
                                          console.error('Error starting phone session:', error);
                                          setSnackbarMsg('Error calling their phone, please try again.');
                                          setOpenSnackbar(true);
                                      });                                    
                                  }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <PhoneIcon sx={{ fontSize: 16, marginRight: '4px' }} />
                                      <span>Call</span>
                                    </div>
                                  </button>
                                )}
                              </TableCell>                              
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              }
                </>
              }
              {currentTab === 1 &&
                renderQuestions()
              }
              {currentTab === 2 &&
                renderScorecard()
              }
              {currentTab === 3 &&
                renderOutputs()
              }
              {currentTab === 4 &&
                renderSettings()
              }
            </Box>
          </Box>
          
            
        </>
        }
        {/* Simulated Interview Preview */}
        {currentTab === 1 && <div className="d-flex flex-col" 
          style={{ flex: 1, borderRadius: '12px', position: 'fixed', right: '24px', bottom: '24px', 
            height: previewOpen ? 'calc(100vh - 112px)' : '70px',
            border: '1px solid lightgray', padding: '24px', background: '#fff', width: '400px' }}>
            {!previewOpen ? 
              <>
                <h4 style={{ margin: 0 }}>
                  Simulated interview preview
                </h4>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setCurrentPreviewQuestion(interview.questions[0]);
                    setSelectedPreview(interview.questions[0])
                    setPreviewOpen(true);
                  }}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    top: 20,
                  }}
                >
                  <OpenInFull style={{ fontSize: '16px' }} />
                </IconButton>
              </>
            :
            <>
              <div className="d-flex flex-row">
                <h3 className="section-heading" style={{ marginTop: 0 }}>
                  Simulated interview preview
                </h3>
                <IconButton
                  aria-label="close"
                  onClick={() => setPreviewOpen(false)}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    top: 16,
                  }}
                >
                  <CloseFullscreen style={{ fontSize: '16px' }} />
                </IconButton>
              </div>
              <p className="color-gray section" style={{ marginTop: 0, fontSize: '14px' }}>The simulated interview below outlines what a potential interaction might look like, based on the topic and depth you've selected/created.</p>
                
              
              <FormSelect
                  label="Question"
                  variant="outlined"
                  value={currentPreviewQuestion.question}
                  onChange={(e) => {
                    let text = e.target.value;
                    let sel = interview.questions.find((question) => question.question == text)
                    setSelectedPreview(sel);
                  }}
                  >
                  {interview.questions.map((question) => {
                    return <MenuItem key={question.id} value={question.question}>
                      {truncate(question.question)}
                    </MenuItem>
                  })}
                  
              </FormSelect>
              <div style={{ overflow: 'auto' }}>
                <SimulatedPreview
                  loading={loadingPreview}
                  preview={interviewPreview}
                ></SimulatedPreview>
              </div>
              </>
            }
        </div>}
      </Box>
      <Dialog open={showDeleteModal}>
        <DialogTitle>Delete {interview.title}</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this interview configuration?
        </DialogContent>
        <DialogActions>
          <button onClick={() => setShowDeleteModal(false)} className="btn-main outlined">
              No, Cancel
          </button>
          <button onClick={() => deleteInterviewConfiguration()} className="btn-main danger outlined">
              Yes, Delete!
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={showSummary}>
        <DialogTitle>Summary</DialogTitle>
        <DialogContent>
          <p>
            {currentSummary}
          </p>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowSummary(false)}>
                Close
            </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showStructuredOutputs}>
          <DialogTitle>Structured Outputs</DialogTitle>
          <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentStructuredOutputs &&
              <pre>{JSON.stringify(currentStructuredOutputs, null, 2)}</pre>
              }
            </TableBody>
          </Table>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => setShowStructuredOutputs(false)}>
                  Close
              </Button>
          </DialogActions>
      </Dialog>
      <Dialog open={showVideoRecording}>
        <DialogTitle>Video Recording</DialogTitle>
        <DialogContent>
          <video src={''}></video>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setShowVideoRecording(false)}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showMessages}>
          <DialogTitle>Transcript</DialogTitle>
          <DialogContent>
            <Grid container>
              {currentMessages.map((msg) => {
                return (
                  <Grid item xs={12}>
                    <p>{msg.message.role === 'assistant' ? <b>Wayfaster</b> : <b>Candidate</b>} &nbsp;&nbsp;&nbsp;&nbsp; {new Date((msg.createdAt - currentMessages[0].createdAt)*1000).toISOString().substr(11, 8)}</p>
                    <p>{msg.message.content}</p>
                  </Grid>
                )
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => setShowMessages(false)}>
                  Close
              </Button>
          </DialogActions>
      </Dialog>
      <Dialog open={showCandidateLinkCreation}
        onClose={() => {
          setCopied(false);
          setShowCandidateLinkCreation(false);
          setGenerateSessionQuestions([])
          setUniqueSessionLink('')
          setCreateSessionInputs({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            callType: 'web'
          })
          setCreateCandidateSessionActiveStep(0)
          setSessionSpecificContext('')
          setSessionSpecificQuestionsStarted(false)
        }}>
          <DialogTitle style={{ borderBottom: '0.5px solid lightgray' }}>
            <Stepper activeStep={createCandidateSessionActiveStep}>
              {createCandidateSessionSteps.map((label, index) => {
                const stepProps= {};
                const labelProps = {};
                if (isCreateCandidateSessionStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isCreateCandidateSessionStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconComponent={() => {
                        return (
                          <div style={{
                            backgroundColor: createCandidateSessionActiveStep >= index ? 'black' : 'lightgray',
                            color: 'white',
                            borderRadius: '50%',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            {index + 1}
                          </div>
                        )
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </DialogTitle>
          <DialogContent style={{ maxWidth: '600px' }}>
            <div style={{ marginTop: 25, maxWidth: '600px' }}>
              {renderActiveStepComponent()}
            </div>
          </DialogContent>
          <DialogActions>
            {(createCandidateSessionActiveStep === 0 && generatedSessionQuestions.length > 0) &&
              <button className="btn-main" disabled={generateSessionSpecificQuestionsLoading} onClick={() => {setCreateCandidateSessionActiveStep(createCandidateSessionActiveStep + 1)}}>
                Next
              </button>
            }
          </DialogActions>
      </Dialog>

      <Dialog open={showBulkCandidateLinkCreation}
        maxWidth="lg"
        onClose={() => {
          setCopied(false);
          setShowBulkCandidateLinkCreation(false);
          setUniqueSessionLink('')
          setCreateSessionInputs({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            callType: 'web'
          })
          setCreateBulkCandidateSessionActiveStep(0)
        }}>
          <DialogTitle style={{ borderBottom: '0.5px solid lightgray' }}>
            <Stepper activeStep={createBulkCandidateSessionActiveStep}>
              {createBulkCandidateSessionSteps.map((label, index) => {
                const stepProps= {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconComponent={() => {
                        return (
                          <div style={{
                            backgroundColor: createCandidateSessionActiveStep >= index ? 'black' : 'lightgray',
                            color: 'white',
                            borderRadius: '50%',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            {index + 1}
                          </div>
                        )
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </DialogTitle>
          <DialogContent >
            <div style={{ marginTop: 25 }}>
              {renderActiveBulkStepComponent()}
            </div>
          </DialogContent>
          <DialogActions>
            {(createCandidateSessionActiveStep === 0 && generatedSessionQuestions.length > 0) &&
              <button className="btn-main" disabled={generateSessionSpecificQuestionsLoading} onClick={() => {setCreateCandidateSessionActiveStep(createCandidateSessionActiveStep + 1)}}>
                Next
              </button>
            }
          </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />
      <Dialog open={showScorecard}>
          <DialogTitle>Scorecard</DialogTitle>
          <DialogContent>
          {currentScorecards.length > 0 &&
            renderScorecards()
          }
          {currentScorecard.length > 0 &&
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Skill</TableCell>
                  <TableCell>Feedback</TableCell>
                  <TableCell>Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentScorecard.map((scorecardItem, index) => (
                  <TableRow key={index}>
                    <TableCell>{scorecardItem.signal}</TableCell>
                    <TableCell>{scorecardItem.feedback}</TableCell>
                    <TableCell>{scorecardItem.score}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
          </DialogContent>
          <DialogActions>
              <button className="btn-main" onClick={() => setShowScorecard(false)}>
                Close
              </button>
          </DialogActions>
      </Dialog>
    </div>
    </DndContext>
  )
}

export const InterviewDetailList = () => {
  const { user, _, isLoading } = useAuth0();
  const [interviews, setInterviews] = useState([])
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if(!isLoading && user.email !== '' && !getInterviewsCalled) {
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      if(!teamId) {
        navigate('/')
        return
      }

      api.getInterviews(userId, teamId)
      .then((fetchedInterviews) => {
        if(fetchedInterviews) {
          setInterviews(fetchedInterviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))             
          setGetInterviewsCalled(true)
          if(fetchedInterviews.length > 0) {
            navigate('/interviews/' + fetchedInterviews[0]._id)
          }
        } else {
          navigate('/interviews')
        }
      })
      .catch((err) => {
        console.log(err)
        setGetInterviewsCalled(true)
      })
    }
  }, [isLoading, user, interviews, getInterviewsCalled]);

  return (
    <div>
      {(getInterviewsCalled && interviews.length > 0) &&
        <InterviewDetail importedInterviews={interviews} />
      }
      {(getInterviewsCalled && interviews.length === 0) &&
        <p style={{ textAlign: 'center'}}>No interviews found. Create your first interview <Link to="/create-interview">here.</Link></p>
      }
      {!getInterviewsCalled &&
      <>
        <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
      </>
      }
    </div>
  )
}


export const Settings = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [interviews, setInterviews] = useState([])
  const [interviewerUser, setInterviewerUser] = useState(null);
  const [showApiKey, setShowApiKey] = useState(false);
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState(false);
  const [refreshTeam, setRefreshTeam] = useState(false);
  const [currentTeam, setCurrentTeam] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [addTeamEmailCount, setAddTeamEmailCount] = useState(1);
  const [addTeamEmail, setAddTeamEmail] = useState('');
  const [addTeamAccess, setAddTeamAccess] = useState('full');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [inviteSent, setInviteSent] = useState(false)

  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false);

  const [teams, setTeams] = useState([]);

  const [accountOpen, setAccountOpen] = useState(false);
  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);
  const knitRef = useRef(null);
  // Backend call to generate & get the authSessionToken
  const newSessionFn = (e) => {
    e?.preventDefault();
    // Hit the backend to generate authSessionToken
    if(currentTeam && currentTeam._id && user) {
      fetch(proxyEndpoint + '/knit-create-session', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          originOrgId: currentTeam._id.$oid,
          originOrgName: currentTeam.name,
          originUserEmail: user.email,
          originUserName: user.name
        })
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((r) => {
        // UI Auth component won't open with an empty session token
        if (knitRef?.current && r.msg && r.msg.token) {
          knitRef.current.setAttribute("authsessiontoken", r.msg.token);
          // Set skipIntro attribute to disable the introduction screen
          // knitRef.current.setAttribute("skipIntro",'');
        } else {
          console.error('Invalid response or missing token');
        }
      })
      .catch((err) => {
        console.error('Error in knit-create-session:', err);
      });
    }
  };  

  // Upon finishing the integration flow
  const onFinishFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["integrationDetails"]);
    if (currentTeam && currentTeam._id && e?.detail?.integrationDetails?.integrationId) {
      fetch(proxyEndpoint + '/update-knit-integration-id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: currentTeam._id.$oid,
          integrationId: e.detail.integrationDetails.integrationId
        })
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Failed to update Knit integration ID');
        }
        setCurrentTeam({...currentTeam, knit_integration_id: e.detail.integrationDetails.integrationId})
        return res.json();
      })
      .catch(err => {
        console.error('Error updating Knit integration ID:', err);
      });
    }
  };

  // Upon deactivate of integration
  const onDeactivateFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["integrationDetails"]);
  };

  // Upon closing of the knit-auth component
  const onKnitCloseFn = (e) => {
    e.preventDefault();
    console.log( e["detail"]["knitClosed"]);
  };

  useEffect(() => {
    if(!currentTeam || !user) {
      return
    }
    
    console.log('knitRef', knitRef)
     // Assign functions to event listeners for onNewSession and onFinish events.
    knitRef.current?.addEventListener("onNewSession",newSessionFn);
    knitRef.current?.addEventListener("onFinish",onFinishFn);
    knitRef.current?.addEventListener("onDeactivate",onDeactivateFn);
    knitRef.current?.addEventListener("onKnitClose", onKnitCloseFn);
    // Set the token once the component has mounted
    newSessionFn();
    return () => {
    // Remove events listeners on unmount
      knitRef.current?.removeEventListener("onNewSession", newSessionFn);
      knitRef.current?.removeEventListener("onFinish",onFinishFn);
      knitRef.current?.removeEventListener("onDeactivate", onDeactivateFn);
      knitRef.current?.removeEventListener("onKnitClose", onKnitCloseFn);
    };
  }, [currentTeam, user]);  

  const accountRef = useRef(null);
  const onSuccess = useCallback((public_token) => {
    console.log('Merge onSuccess callback triggered');
    console.log('Public token:', public_token);
    
    api.getMergeAccountToken({ publicToken: public_token, userId: user.email, teamId: currentTeam._id.$oid })
      .then(response => {
        console.log('Merge account token response:', response);
        // Handle the response as needed
      })
      .catch(error => {
        console.error('Error getting Merge account token:', error);
        // Handle any errors
      });
  }, [user, currentTeam]);
  const [generatedLinkToken, setGeneratedLinkToken] = useState(null);
  useEffect(() => {
    if(user && !generatedLinkToken && currentTeam && currentTeam._id) {
      api.getMergeLinkToken({ userId: user.email, teamId: currentTeam._id.$oid })
      .then((response) => {
        setGeneratedLinkToken(response.data.link_token)
      })
    }
  }, [user, generatedLinkToken, currentTeam])

  const { open, isReady } = useMergeLink({
    linkToken: generatedLinkToken, // Replace ADD_GENERATED_LINK_TOKEN with the token retrieved from your backend (Step 1)
    onSuccess,
    onClose: () => {
      setGeneratedLinkToken('')
    }
  });  
  
  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };
  const { logout } = useAuth0();
  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };
  
  function interviewSort(a, b) {
    // top_val comes first
    if (a.type && a.type == 'template') return 1;
    if (b.type && b.type == 'template') return -1;
    // neither matches `value` - compare them normally
    else return 0;
  }

  const ACCESS_OPTIONS = [
    {name: 'Full access', val: 'full'},
    // {name: 'Read only', val: 'read'}
  ]

  useEffect(() => {
    if(user && !isLoading) {
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      axios.post(proxyEndpoint + '/me', { userId: userId })
      .then(response => {
        setInterviewerUser(response.data);
      })
      .catch(error => {
        console.error(error);
      });

      const teamId = localStorage.getItem('teamId')
      api.getTeam({ userId, teamId }).then((response) => {
        let team = response.data;
        setCurrentTeam(team)
        setTeamMembers(team.members)
        setRefreshTeam(false);
      })
      .catch(error => {
        console.error(error);
      });
    }
  }, [user, isLoading, refreshTeam]);

  useEffect(() => {
    if(!user || !isAuthenticated) {
      return
    }

    if(Object.keys(currentTeam).length > 0 && teams.length > 0) {
      return
    }

    const teamId = localStorage.getItem('teamId')
    console.log('teamId', teamId)
    if(!teamId) {
      navigate('/')
      return
    } else {
      if(Object.keys(currentTeam).length > 0) {
        return
      }
      var userId = user.email
      const overrideUser = localStorage.getItem('globalOverrideUser')
      if(overrideUser) {
        userId = overrideUser
      }

      api.getTeams({ userId: userId }).then((res) => {
        console.log('teams', res.data)
        setTeams(res.data)
      })
    }

    if(user && !getInterviewsCalled) {
      var userId = user.email
      var overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      if(!teamId) {
        navigate('/')
        return
      }
      api.getInterviews(userId, teamId)
      .then((response) => {
        // setInterviews(response.reverse())
        setInterviews(response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))        
        setGetInterviewsCalled(true)
      })
    }
  }, [user, isAuthenticated, currentTeam, teams, setCurrentTeam, setTeams])
  
  
  const handleOnCopy = (interviewConfigId) => {
    console.log('handleOnCopy', interviewConfigId)
    navigate(`/create-interview?copy_from=${interviewConfigId}`);
  }
  
  const toggleShowApiKey = () => {
    setShowApiKey(!showApiKey);
  };

  const generateApiKey = () => {
    console.log('generateApiKey')
    var userId = user.email
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    axios.post(proxyEndpoint + '/generate_api_key', { teamId: currentTeam._id.$oid })
      .then(response => {
        if(response.data.api_key) {
          setCurrentTeam({...currentTeam, api_key: response.data.api_key, public_team_id: response.data.public_team_id});
          setSnackbarMsg('API Key generated successfully');
          setOpenSnackbar(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  const saveWebhookUrl = () => {
    console.log('saveWebhookUrl')
    var userId = user.email
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    axios.post(proxyEndpoint + '/update_webhook_url', { teamId: currentTeam._id.$oid, webhookUrl: currentTeam.webhook_url })
    .then(response => {
      console.log('Webhook URL saved successfully');
      if(response.data.webhook_url) {
        setCurrentTeam({...currentTeam, webhook_url: response.data.webhook_url});
        setSnackbarMsg('Team Webhook URL saved successfully');
        setOpenSnackbar(true);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  const addTeamMemberInput = () => {
    if (addTeamEmailCount < 5) setAddTeamEmailCount((prev) => prev + 1)
  }

  const createTeam = () => {
    var userId = user.email;
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    var teamDetails = {
      name: "miguel's second team"
    }
    api.createTeam({ userId, teamDetails }).then((response) => {
      setSnackbarMsg(`Successfully created team.`)
      setOpenSnackbar(true)
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });
  }

  const getTeam = (team_id) => {
    let userId = user.email;
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    let teamId = team_id
    api.getTeam({ userId, teamId }).then((response) => {
      setSnackbarMsg(`Successfully got team.`)
      setOpenSnackbar(true)
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });
  }

  const callDeleteTeamMember = (memberId) => {
    let teamId = currentTeam._id.$oid;
    let userId = user.email;
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    api.deleteTeamMember({ memberId, teamId, userId }).then((response) => {
      setSnackbarMsg(`Successfully deleted team member.`);
      setOpenSnackbar(true);
      setRefreshTeam(true);
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });

  }

  const callInviteTeamMember = (member_id, access) => {
    let memberId = member_id;
    let teamId = currentTeam._id.$oid;
    let userId = user.email;
    const overrideUserLocal = localStorage.getItem('globalOverrideUser')
    if(overrideUserLocal != null) {
      userId = overrideUserLocal
    }
    let role = access;
    api.inviteTeamMember({ memberId, teamId, userId, role }).then((response) => {
      setSnackbarMsg(`Successfully invited team member.`);
      setOpenSnackbar(true);
      setRefreshTeam(true);
      setInviteSent(true)
    })
    .catch(error => {
      console.error(error);
      setSnackbarMsg(error.response.data.error);
      setOpenSnackbar(true);
    });
  }

  const teamClicked = (teamId) => {
    if (currentTeam._id.$oid != teamId) {
      const new_team = teams.find((t) => t._id.$oid == teamId);
      localStorage.setItem('teamId', new_team._id.$oid)
      setCurrentTeam(new_team)
      navigate('/interviews')
    }
  }

  return (<>
  
  
    <Drawer
    variant="permanent"
    sx={{
      width: 312,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: {
        backgroundColor: 'var(--color-dark-mode)', width: 312, zIndex: "var(--zIndex-canvas)" },
    }}
  >
    <button
      className="btn-main btn-dark-mode btn-logo d-flex"
      style={{ height: "fit-content", backgroundColor: 'var(--color-dark-mode)', padding: '8px 12px' }}
      aria-label="menu"
      onClick={() => {
        if (location.pathname != "/your-team") navigate("/interviews");
      }}
    >
      <img className="mr-auto" alt="Wayfaster" style={{ height: '32px' }} src={WayfasterLogo} />
    </button>

    <div style={{ marginRight: '12px', marginLeft: '12px' }}>
      <Switcher currentTeam={currentTeam} teams={teams} clicked={teamClicked}></Switcher>
    </div>

    <button
      className="btn-main btn-dark-mode d-flex"
      style={{
        margin: "8px 12px 0px",
        padding: '16px 12px',
        borderRadius: '8px',
        backgroundColor: 'transparent',
        border: '1px solid #3B3B3B',
        width: 'calc(100% - 24px)',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '56px',
        boxSizing: 'border-box',
        outline: 'none', // Remove default focus outline
        boxShadow: '0 0 0 1px #3B3B3B' // Add a white box shadow to create a visible border
      }}
        onClick={() => {
          navigate('/create-interview')
        }}
      >
      <span style={{ color: '#fff', fontSize: '14px', fontWeight: 600 }}>New Interview</span>
        <Add sx={{ color: '#fff', fontSize: '18px' }} />
      </button>   

    <div
      className="d-flex flex-row clickable getting-started"
      style={{
        margin: "8px 12px 0px", 
        padding: '12px', 
        borderRadius: '8px', 
        backgroundColor: 'var(--color-dark-mode)',
      }}
      onClick={() => {
        navigate('/getting-started');
      }}
    >
      <div className="d-flex align-items-center" style={{ width: '100%' }}>
        <PlayArrowIcon sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }} />
        <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Getting Started / Help Videos</h4>
      </div>
    </div>

    <div className="d-flex flex-row clickable" style={{ margin: "8px 12px 0px", padding: '12px', borderRadius: '8px', backgroundColor: 'var(--color-hover-dark)' }}
      onClick={() => setInterviewAccordionOpen((prev) => !prev)}>
      <PeopleOutlinedIcon className="my-auto" sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }}></PeopleOutlinedIcon>
      <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Interviews</h4>
      <ExpandMoreIcon fontSize="14px" className="my-auto" style={{ transition: 'all .3s ease', marginLeft: 'auto', color: '#fff', transform: interviewAccordionOpen ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
    </div>
    {!getInterviewsCalled && <CircularProgress size={20} className="mx-auto" sx={{ color: 'var(--color-primary)', marginTop: '16px' }}/>}
    {interviews.length > 0 &&
      <>
      
      <Scrollbars renderThumbVertical={thumbVDark} style={{ transition: 'all .3s ease', flex: interviewAccordionOpen ? 5 : 0, scrollbarColor: 'rgba(255, 255, 255, 0.2) var(--color-dark-mode)' }}>
        <Box sx={{ overflow: 'hidden',  padding: '0px 12px', boxShadow: 'inset -8px -12px 48px -8px var(--color-dark-mode)' }}>
          <List style={{ paddingTop: '0px' }} className="interview-list-wrapper">
          {interviews.length === 0 ?
            <div style={{ margin: 'auto'  }}>
              <p style={{ fontSize: '12px', color: 'var(--color-dark-mode-light)' }}>No interviews to show</p>
            </div>
            : interviews.sort(interviewSort).map((interview) => {
            return (
                <ListItem
                  className="interview-list-item"
                  style={{
                    padding: '4px 8px',
                    borderRadius: 0,
                    height: 'fit-content',
                    margin: '4px 0px',
                    width: '264px'
                  }}
                  onClick={() => {
                    navigate('/interviews/' + interview._id);
                  }}
                >
                  <div class="d-flex flex-row" style={{ width: '100%' }}>
                    <div className="d-flex" style={{ flex: 11 }}>
                      <p style={{ lineHeight: '18px', fontSize: '14px', margin: '8px 4px', fontWeight: 600, color: 'var(--color-dark-mode-light)'}}>
                        {interview.type && interview.type == 'template' && <Circle className="m-auto color-mid" style={{ fontSize: 8, marginRight: '4px' }}/>}
                        {interview.title}
                      </p>
                    </div>
                    <div className="d-flex" style={{ marginLeft: 'auto', flex: 1 }}>
                      <button className="btn-main small d-flex gray-dark outlined my-auto"
                        style={{ marginLeft: 'auto', width: '28px', height: '28px' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleOnCopy(interview._id)}
                        }>
                        <ContentCopyOutlined className="m-auto" sx={{ fontSize: '18px', color: 'var(--color-dark-mode-light)' }}></ContentCopyOutlined>
                      </button>
                    </div>
                  </div>
                </ListItem>
              )
            })}
          </List>
        </Box>
      </Scrollbars>
      </>
    }

    <Box style={{ flex: 3 }}>
      {/* placeholder */}
    </Box>
          
    
  {user && isAuthenticated && (
    <>
      <button
        className="btn-main btn-dark-mode btn-logo d-flex"
        style={{ height: "fit-content", backgroundColor: 'var(--color-dark-mode)', padding: '8px 12px' }}
        aria-label="menu"
        onClick={() => {
            navigate("/settings");
        }}>
        <SettingsIcon className="my-auto" style={{ marginRight: '8px', fontSize: '18px', color: 'var(--color-dark-mode-light)' }}></SettingsIcon>
                    
        <p style={{ lineHeight: '20px', fontSize: '14px', margin: '8px 4px', fontWeight: 600, color: 'var(--color-dark-mode-light)' }}>
          Settings
        </p>
      </button>
      
      <div className="d-flex flex-row mx-auto" style={{ padding: '8px', width: '100%' }}>
        <button
          className="btn-main btn-dark-mode d-flex flex-row"
          style={{ height: "40px", width: '100%' }}
          ref={accountRef}
          onClick={handleAccountToggle}
        >
          <Avatar
            className="my-auto"
            sx={{
              bgcolor: "var(--color-primary)",
              color: "black",
              width: 32,
              height: 32,
              textTransform: "capitalize",
              fontSize: "16px",
              marginTop: "-2px",
              marginRight: "6px",
            }}
          >
            {Array.from(user.email)[0]}
          </Avatar>
          <h6
            className="color-main my-auto"
            style={{ marginRight: "4px", fontSize: "12px", color: "#fff" }}
          >
            {user.email}
          </h6>
          <UnfoldMore className="color-gray my-auto" style={{ marginLeft: 'auto', fontSize: '24px' }} />
        </button>
      </div>
    </>
  )}

  <Popper
    open={accountOpen}
    anchorEl={accountRef.current}
    role={undefined}
    placement="bottom-end"
    style={{ width: "250px", zIndex: 9999 }}
    transition
  >
    {({ TransitionProps, placement }) => (
      <Grow
        {...TransitionProps}
        style={{
          transformOrigin: "right top",
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleAccountClose}>
            <MenuList
              autoFocusItem={accountOpen}
              id="composition-menu"
              aria-labelledby="composition-button"
              sx={{
                ["& .MuiMenuItem-root"]: {
                  fontSize: "14px",
                },
                ["& .MuiTypography-root"]: {
                  fontSize: "14px",
                },
              }}
            >
                <>
                    <MenuItem
                    onClick={() => {
                        localStorage.removeItem("teamId");
                        localStorage.removeItem("globalOverrideUser");
                        logout({ returnTo: "/" });
                    }}
                    >
                    <ListItemIcon>
                        <LogoutIcon
                        className="color-danger"
                        fontSize="14px"
                        />
                    </ListItemIcon>
                    <ListItemText className="color-danger">
                        Logout
                    </ListItemText>
                    </MenuItem>
                </>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </Popper>
    </Drawer>
    <div style={{ padding: '24px 32px', height: 'fit-content', width: '100%', background: 'white' }}>
      <h3 className="section" style={{ marginTop: 0 }}>Settings</h3>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />

      {/* Teams */}

      <div className="d-flex flex-col mx-auto" style={{ paddingTop: '24px', paddingBottom: '80px' }}>
          {/* Interview Questions Display */}
            <div class="d-flex flex-row section">
              <div class="d-flex flex-col">
                <h3 className="section-heading" style={{ marginTop: 0 }}>Your Team</h3>
                <p className="color-gray section" style={{ fontSize: 14, marginTop: 0 }}>Manage your existing team.</p>
              </div>
              <button className="btn-main d-flex my-auto" style={{ marginLeft: 'auto', marginRight: '16px' }}
                  onClick={() => setShowAddTeamMemberModal(true)}>
                  <Add className="my-auto" fontSize="24px"/>
                  Add Team Member
                </button>
            </div>

            <Paper style={{ margin: 'auto', width: '50%', boxShadow: 'none', borderRadius: '9px', border: '1px solid #D9D9D9' }}>
              <Grid container>
                <TableContainer style={{ width: '100%', margin: 0 }}>
                  <Table style={{ margin: 0}}>
                    <TableHead style={{ margin: 0}}>
                      <TableRow sx={{
                              '& .MuiTableCell-root': {
                                lineHeight: '20px',
                                backgroundColor: '#FAFAFA',
                                color: 'var(--color-heading)',
                                padding: '12px 16px'
                              }
                            }}>
                        {/* <TableCell>
                          Name
                        </TableCell> */}
                        <TableCell style={{ borderRadius: '9px 0 0 0' }}>
                          Email
                        </TableCell>
                        <TableCell style={{ borderRadius: '0 9px 0 0' }}>
                          {/* Actions */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-rounded-bottom">
                    {teamMembers && teamMembers.map((member, idx) => {
                      return (
                        <TableRow key={idx} sx={{ border: 'none', borderBottom: idx == teamMembers.length - 1 ? 'none' : '1px solid lightgray', padding: '0px', margin: '0px' }}>
                          {/* <TableCell style={{ margin: 0, fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            {member.name}
                          </TableCell>                           */}
                          <TableCell style={{  fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>{member.user_id}
                              {
                                member.status == 'invited' && <>
                                  <ChipTag variant="in-progress" text="Invited" style={{ width: 'max-content', marginLeft: '12px', marginTop: 'auto', marginBottom: 'auto' }} />
                                </>
                              }
                          </TableCell>
                          <TableCell style={{ fontSize: 14, color: '#656565', border: 'none', fontWeight: 500}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: 'auto' }}>
                              {(user && member.user_id !== user.email) ?
                                <Button className="btn-main btn-text my-auto"
                                  style={{ border: 'none !important' }}
                                  onClick={() => callDeleteTeamMember(member.user_id)}>
                                    <b style={{ color: "var(--color-reject)" }}>Delete</b>
                                </Button>
                                :
                                <>
                                </>
                              }
                              {/* <Button className="btn-main btn-text my-auto"
                                onClick={() => console.log('pressed edit')}>
                                  <b style={{ color: "var(--color-progress)" }}>Edit</b>
                              </Button> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
            {/* Add Team Dialog */}

            <Dialog open={showAddTeamMemberModal} maxWidth="md" onClose={() => {
              setAddTeamEmail('')
              setInviteSent(false)
            }}>
              <DialogContent style={{ padding: '20px 24px 0px' }}>
                <h5 className="section-heading">Invite team members
                  <TooltipCustom
                    title={<>
                        <p className="m-auto">
                          Share interviews, sessions, and other team resources with people you collaborate with.
                        </p>
                      </>}
                    style={{ fontSize: '14px', marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto' }}>
                  </TooltipCustom>
                </h5>
                <IconButton
                  aria-label="close"
                  onClick={() => setShowAddTeamMemberModal(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ fontSize: '16px' }} />
                </IconButton>
                <p className="color-gray" style={{ fontSize: 12, margin: 0 }}>Get your projects up and running faster by inviting your team to collaborate.</p>
                <div className="d-flex flex-row" style={{ marginTop: '16px' }}>
                  <div className="d-flex" style={{ flex: '10', marginRight: '16px' }}>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><MailOutlineOutlined></MailOutlineOutlined></InputAdornment>,
                      }}
                      value={addTeamEmail}
                      onChange={(e) => {setAddTeamEmail(e.target.value)}}
                    />
                    {/* <p style={{ fontSize: 14, marginLeft: 10, marginTop: 20, fontWeight: '500px' }}>What are follow up questions? Wayfaster assessments are fully dynamic, meaning questions are generated based on what the candidate says. <span style={{ color: '#20C58A'}}>Watch a small demo here to see how it works.</span></p> */}
                  </div>
                  <div className="d-flex" style={{ flex: '2' }}>
                    <TextField
                      select
                      variant="outlined"
                      defaultValue={'full'}
                      value={addTeamAccess}
                      onChange={(e) => {setAddTeamAccess(e.target.value)}}
                    >
                      {ACCESS_OPTIONS.map((access) =>
                        <MenuItem key={access.name} value={access.val}>
                          {access.name}
                        </MenuItem>)}
                    </TextField>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                  <div style={{ display : 'flex', width: '100%', padding: '16px'}}>
                    {/* {addTeamEmailCount < 5 && <Button className="btn-main gray btn-text"
                      style={{ marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto', border: 'none !important' }}
                      onClick={() => addTeamMemberInput()}>
                        <Add style={{ fontSize: '16px' }}></Add>
                        Add another
                    </Button>} */}
                    <Button className="btn-main" style={{ height: '40px', padding: '10px 14px', marginLeft: 'auto' }}
                      onClick={() => callInviteTeamMember(addTeamEmail, addTeamAccess)} disabled={inviteSent}>
                        {inviteSent ?
                          <>
                            <MailOutlineOutlined style={{ marginLeft: 'auto', fontSize: '18px', marginRight: '8px' }}></MailOutlineOutlined>
                            Invite Sent
                          </>
                          :
                          <>
                          <MailOutlineOutlined style={{ marginLeft: 'auto', fontSize: '18px', marginRight: '8px' }}></MailOutlineOutlined>
                          Send Invite
                          </>
                        }
                    </Button>
                  </div>
              </DialogActions>
            </Dialog>
          </div>
            <Divider/>
            <h3 style={{ fontFamily: 'PPNeueMontreal', fontSize: '20px', fontWeight: 'bold' }}>Connect your ATS</h3>
            <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>Connect your Applicant Tracking System to streamline your hiring process</p>
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2, marginBottom: 2, fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>
              Note: For proper integration, the interview title in Wayfaster must exactly match the job name in your ATS.
            </Typography>
            {/* <Button
              variant="contained"
              color="primary"
              disabled={!isReady || (currentTeam && currentTeam.merge_account_token)}
              onClick={() => {
                
              }}
              startIcon={<LinkIcon />}
              sx={{
                fontFamily: 'PPNeueMontreal',
                fontSize: '14px',
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#333',
                },
                '&:disabled': {
                  backgroundColor: '#444',
                  color: '#888',
                },
                padding: '10px 20px',
                borderRadius: '8px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            >
              {currentTeam && currentTeam.merge_account_token ? 'ATS is Connected' : 'Connect your ATS'}
            </Button> */}
            <knit-auth ref={knitRef}>
              <Button 
              variant="contained"
              color="primary"
              startIcon={<LinkIcon />}
              disabled={currentTeam?.knit_integration_id}
              sx={{
                fontFamily: 'PPNeueMontreal',
                fontSize: '14px',
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#333',
                },
                '&:disabled': {
                  backgroundColor: '#444',
                  color: '#888',
                },
                padding: '10px 20px',
                borderRadius: '8px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}              
              slot="trigger">{currentTeam?.knit_integration_id ? 'ATS Connected' : 'Connect your ATS'}</Button>
            </knit-auth>            
            {currentTeam && currentTeam.merge_account_token && (
              <div style={{ marginTop: '20px' }}>
                <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>You've connected your ATS. Set the stage name in your ATS that corresponds to the Wayfaster interview stage.</p>
                <FormField
                  label="Wayfaster Stage Name"
                  tooltip
                  tooltipTitle="Enter the name of the stage in your ATS that corresponds to Wayfaster interviews."
                  variant="outlined"
                  value={currentTeam.wayfaster_stage_name || ""}
                  onChange={(e) => {
                    setCurrentTeam({
                      ...currentTeam,
                      wayfaster_stage_name: e.target.value
                    });
                  }}
                  inputProps={{ style: { fontFamily: 'PPNeueMontreal', fontSize: '14px' } }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    api.updateWayfasterStageName({
                      userId: user.email,
                      teamId: currentTeam._id.$oid,
                      wayfasterStageName: currentTeam.wayfaster_stage_name
                    })
                    .then((res) => {
                      console.log("Wayfaster stage name updated successfully:", res);
                      setCurrentTeam({
                        ...currentTeam,
                        wayfaster_stage_name: currentTeam.wayfaster_stage_name
                      });
                      setSnackbarMsg("Wayfaster stage name updated successfully");
                      setOpenSnackbar(true);
                    })
                    .catch((err) => {
                      console.error("Failed to update Wayfaster stage name:", err);
                      setSnackbarMsg("Failed to update Wayfaster stage name");
                      setOpenSnackbar(true);
                    });
                  }}
                  sx={{
                    fontFamily: 'PPNeueMontreal',
                    fontSize: '14px',
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                    padding: '10px 20px',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    marginTop: '10px',
                  }}
                >
                  Save Stage Name
                </Button>
              </div>
            )}
            <br/>
            <h3>Developer</h3>
            <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>Settings for interacting with our service via our public API and webhooks.</p>
            <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>Check out our <a href="https://wayfaster.mintlify.app" target="_blank" rel="noopener noreferrer" style={{ color: 'var(--color-main)', textDecoration: 'underline' }}>docs here</a> to see the endpoints and webhook events we support.</p>
            {currentTeam && currentTeam.public_team_id &&
              <>
                <h4>Your Team ID</h4>
                <p style={{ fontFamily: 'PPNeueMontreal', fontSize: '14px' }}>This is your username for interacting with our service via our public API. This never changes and it is specific to your team.</p>            
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormField
                  value={currentTeam && currentTeam.public_team_id ? currentTeam.public_team_id : ""}
                  disabled
                  variant="outlined"
                  style={{ flex: 1 }}
                />
                </div>
              </>
            }
            <h4>Your API Key</h4>
            {currentTeam && currentTeam.api_key &&
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormField
                  type={showApiKey ? "text" : "password"}
                  value={currentTeam && currentTeam.api_key ? currentTeam.api_key : ""}
                  disabled
                  variant="outlined"
                  style={{ flex: 1 }}
                />
                <button className="btn-main outlined d-flex"
                  style={{ height: '46px', marginLeft: '8px', marginBottom: '16px' }}
                  onClick={toggleShowApiKey}>
                  {showApiKey ? <VisibilityOffIcon className="m-auto"/> : <VisibilityIcon className="m-auto"/>}
                </button>
              </div>
            }
            {(!currentTeam || !currentTeam.api_key) &&
              <p>No API Key found. Generate one.</p>
            }
            <br/>
            <button className="btn-main" onClick={generateApiKey} style={{ fontSize: '12px'}}>
              {currentTeam && currentTeam.api_key ? "Regenerate API Key" : "Generate API Key"}
            </button>
            <h4>Webhook Configuration</h4>
            <FormField
              label="Webhook URL"
              tooltip
              tooltipTitle="Enter the endpoint where we should send interview session start and completion events to. This is set for the entire team."
              variant="outlined"
              value={currentTeam && currentTeam.webhook_url ? currentTeam.webhook_url : ""}
              onChange={(e) => setCurrentTeam({...currentTeam, webhook_url: e.target.value})}
            />
            <button className="btn-main" onClick={() => saveWebhookUrl()}  style={{ fontSize: '12px'}}>
              Save
            </button>
    </div>
    
  </>
)
}

export const GettingStarted = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [interviews, setInterviews] = useState([]);
  const [currentTeam, setCurrentTeam] = useState({});
  const [teams, setTeams] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);
  const [accountOpen, setAccountOpen] = useState(false);
  const accountRef = useRef(null);
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false);
  const { interview_id, session_id } = useParams();

  useEffect(() => {
    if (!user || !isAuthenticated) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      var userId = user.email;
      const overrideUserLocal = localStorage.getItem('globalOverrideUser');
      if (overrideUserLocal != null) {
        userId = overrideUserLocal;
      }
      const teamId = localStorage.getItem('teamId');
      if (!teamId) {
        navigate('/');
        return;
      }

      // Fetch teams
      try {
        const teamsResponse = await api.getTeams({ userId: userId });
        setTeams(teamsResponse.data);
        const currentTeam = teamsResponse.data.find(team => team._id.$oid === teamId);
        setCurrentTeam(currentTeam || {});
      } catch (error) {
        console.error('Error fetching teams:', error);
      }

      // Fetch interviews
      if (!getInterviewsCalled) {
        try {
          const interviewsResponse = await api.getInterviews(userId, teamId);
          setInterviews(interviewsResponse.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
          setGetInterviewsCalled(true);
        } catch (error) {
          console.error('Error fetching interviews:', error);
        }
      }
    };

    fetchData();
  }, [user, isAuthenticated, navigate, getInterviewsCalled]);

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };

  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };

  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );  

  const teamClicked = (teamId) => {
    if (currentTeam._id.$oid !== teamId) {
      const new_team = teams.find((t) => t._id.$oid === teamId);
      localStorage.setItem('teamId', new_team._id.$oid);
      setCurrentTeam(new_team);
      navigate('/interviews');
    }
  };

  const handleOnCopy = (interviewConfigId) => {
    console.log('handleOnCopy', interviewConfigId);
    navigate(`/create-interview?copy_from=${interviewConfigId}`);
  };

  function interviewSort(a, b) {
    // top_val comes first
    if (a.type && a.type == 'template') return 1;
    if (b.type && b.type == 'template') return -1;
    // neither matches `value` - compare them normally
    else return 0;
  }  

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: 312,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            backgroundColor: 'var(--color-dark-mode)',
            width: 312,
            zIndex: "var(--zIndex-canvas)"
          },
        }}
      >
        <button
          className="btn-main btn-dark-mode btn-logo d-flex"
          style={{ height: "fit-content", backgroundColor: 'var(--color-dark-mode)', padding: '8px 12px' }}
          aria-label="menu"
          onClick={() => {
            if (location.pathname !== "/your-team") navigate("/interviews");
          }}
        >
          <img className="mr-auto" alt="Wayfaster" style={{ height: '32px' }} src={WayfasterLogo} />
        </button>

        <div style={{ marginRight: '12px', marginLeft: '12px' }}>
          <Switcher currentTeam={currentTeam} teams={teams} popper={true} clicked={teamClicked}></Switcher>
        </div>

        <button
          className="btn-main btn-dark-mode d-flex"
          style={{
            margin: "8px 12px 0px",
            padding: '16px 12px',
            borderRadius: '8px',
            backgroundColor: 'transparent',
            border: '1px solid #3B3B3B',
            width: 'calc(100% - 24px)',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '56px',
            boxSizing: 'border-box',
            outline: 'none', // Remove default focus outline
            boxShadow: '0 0 0 1px #3B3B3B' // Add a white box shadow to create a visible border
          }}
            onClick={() => {
              navigate('/create-interview')
            }}
          >
            <span style={{ color: '#fff', fontSize: '14px', fontWeight: 600 }}>New Interview</span>
            <Add sx={{ color: '#fff', fontSize: '18px' }} />
          </button>   

          <div
            className={`d-flex flex-row clickable getting-started ${location.pathname === '/getting-started' ? 'selected' : ''}`}
            style={{
              margin: "8px 12px 0px", 
              padding: '12px', 
              borderRadius: '8px', 
              backgroundColor: location.pathname === '/getting-started' ? 'var(--color-hover-dark)' : 'var(--color-dark-mode)',
            }}
            onClick={() => {
              navigate('/getting-started');
            }}
          >
            <div className="d-flex align-items-center" style={{ width: '100%' }}>
              <PlayArrowIcon sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }} />
              <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Getting Started / Help Videos</h4>
            </div>
          </div>   


        <div className="d-flex flex-row clickable" style={{ margin: "8px 12px 0px", padding: '12px', borderRadius: '8px', backgroundColor: 'var(--color-dark-mode)' }}
            onClick={() => setInterviewAccordionOpen((prev) => !prev)}>
            <PeopleOutlinedIcon className="my-auto" sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }}></PeopleOutlinedIcon>
            <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Interviews</h4>
            <ExpandMoreIcon fontSize="14px" className="my-auto" style={{ transition: 'all .3s ease', marginLeft: 'auto', color: '#fff', transform: interviewAccordionOpen ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
        </div>
          {!getInterviewsCalled && <CircularProgress size={20} className="mx-auto" sx={{ color: 'var(--color-primary)', marginTop: '16px' }}/>}
          {interviews.length > 0 &&
            <>
            <Scrollbars renderThumbVertical={thumbVDark} style={{ transition: 'all .3s ease', flex: interviewAccordionOpen ? 5 : 0, scrollbarColor: 'rgba(255, 255, 255, 0.2) var(--color-dark-mode)' }}>
              <Box sx={{ overflow: 'hidden', padding: '0px 12px', boxShadow: 'inset -8px -12px 48px -8px var(--color-dark-mode)' }}>            
                <List style={{ paddingTop: '0px' }} className="interview-list-wrapper">
                {interviews.length === 0 ?
                  <div style={{ margin: 'auto'  }}>
                    <p style={{ fontSize: '12px', color: 'var(--color-dark-mode-light)' }}>No interviews to show</p>
                  </div>
                  : interviews.sort(interviewSort).map((interview) => {
                  return (
                      <ListItem
                        className={interview_id === interview._id ? 'interview-list-item-selected' : "interview-list-item" }
                        style={{
                          padding: '4px 8px',
                          borderRadius: 0,
                          height: 'fit-content',
                          margin: '4px 0px',
                          width: '264px'
                        }}
                        onClick={() => {
                          navigate('/interviews/' + interview._id);
                        }}
                      >
                        <div class="d-flex flex-row" style={{ width: '100%' }}>
                          <div className="d-flex" style={{ flex: 11 }}>
                            <p style={{ lineHeight: '18px', fontSize: '14px', margin: '8px 4px', fontWeight: 600, color: interview_id === interview._id ? '#fff' :  'var(--color-dark-mode-light)'}}>
                              {interview.type && interview.type == 'template' && <Circle className="m-auto color-mid" style={{ fontSize: 8, marginRight: '4px' }}/>}
                              {interview.title}
                            </p>
                          </div>
                          <div className="d-flex" style={{ marginLeft: 'auto', flex: 1 }}>
                            <button className="btn-main small d-flex gray-dark outlined my-auto"
                              style={{ marginLeft: 'auto', width: '28px', height: '28px' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleOnCopy(interview._id)}
                              }>
                              <ContentCopyOutlined className="m-auto" sx={{ fontSize: '18px', color: 'var(--color-dark-mode-light)' }}></ContentCopyOutlined>
                            </button>
                          </div>
                        </div>
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
            </Scrollbars>
            </>
          }


        {/* Account menu */}
        <Popper
          open={accountOpen}
          anchorEl={accountRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleAccountClose}>
                  <MenuList
                    autoFocusItem={accountOpen}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <MenuItem
                      onClick={() => {
                        localStorage.removeItem("teamId");
                        localStorage.removeItem("globalOverrideUser");
                        logout({ returnTo: "/" });
                      }}
                    >
                      <ListItemIcon>
                        <LogoutIcon
                          className="color-danger"
                          fontSize="14px"
                        />
                      </ListItemIcon>
                      <ListItemText className="color-danger">
                        Logout
                      </ListItemText>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Drawer>

      <div style={{ padding: '24px 32px', height: 'fit-content', width: '100%', background: 'white' }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => {setOpenSnackbar(false)}}
          message={snackbarMsg}
        />

        <div className="d-flex flex-col mx-auto" style={{ paddingTop: '24px', paddingBottom: '80px' }}>
          <div className="d-flex flex-row section justify-content-between align-items-center">
            <div className="d-flex flex-col" style={{ flex: 1 }}>
              <h3 className="section-heading" style={{ marginTop: 0 }}>Discover how Wayfaster works</h3>
              <p className="color-gray section" style={{ fontSize: 14, marginTop: 0 }}>Take a few minutes to discover how the product works.</p>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <button
                className="btn-main btn-dark-mode d-flex"
                onClick={() => navigate('/create-interview')}
                style={{
                  padding: '16px 12px',
                  borderRadius: '8px',
                  backgroundColor: 'transparent',
                  border: '1px solid #3B3B3B',
                  width: '135px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '56px',
                  boxSizing: 'border-box',
                  boxShadow: '0 0 0 1px #3B3B3B' // Add a white box shadow to create a visible border
                }}
              >                
                <Add sx={{ color: '#fff', fontSize: '18px' }} />
                <span style={{ color: '#fff', fontSize: '14px', fontWeight: 600, marginRight: '8px' }}>New Interview</span>
              </button>
            </div>
          </div>

          <div className="video-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
            {[
              { id: 1, title: "Getting Started: Creating an Interview", url: "https://www.loom.com/embed/461af952d4bd4228946c43c1238767db" },
              { id: 2, title: "Adding People to a Team", url: "https://www.loom.com/embed/a9c921960e114612be30f22268e0bc61" },
              { id: 3, title: "Creating Topics for The Interview", url: "https://www.loom.com/embed/c622212a13dd45e1a346a96199bf83ef" },
              { id: 4, title: "Creating Topics from Scratch", url: "https://www.loom.com/embed/d48a3b390adf4023a3849ba0e730a07c" },
              { id: 5, title: "Creating Scoring Criteria from Scratch", url: "https://www.loom.com/embed/13ad85032afd4740abe719017ec8ae50" },
              { id: 6, title: "Structured Outputs and How to Use Them", url: "https://www.loom.com/embed/cf98ea4b370d40349ce06680a9c1baba" },
              { id: 7, title: "Creating and Sending Interview Links", url: "https://www.loom.com/embed/6f16cb930acb4ad0a9ca5aaca8d8ff9d" },
              { id: 8, title: "Viewing and Sharing Interview Results", url: "https://www.loom.com/embed/8a68e9a2fe6e4d47adb819afbe703fc5" },
              { id: 9, title: "Modifying an Existing Interview", url: "https://www.loom.com/embed/ea15388f56114f08b4e49982dfa93ef9" },
              { id: 10, title: "Improving Your Scoring Model", url: "https://www.loom.com/embed/ead87aa28d154fa6a564de42b5a80b47" },
              { id: 11, title: "Custom Prompting/Interaction With the AI", url: "https://www.loom.com/embed/27942de0bb6c4c638b88de3491633a78" }
            ].map((video) => (
              <div key={video.id} style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
                  <iframe
                    src={video.url}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px'
                    }}
                  ></iframe>
                </div>
                <p style={{ 
                  marginTop: '10px', 
                  fontSize: '14px', 
                  fontWeight: 'bold', 
                  textAlign: 'left',
                  fontFamily: 'PPNeueMontreal, sans-serif'
                }}>{video.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const Demo = () => {
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [interviewStarted, setInterviewStarted] = useState(false);
    const [waitlistIsOpen, setWaitlistIsOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    // const [callId, setCallId] = useState(0)
    const [interview, setInterview] = useState(emptyInterviewObject);
    const [updated, setUpdated] = useState(false);
    const [loadingDiagramFeedback, setLoadingDiagramFeedback] = useState(false);
    const [micEnabled, setMicEnabled] = useState(false);
    const [visualState, setVisualState] = useState('');
    const [firstMobileScreenDone, setFirstMobileScreenDone] = useState(false);
    const [scorecardModalOpen, setScorecardModalOpen] = useState(false);
    const [, setTimerElapsed] = React.useState(0);

    // Waitlist
    const [subscriptionSuccessSnackOpen, setSubscriptionSuccessSnackOpen] = React.useState(false);
    const [subscriptionFailSnackOpen, setSubscriptionFailSnackOpen] = React.useState(false);

    const handleOpenWaitlist = () => {
      setWaitlistIsOpen(true);
    }

    const handleCloseWaitlist = () => {
      setWaitlistIsOpen(false);
    }

    const handleCloseSuccessSnack = () => {
      setSubscriptionSuccessSnackOpen(false);
    }

    const handleCloseFailSnack = () => {
      setSubscriptionFailSnackOpen(false);
    }

    const validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    const handleJoinWaitlist = async () => {
      const emailValid = validateEmail(email)
      if(!emailValid) {
        setSubscriptionFailSnackOpen(true)
        return
      }

      try {
        const response = await axios.post(proxyEndpoint + '/join_waitlist', {
          subscription: { email: email }
        });
        if(!response.data.result) {
          setSubscriptionFailSnackOpen(true);
        } else {
          handleCloseWaitlist();
          setSubscriptionSuccessSnackOpen(true);
          setWaitlistIsOpen(false);
        }
      } catch (error) {
        setSubscriptionFailSnackOpen(true);
      }

    }

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    const handleCloseSubscriptionFailSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setSubscriptionFailSnackOpen(false);
    };

    const handleCloseSubscriptionSuccessSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setSubscriptionSuccessSnackOpen(false);
    };

    // Tabs
    const [tabValue,] = React.useState(0);


    // Material UI responsive layout settings
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Timer
    const [timer, setTimer] = React.useState(interviewDuration);

    useEffect(() => {
      let interval = null;
      if (interviewStarted) {
        console.log('starting timer')
        interval = setTimeout(() => {
          if(timer > 0) {
            setTimer(timer => timer - 1)
            setTimerElapsed(timerElapsed => timerElapsed + 1)
          }
        }, 1000);
      }

      return () => {
          if (interval !== null && timer <= 0) {
              clearTimeout(interval);
          }
      };
  }, [interviewStarted, timer]);

    if(excalidrawAPI !== null && !updated) {
        fetch('prefill.excalidraw.json')
        .then(response => response.json())
        .then(data => {
          excalidrawAPI.updateScene({elements: data.elements, appState: data.appState});
          excalidrawAPI.scrollToContent(data.elements, {
              fitToContent: true,
              animate: false,
          });
          setUpdated(true);
          const appState = excalidrawAPI.getAppState();
          const elements = excalidrawAPI.getSceneElements();
          if (!elements || !elements.length) {
              return
          }

          exportToBlob({
              elements,
              appState: {
                  ...appState,
                  exportWithDarkMode: false,
              },
              files: excalidrawAPI.getFiles(),
              maxWidthOrHeight: 1000
          })
          .catch((error) => {
              console.error('Error:', error);
          });
        })
    }

    const initWebsocketConnection = (callId, interviewTypeParam) => {
      let clientType = 'desktop'
      if (isMobile) {
        clientType = 'mobile'
      }

      console.log('initWebsocketConnection')
      const socket = io(wsEndpoint, {
        withCredentials: true,
        transports: ["websocket"]
      });
      socket.on('connect', () => {
        console.log('Websocket connected');
      });

      socket.on('disconnect', () => {
        console.log('Websocket disconnected');
      });

      console.log(globalInterviewType);

      socket.emit('register', {
        callId: callId,
        clientType: clientType,
        interviewType: interviewTypeParam
      });
      socket.on('update', (interview) => {
        console.log('update', interview.metadata.callId, callId)
        console.log(interview.scorecard)
        if(interview.metadata.callId === callId) {
          setInterview(interview);
        }
      });
      socket.on('parrot', (res) => {
        console.log('par', res.callId)
        if(res.callId === callId) {
          console.log('parroting')
          vapi.send({
            type: "add-message",
            message: {
              role: "system",
              content: "parrot_: " + res.res,
            },
          });
        }
      })

      return socket;
    }

    const handleStartInterview = (interviewTypeParam) => {
        console.log('handleStartInterview')
        setInterviewStarted(true);
        var interviewType = 'system_design_event_ticketing'
        if(interviewTypeParam !== '') {
          interviewType = interviewTypeParam
          globalInterviewType = interviewType
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          const interviewTypeQueryParam = urlParams.get('interview_type');
          if (interviewTypeQueryParam) {
            interviewType = interviewTypeQueryParam;
            globalInterviewType = interviewType
          }
        }

        if(interviewTypeParam === 'eve') {
          vapiEnvId = 'df16d937-aa79-4202-8304-49091a6b92f7'
        }

        vapi.start(vapiEnvId)
        .then((data) => {
          // i assume ID is callId
          callId = data['id']
          if(socket === undefined) {
              socket = initWebsocketConnection(callId, interviewTypeParam);
          }
        })
        .catch((err) => {console.error(err)})

        vapi.on('speech-start', () => {
            console.log('Speech has started');
            setVisualState('speaking');
        });

        vapi.on('speech-end', () => {
            console.log('Speech has ended');
            setVisualState('listening');
        });

        vapi.on('call-start', () => {
            console.log('Call has started');
        });

        vapi.on('call-end', () => {
            console.log('Call has stopped');
        });

        vapi.on('volume-level', (volume) => {
        });

        // Function calls and transcripts will be sent via messages
        vapi.on('message', (message) => {
            console.log(message);
        });

        vapi.on('error', (e) => {
            console.error(e)
        });
    }

    const handleStopInterview = () => {
      console.log('handleStopInterview')
      if(!scorecardModalOpen) {
        // get scorecard
        if(stopInvoked) {
          return
        }
        stopInvoked = true
        vapi.send({
          type: "add-message",
          message: {
            role: "system",
            content: "parrot_: That is the end of the interview. Thanks so much for taking the time to chat with us, and we will get back to you shortly!",
          },
        });

        console.log('making request')
        axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })
        .then((response) => {
            console.log(response.data)
        })
        .catch((error) => {
            console.error('Error fetching scorecard:', error);
        });

        axios.post(`${proxyEndpoint}/structured_outputs`, { callId: callId })
        .then((response) => {
            console.log(response.data)
        })
        .catch((error) => {
            console.error('Error fetching structured_outputs:', error);
        });

        axios.post(`${proxyEndpoint}/recording`, { callId: callId })
        .then((response) => {
            console.log(response.data)
        })
        .catch((error) => {
            console.error('Error fetching recording:', error);
        });

        setScorecardModalOpen(true);

        // if(socket != null) {
        //     socket.close();
        //     socket = null;
        // }
        setInterview(emptyInterviewObject)

        setTimeout(() => {
          vapi.stop()
        }, 10000)
      }
    }

    const handleSubmitDiagramFeedback = () => {
      console.log('handleSubmitDiagramFeedback')
      setLoadingDiagramFeedback(true);
      const buffering_reses = [
        'Hang on, taking a look at the whiteboard',
        'One sec, taking a look',
        'Looking, give me one second.',
        "Bear with me, I'm perusing the whiteboard details.",
        "Just a tick, I'm eyeing the whiteboard content.",
        'Allow me a brief pause to scan the whiteboard.',
        'I need a short moment to digest the whiteboard info.',
        'Let me take a swift glance at the whiteboard.',
        "I'm diving into the whiteboard, hold on.",
        'A quick study of the whiteboard is underway.',
        "I'm zooming in on the whiteboard, stand by.",
        'Peering into the whiteboard, just a moment.',
        "I'm deciphering the whiteboard scribbles, hang tight."
      ];
      const buffering_res = buffering_reses[Math.floor(Math.random() * buffering_reses.length)];
      vapi.send({
        type: "add-message",
        message: {
          role: "system",
          content: "parrot_: " + buffering_res,
        },
      });

      if (!excalidrawAPI) {
          return
      }
      const elements = excalidrawAPI.getSceneElements();
      if (!elements || !elements.length) {
          return
      }

      const appState = excalidrawAPI.getAppState();

      exportToBlob({
          elements,
          appState: {
              ...appState,
              exportWithDarkMode: false,
          },
          files: excalidrawAPI.getFiles(),
          maxWidthOrHeight: 1000
      })
      .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
              const base64data = reader.result;
              fetch(`${proxyEndpoint}/get_diagram_feedback`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ image: base64data, callId: callId })
              })
              .then((response) => {
                  setLoadingDiagramFeedback(false);
                  response.json()
                  .catch((err) => {console.error(err)})
              })
              .catch((err) => {console.error(err)})
          }
      })
    }

    if (timer <= 0) {
        handleStopInterview()
    }

    const renderContext = () => {
      return interview.context.map((message, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
            <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
            <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>{message}</Typography>
        </div>
      ))
    }

    function secondsToHumanReadableTime(seconds) {
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

      return formattedTime;
    }

    const enableMic = () => {
      navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function(stream) {
        setMicEnabled(true);
      })
      .catch(function(err) {
        console.log('Microphone permission denied');
      });
    }
    console.log('(isMobile && firstMobileScreenDone) || !isMobile', (isMobile && firstMobileScreenDone) || !isMobile)
    const urlParams = new URLSearchParams(window.location.search);
    const interviewTypeQueryParam = urlParams.get('interview_type');
    var interviewTypeModalText = ''
    if (interviewTypeQueryParam) {
      globalInterviewType = interviewTypeQueryParam;
    }
    if (globalInterviewType === 'virtual_assistant') {
      interviewTypeModalText = 'virtual assistant'
    } else if (globalInterviewType === 'system_design') {
      interviewTypeModalText = 'system design mock'
    } else if (globalInterviewType === 'instacart_biz_ops') {
      interviewTypeModalText = 'Instacart Business Operations pre-screening'
    } else if (globalInterviewType === 'residency') {
      interviewTypeModalText = 'medical residency mock'
    } else if (globalInterviewType === 'patient_questionnaire') {
      interviewTypeModalText = 'patient care'
    }

    return (
        <>
            <Container>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12} sm={12}>
                  <form>
                    <Dialog open={waitlistIsOpen} onClose={() => {setWaitlistIsOpen(true)}}>
                      <DialogTitle
                        style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'
                        }}
                      >Waitlist</DialogTitle>
                      <DialogContent
                        style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          padding: 10,
                          fontFamily: 'Mulish'
                        }}
                      >
                        <DialogContentText style={{
                          fontFamily: 'Mulish',
                          color: 'white',
                          padding: 10
                        }}>
                          Join the waitlist to get access to your recording and the fully featured product!
                          <br/>
                          <br/>
                          <input
                            required
                            onChange={handleEmailChange}
                            style={{
                              color: 'white',
                              width: 300,
                              fontSize: 18,
                              fontFamily: 'Mulish',
                              background: 'black',
                              paddingBottom: 10,
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderRight: 'none',
                              borderBottom: '0.5px solid white',
                            }}
                            type="email"
                            placeholder={'Enter your email address'}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'
                        }}
                      >
                        <Button
                          type="submit"
                          style={{ color: 'black', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}
                          onClick={handleJoinWaitlist}>Join</Button>
                      </DialogActions>
                    </Dialog>
                  </form>
                  <Snackbar
                    open={subscriptionSuccessSnackOpen}
                    autoHideDuration={5000}
                    onClose={handleCloseSubscriptionSuccessSnack}>
                      <Alert onClose={handleCloseSuccessSnack} severity="success" sx={{ width: '100%' }}>
                        You're on the waitlist!
                      </Alert>
                    </Snackbar>
                    <Snackbar
                    open={subscriptionFailSnackOpen}
                    autoHideDuration={5000}
                    onClose={handleCloseSubscriptionFailSnack}>
                      <Alert onClose={handleCloseFailSnack} severity="error" sx={{ width: '100%' }}>
                        Error: e-mail already used or invalid format.
                      </Alert>
                    </Snackbar>
                </Grid>
              </Grid>
            </Container>
            {((isMobile && firstMobileScreenDone) || !isMobile) &&
            <Paper elevation={0} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', zIndex: 9999, background: '#181818', color: 'white' }}>
              <Typography variant="h6" style={{ cursor: 'pointer', textTransform: 'none' }}>
                <Button onClick={handleOpenWaitlist} style={{ color: 'white', fontWeight: 200, textTransform: 'none', fontSize: 16, fontFamily: 'Mulish' }}>
                  {isMobile &&
                    <>
                    Get future access.
                    </>
                  }
                  {!isMobile &&
                    <>

                      Get future access and your interview recording.
                    </>
                  }
                  <span onClick={handleOpenWaitlist} style={{ textDecoration: 'underline', marginLeft: '4px' }}>Join waitlist.</span>
                </Button>
              </Typography>
            </Paper>
            }
            <Container maxWidth = "xl" className = "whiteboardView" style={{ backgroundColor: '#050505' }}>
              <div style={{ paddingTop: 25, marginBottom: isMobile ? 0 : 25 }}>
                <a href="https://techinterviewer.ai"><img alt="" src="logo.png" /></a>
              </div>
                <Grid container direction="column" spacing={3}>
                    <Grid item container xs={12} style={{ marginBottom: 15 }}>
                      {!isMobile &&
                      <Grid item xs={4} style={{ textAlign: 'left' }}>
                        <div style={{fontSize: 22, fontFamily: 'Mulish'}}>
                          {globalInterviewType === 'patient_questionnaire' &&
                            <>
                            Lupus Patient Check-in
                            </>
                          }
                          {globalInterviewType === 'system_design' &&
                            <>
                              System Design Interview: "Design a Blog App"
                            </>
                          }
                          {globalInterviewType === 'system_design_twitter' &&
                            <>
                              System Design Interview: "Design Twitter"
                            </>
                          }
                          {globalInterviewType === 'system_design_instagram' &&
                            <>
                              System Design Interview: "Design Instagram"
                            </>
                          }
                          {globalInterviewType === 'system_design_google_docs' &&
                            <>
                              System Design Interview: "Design Google Docs"
                            </>
                          }
                          {globalInterviewType === 'system_design_job_scheduler' &&
                            <>
                              System Design Interview: "Design a Job Scheduler"
                            </>
                          }
                          {globalInterviewType === 'virtual_assistant' &&
                            <>
                              Virtual Assistant Pre-Screening Interview
                            </>
                          }
                          {globalInterviewType === 'consulting' &&
                            <>
                              Consulting
                            </>
                          }
                          {globalInterviewType === 'market_sizing' &&
                            <>
                              Market Sizing
                            </>
                          }
                          {globalInterviewType === 'eve' &&
                            <>
                              A Chat with Eve LaPlante
                            </>
                          }
                          {globalInterviewType === 'instacart_biz_ops' &&
                            <>
                              Instacart Business Operations Manager Pre-Screening Interview
                            </>
                          }
                          {globalInterviewType === 'residency' &&
                            <>
                              Mock Residency Interview
                            </>
                          }
                          {globalInterviewType === 'phd_psych' &&
                            <>
                              Mock Clinical Psych PhD Interview
                            </>
                          }
                          {globalInterviewType === 'therapy' &&
                            <>
                              Therapy Session
                            </>
                          }
                          {globalInterviewType === 'system_design_event_ticketing' &&
                            <>
                              System Design Interview: "Design TicketMaster"
                            </>
                          }
                        </div>
                      </Grid>
                      }
                      {(firstMobileScreenDone && isMobile) &&
                      <Grid item xs={12} style={{ textAlign: 'left', marginBottom: 30, marginTop: 30 }}>
                        <div style={{fontSize: 17, fontFamily: 'Mulish' }}>
                          {globalInterviewType === 'system_design' &&
                            <>
                              Mock System Design Interview
                            </>
                          }
                          {globalInterviewType === 'system_design_twitter' &&
                            <>
                              System Design Interview: "Design Twitter"
                            </>
                          }
                          {globalInterviewType === 'system_design_instagram' &&
                            <>
                              System Design Interview: "Design Instagram"
                            </>
                          }
                          {globalInterviewType === 'system_design_google_docs' &&
                            <>
                              System Design Interview: "Design Google Docs"
                            </>
                          }
                          {globalInterviewType === 'eve' &&
                            <>
                              A Chat with Eve LaPlante
                            </>
                          }
                          {globalInterviewType === 'system_design_job_scheduler' &&
                            <>
                              System Design Interview: "Design a Job Scheduler"
                            </>
                          }
                          {globalInterviewType === 'virtual_assistant' &&
                            <>
                              Virtual Assistant Pre-Screening Interview
                            </>
                          }
                          {globalInterviewType === 'instacart_biz_ops' &&
                            <>
                              Instacart Business Operations Manager Pre-Screening Interview
                            </>
                          }
                          {globalInterviewType === 'residency' &&
                            <>
                              Mock Residency Interview
                            </>
                          }
                          {globalInterviewType === 'phd_psych' &&
                            <>
                              Mock Clinical Psych PhD Interview
                            </>
                          }
                          {globalInterviewType === 'therapy' &&
                            <>
                              Therapy Session
                            </>
                          }
                          {globalInterviewType === 'system_design_event_ticketing' &&
                            <>
                              TicketMaster
                            </>
                          }
                          {globalInterviewType === 'consulting' &&
                            <>
                              Consulting
                            </>
                          }
                          {globalInterviewType === 'market_sizing' &&
                            <>
                              Market Sizing
                            </>
                          }
                        </div>
                      </Grid>
                      }
                      <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                          {!isMobile &&
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 30, fontSize: 16, fontFamily: 'Mulish', color:'#4451f6' }}>
                              {visualState === '' &&
                                <div style={{ color: interviewStarted ? 'white' : 'black' }}>
                                  {interviewerName} is Connecting...
                                </div>
                              }
                              {visualState === 'listening' &&
                                <div>
                                  {interviewerName} is <b>Listening</b>
                                </div>
                              }
                              {visualState === 'speaking' &&
                                <div>
                                   {interviewerName} is <b>Speaking</b>
                                </div>
                              }
                            </div>
                          }
                            {((isMobile && firstMobileScreenDone) || !isMobile) &&
                            <div style={{ display: 'flex', alignItems: isMobile ? 'left' : 'center', fontFamily: 'Mulish', fontSize: 18, color: 'white', marginRight: isMobile ? 0 : 15, position: isMobile ? 'absolute' : 'relative', left: isMobile ? '2.5vh' : 0 }}>
                              <AccessTimeIcon />
                              <span style={{ fontSize: 18, marginLeft: 5 }}>
                                {secondsToHumanReadableTime(timer)}
                              </span>
                            </div>
                            }
                            {!isMobile && globalInterviewType.startsWith('system_design') &&
                              <IconButton onClick={handleSubmitDiagramFeedback} disabled={loadingDiagramFeedback || !interviewStarted} style={{
                                background: '#181818',
                                color: 'white',
                                borderRadius: 25,
                                fontFamily: 'Mulish',
                                paddingLeft: 20,
                                paddingRight: 20,
                                fontSize: 16,
                                marginLeft: 'auto', // Added to move the button to the right
                              }}>
                                {loadingDiagramFeedback ? 'Syncing...' : <><SyncIcon />
                                Sync</>}
                              </IconButton>
                            }
                            {(interviewStarted && !isMobile) &&
                              <IconButton onClick={handleStopInterview} style={{
                                background: '#4451f6',
                                color: 'white',
                                borderRadius: 25,
                                fontFamily: 'Mulish',
                                paddingLeft: 20,
                                paddingRight: 20,
                                fontSize: 16,
                                marginLeft: 15, // Added to move the button to the right
                              }} disabled={!interviewStarted}>
                                <StopIcon />
                                Stop/Score
                              </IconButton>
                            }
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    {(isMobile && interviewStarted) &&
                    <Grid item xs={12} sm={12} style={{ marginBottom: 10, paddingBottom: 25 }}>
                      <div style={{ textAlign: 'left', alignItems: 'left', fontSize: 16, fontFamily: 'Mulish' }}>
                        {visualState === '' &&
                          <div style={{ color: interviewStarted ? '#4451f6': 'black' }}>
                             {interviewerName} is Connecting...
                          </div>
                        }
                        {visualState === 'listening' &&
                          <div>
                            {interviewerName} is <b>Listening</b>
                          </div>
                        }
                        {visualState === 'speaking' &&
                          <div>
                            {interviewerName} is <b>Speaking</b>
                          </div>
                        }
                      </div>
                    </Grid>
                    }
                    {!isMobile &&
                    <Grid item container style={{ display: tabValue === 1 ? 'none' : 'flex' }} >
                      <>
                      <Grid item xs={globalInterviewType.startsWith('system_design') ? 3 : 12} style={{ marginTop: -30, paddingRight:10 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontFamily: 'Mulish', fontSize: 18, }}>Instructions</p>
                      </div>
                      <br/>
                      {renderContext()}
                      {loadingDiagramFeedback && (
                          <div style={{ color: 'white', fontFamily: 'Mulish', display: 'flex', alignItems: 'center' }}>
                            <CircularProgress size={20} style={{ marginRight: 10 }} /> Updating Instructions...
                          </div>
                        )
                      }
                      </Grid>
                      {globalInterviewType.startsWith('system_design') &&
                        <Grid item xs={9}>
                          <div className="excalidraw-custom">
                            <Excalidraw
                              theme="dark"
                              excalidrawAPI={(api)=> setExcalidrawAPI(api)}
                            />
                          </div>
                        </Grid>
                      }
                      </>
                    </Grid>
                    }
                    {((!isMobile && !interviewStarted) || (isMobile && firstMobileScreenDone && !interviewStarted)) &&
                      <Dialog open={!interviewStarted}>
                        <DialogTitle style={{
                          background: '#181818',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish',
                        }}>{!micEnabled ? "Get Started" : "Start Interview"}</DialogTitle>
                        <DialogContent style={{
                          background: '#181818',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          padding: 10
                        }}>
                          <DialogContentText>
                            {!micEnabled &&
                              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                                You will be doing a {interviewTypeModalText} interview. Please enable your microphone to talk to {interviewerName}, your AI interviewer
                              </div>
                            }
                            {(allSysDesignParam === 'true' && globalInterviewType.startsWith('system_design') && micEnabled) &&
                              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                                Your microphone is enabled!
                                <br/>
                                <br/>
                                Watch <a style={{ color: 'white' }} href="https://www.youtube.com/embed/d2wknWa1VVg">this live video</a> instead if you're not in a place to talk.
                              <p style={{ fontSize: 24, fontFamily: 'Mulish', textAlign: 'center' }}>Pick a System Design Problem</p>
                              <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#f5f5f5' }} onClick={() => handleStartInterview('system_design')}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish' }}>
                                        Design a Blog App
                                      </p>
                                      <ArticleIcon style={{ fontSize: 40, color: 'black', backgroundColor: 'white', borderRadius: '50%', marginBottom: 1 }} />
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#1DA1F2' }} onClick={() => {handleStartInterview('system_design_twitter')}}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design Twitter
                                      </p>
                                      <TwitterIcon style={{ fontSize: 40, color: '#1DA1F2', backgroundColor: 'white', borderRadius: '50%', padding: 5, marginBottom: 1 }} />
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#4285F4' }} onClick={() => {handleStartInterview('system_design_google_docs')}}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design Google Docs
                                      </p>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <GoogleIcon style={{ fontSize: 40, color: 'white', marginRight: 8 }} />
                                        <ArticleIcon style={{ fontSize: 40, color: 'white' }} />
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#B8860B' }} onClick={() => handleStartInterview('system_design_job_scheduler')}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design a Job Scheduler
                                      </p>
                                      <ScheduleIcon style={{ fontSize: 40, color: 'white', marginRight: 8 }} />
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#C13584' }} onClick={() => {handleStartInterview('system_design_instagram')}}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design Instagram
                                      </p>
                                      <InstagramIcon style={{ fontSize: 40, color: 'white', marginRight: 8 }} />
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#1DB954' }} onClick={() => {handleStartInterview('system_design_spotify')}}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design Spotify
                                      </p>
                                      <LibraryMusicIcon style={{ fontSize: 40, color: 'white', marginBottom: 1 }} />
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>
                              </div>
                            }
                            {micEnabled &&
                              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                                Your microphone is enabled! Press the start interview button and our interviewer, {globalInterviewType === 'eve' ? 'Isabelle' : 'Steve'} will read you off a prompt and instructions.
                                <br/>
                                <br/>
                                Watch <a style={{ color: 'white' }} href="https://www.youtube.com/embed/d2wknWa1VVg">this live video</a> instead if you're not in a place to talk.
                              </div>
                            }
                          </DialogContentText>
                        </DialogContent>
                          <DialogActions style={{ background: 'black', border: '0.5px solid #5F6368', padding: 10 }}>
                              <Button autoFocus onClick={enableMic} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                                {micEnabled ? 'Microphone Enabled' : 'Enable Microphone'}
                              </Button>
                              {micEnabled &&
                                <Button onClick={() => {handleStartInterview(globalInterviewType)}} disabled={!micEnabled} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                                  Start Interview
                                </Button>
                              }
                          </DialogActions>
                      </Dialog>
                    }
                    {/* {timer <= 0 &&
                      <Dialog open={timer <= 0}>
                        <DialogTitle>Nice Work!</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Thank you so much for trying the experience. We will send you a recording of your experience and a scorecard to your email.
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            onChange={handleEmailChange}
                            variant="standard"
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleJoinWaitlist}>
                            Enter Email
                          </Button>
                        </DialogActions>
                      </Dialog>
                    } */}
                </Grid>
            </Container>
            {scorecardModalOpen && (
              <Dialog
                style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'
                }}
                open={scorecardModalOpen}
                onClose={() => setScorecardModalOpen(false)}
                aria-labelledby="scorecard-dialog-title"
                aria-describedby="scorecard-dialog-description"
                fullWidth
                maxWidth="md" // Changed from default to make the dialog wider
              >
                <DialogTitle id="scorecard-dialog-title"
                  style={{
                    background: 'black',
                    color: 'white',
                    border: '0.5px solid #5F6368',
                    fontFamily: 'Mulish'
                  }}
                >Interview Scorecard</DialogTitle>
                <DialogContent
                  style={{
                    background: 'black',
                    color: 'white',
                    border: '0.5px solid #5F6368',
                    fontFamily: 'Mulish'
                  }}
                >
                  <DialogContentText id="scorecard-dialog-description">
                    Here's how you did in your interview.
                  </DialogContentText>
                  <List>
                    <TableContainer component={Paper}
                      style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'
                      }}
                    >
                      <Table aria-label="scorecard table">
                        <TableHead style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'
                        }}>
                          <TableRow style={{
                            background: 'black',
                            color: 'white',
                            border: '0.5px solid #5F6368',
                            fontFamily: 'Mulish'
                          }}>
                            <TableCell style={{ color: 'white', fontFamily: 'Mulish'}}>Skill</TableCell>
                            <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Feedback</TableCell>
                            <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Pass?</TableCell>
                            <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Discussed in Interview</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'
                        }}>
                          {interview.scorecard && interview.scorecard.length === 0 &&
                          <TableRow style={{
                            background: 'black',
                            color: 'white',
                            border: '0.5px solid #5F6368',
                            fontFamily: 'Mulish',
                            height: '100%', // Ensure TableRow takes full height
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            <TableCell colSpan={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              <CircularProgress style={{ color: 'white' }} />
                            </TableCell>
                          </TableRow>
                          }
                          {interview.scorecard && interview.scorecard.length > 0 &&
                            <>
                              {interview.scorecard.map((row) => (
                                <TableRow key={row.signal} style={{
                                  background: 'black',
                                  color: 'white',
                                  border: '0.5px solid #5F6368',
                                  fontFamily: 'Mulish'
                                }}>
                                  <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} component="th" scope="row">
                                    {row.signal}
                                  </TableCell>
                                  <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.feedback}</TableCell>
                                  <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.asked_to_candidate ? row.score > 5 ? 'Yes' : 'No' : <>-</>}</TableCell>
                                  <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.asked_to_candidate ? "Yes" : "No"}</TableCell>
                                </TableRow>
                              ))}
                              {interview.scoring_pending &&
                                <TableCell style={{ borderBottom: 'none' }}>
                                  <CircularProgress style={{ color: 'white' }} />
                                </TableCell>
                              }
                            </>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </List>
                  {/* <br/><br/>
                  <p style={{ color: 'white', fontFamily: 'Mulish', fontWeight: 600 }}>Join the waitlist to get your recording. </p>
                  <TextField
                    InputLabelProps={{
                      style: { color: 'white', fontFamily: 'Mulish' },
                    }}
                    inputProps={{
                      style: { color: 'white', fontFamily: 'Mulish' },
                    }}
                    InputProps={{
                      style: { color: 'white', borderBottom: '1px solid white' },
                    }}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Enter your email"
                    type="email"
                    fullWidth
                    onChange={handleEmailChange}
                    variant="standard"
                  />                                */}
                </DialogContent>
                {/* <DialogActions
                  style={{
                    background: 'black',
                    color: 'white',
                    border: '0.5px solid #5F6368',
                    fontFamily: 'Mulish'
                  }}
                >
                  <Button style={{ color: 'white', fontFamily: 'Mulish'}} onClick={handleJoinWaitlist}>
                    Submit
                  </Button>
                </DialogActions>  */}
              </Dialog>
            )}
            {firstMobileScreenDone && isMobile &&
                <>
                <Grid item container xs={12} sm={12} style={{ padding: 10, margin: '0 auto', display: 'flex', alignItems: 'center' }}>
                  <ChatIcon style={{ color: '#4451f6', marginRight: '10px' }} />
                  <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Instructions</p>
                  <br/><br/><br/>
                  {renderContext()}
                </Grid>
                {interviewStarted &&
                  <IconButton onClick={handleStopInterview} style={{
                    margin: 'auto',
                    background: '#4451f6',
                    color: 'white',
                    display: 'block',
                    borderRadius: 20,
                    fontFamily: 'Mulish',
                    paddingTop: 10,
                    fontSize: 16,
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginTop: 25,
                  }} disabled={!interviewStarted}>
                    <StopIcon fontSize="large" />
                    <br/>
                    Stop and Score
                  </IconButton>
                }
                </>
            }
            {!firstMobileScreenDone && isMobile &&
              <Grid item container xs={12} sm={12} style={{ padding: 10, margin: '0 auto',borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ marginBottom: 80, border: '0.5px solid #5F6368', borderRadius: 10 }}>
                  <iframe width="290px" height="203px" src="https://www.youtube.com/embed/MzvYtFLs9Tc?si=hVH8J-pIEztrLzOh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
                <button style={{ color: 'white', background: '#4451f6', border: '0 none', borderRadius: 25, fontFamily: 'Mulish', fontSize: 16, width: '80%', height: 48 }} onClick={() => {setFirstMobileScreenDone(true)}}>
                  Start Interview
                </button>
                <br/>
                <br/>
                <br/>
                <br/>
                {/*<button style={{ color: 'white', background: 'black', border: '1px solid white', borderRadius: 25, fontFamily: 'Mulish', fontSize: 16, width: '80%', height: 48 }} onClick={handleOpenWaitlist}>
                  Join Waitlist
            </button> */}
              </Grid>
            }
            <Container>
              <Snackbar
                open={subscriptionSuccessSnackOpen}
                autoHideDuration={5000}
                onClose={handleCloseSubscriptionSuccessSnack}>
                  <Alert onClose={handleCloseSuccessSnack} severity="success" sx={{ width: '100%' }}>
                    You're on the waitlist!
                  </Alert>
                </Snackbar>
              <Snackbar
              open={subscriptionFailSnackOpen}
              autoHideDuration={5000}
              onClose={handleCloseSubscriptionFailSnack}>
                <Alert onClose={handleCloseFailSnack} severity="error" sx={{ width: '100%' }}>
                  Error: e-mail already used or invalid format.
                </Alert>
              </Snackbar>
            </Container>
        </>
    );
};

export const Logout = () => {
  const { isAuthenticated, logout, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function checkUser() {
      console.log('isAuthenticated', isAuthenticated, error)
      if (!isLoading) {
        if(isAuthenticated) {
          localStorage.removeItem('teamId')
          localStorage.removeItem('globalOverrideUser')
          logout({
            logoutParams: {
              returnTo: 'https://admin.techinterviewer.ai'
            }
          });
        } else {
          navigate('/interviews')
        }
      }
    }

    checkUser();
  }, [isAuthenticated, logout, isLoading, error, navigate]);

  return (
    <div>
      <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
    </div>
  )
}

export const Login = () => {
  const { user, isAuthenticated, loginWithRedirect, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function checkUser() {
      console.log('isAuthenticated', isAuthenticated, error)
      if (!isLoading) {
        if(isAuthenticated) {
          const teamId = localStorage.getItem('teamId')
          if(teamId) {
            navigate('/interviews')
            return
          }

          api.getTeams({ userId: user.email }).then((response) => {
            const teams = response.data
            if (teams.length === 0) {
              navigate('/your-team')
              return
            }

            let invited_team_id = null
            teams.map((team) => {
              team.members.map((member) => {
                if(member.user_id === user.email && member.status === 'invited') {
                  invited_team_id = team._id.$oid
                  return
                }
              })
              return
            })

            if(invited_team_id) {
              localStorage.setItem('teamId', invited_team_id)
              navigate('/your-team')
              return
            }

            // no invites found. check if active membership on any
            let active_team_id = null
            teams.map((team) => {
              team.members.map((member) => {
                if(member.user_id === user.email && member.status === 'active') {
                  active_team_id = team._id.$oid
                  return
                }
              })
            })

            if(active_team_id) {
              localStorage.setItem('teamId', active_team_id)
              navigate('/interviews')
              return
            }
          })
          .catch((error) => {
            console.log(error)
            // no teams found with user
            if (error.response.status == 400) {
              navigate('/your-team')
              return
            }
          })
        } else {
          loginWithRedirect();
        }
      }
    }

    checkUser();
  }, [isAuthenticated, loginWithRedirect, isLoading, error, navigate]);

  return (
    <div>
      <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
    </div>
  )
}
